import { TableCell, TableRow, Tooltip } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import TableComponent from '../../components/Tables';
import { STATE } from '../../constants';
import { ApiGet, ApiPost, ApiPut } from '../../helper/API/ApiData';
import { getImageUrl, isEmpty } from '../../helper/util';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import { DatePicker } from '@mui/lab'
import DatePicker from "react-datepicker";
import { Stack, TextField } from '@mui/material'
import React from 'react'
import { toast } from 'react-toastify';
import { OrganizationChart } from 'primereact/organizationchart';
import './staff.css'

// import Header from './components/header';
// import Sidebar from './components/sidebar'
import { useNavigate } from 'react-router';
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
import { TieredMenu } from 'primereact/tieredmenu';
import Select from 'react-select';
import { InputSwitch } from 'primereact/inputswitch';
import { Dialog } from 'primereact/dialog';


function Staff() {
    const slabList = [
        { label: "0L to 1L", value: "0-1" },
        { label: "1L to 2L", value: "1-2" },
        { label: "2L to 3L", value: "2-3" },
        { label: "3L to 4L", value: "3-4" },
        { label: "4L to 5L", value: "4-5" },
        { label: "5L+", value: "5+" },
    ]
    const staffData = {
        department: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        email: "",
        address: "",
        mobile: "",
        pan_no: "",
        ctc: "",
        salary_justification_ratio: "",
        reports_to:"",
        reporting_id:'',
        date_of_joining: new Date(),
        incentive_list: [],
        department_error: null,
        first_name_error: null,
        last_name_error: null,
        email_error: null,
        address_error: null,
        mobile_error: null,
        pan_no_error: null,
        ctc_error: null,
        date_of_joining_error: null,
        salary_justification_ratio_error: null,
        reports_to_error:null
    }
    const [activeTab, setActiveTab] = useState("hrlist");
    const [orgChartTab , setOrgCgartTab] =useState(false);
    const [staffDetails, setStaffDetails] = useState([]);
    const [loader, setLoader] = useState(true);
    const [addOpen, setAddOpen] = useState(false)
    const [isEdit, setIsEdit] = useState("")
    const [formData, setFormData] = useState<any>(staffData);
    const [state, setState] = useState(STATE.DEFAULT_STATE);
    const [searchValue, setSearchValue] = useState('');
    const [reportToData, setReportToData] = useState<any>([]);
    const [selectedReportTo, setSelectedReportTo] = useState(null);

    const [departmentOptions, setDepartmentOptions] = useState(null);
    const [totalRecords, setTotalRecords] = useState(0);
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 0,
        search: '',
        filters: {
            Name: { value: null, matchMode: FilterMatchMode.IN },
            Department: { value: null, matchMode: FilterMatchMode.IN }
        }
    });
    const [showDialog, setShowDialog] = useState(false); 
    const [activeStatus, setActiveStatus] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [orgChartData,setOrgChartData] = useState<any>([]);
    let navigate = useNavigate();

    const columns = [
        { id: 'SRNO', label: 'SR.NO.' },
        { id: 'Name', label: 'Name' },
        { id: 'Department', label: 'Department' },
        { id: 'CTC', label: 'CTC' },
        { id: 'Action', label: 'Action' },
    ];

    
    const handleReportToChange = (selectedOption: any) => {
        setSelectedReportTo(selectedOption)
        setFormData({
          ...formData,
          reports_to: selectedOption ? selectedOption.value : '',
          reports_to_error: null,
        });
      };
      const handleDepartmentChange = (selectedOption: any) => {
        setFormData({
          ...formData,
          department: selectedOption ? selectedOption.value : '',
          department_error: null,
        });
      };

    const handlePageData = (limit: number, page: number = 1) => {
        setState({
            ...state,
            limit,
            page
        })
    }

    const handlePage = (page: number) => {
        setState({
            ...state,
            page
        })
    }

    const addStaffData = () => {
        let updatedForm = { ...formData };
        for (let key in updatedForm) {
            if (typeof updatedForm[key] == "string") {
                updatedForm[key] = updatedForm[key].trim();
            }
        }
        let emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/;
        let namePattern=/^[A-Za-z\s]+$/;
        let mobilePattern = /^\+?[0-9]{10,}$/;

        //PAN validation
        let panVal = formData.pan_no;
        let firstFivePattern = /^[A-Za-z]{5}/;
        let firstFive = panVal.substring(0, 5);
        let sixtoNinePattern = /^[0-9]{4}/;
        let sixtoNine = panVal.substring(5, 9);
        let lastValPattern = /^[A-Za-z]{1}/;
        let lastVal = panVal.substring(9, 10);

        if (formData.department == "") {
            updatedForm = { ...updatedForm, department_error: "Please enter a department" };
        }
        if (formData.email == "") {
            updatedForm = { ...updatedForm, email_error: "Please enter an email" };
        }
        if (formData.email != "" && !emailPattern.test(formData.email)) {
            updatedForm = { ...updatedForm, email_error: "Please enter a valid email" };
        }
        if (formData.first_name == "") {
            updatedForm = { ...updatedForm, first_name_error:"Please enter a first name" };
        }
        if (formData.first_name != "" && !namePattern.test(formData.first_name)) {
            updatedForm = { ...updatedForm, first_name_error:  "Please enter a valid name" };
        }
        if (formData.last_name == "") {
            updatedForm = { ...updatedForm, last_name_error: "Please enter a last name" };
        }
        if (formData.last_name != "" && !namePattern.test(formData.last_name)) {
            updatedForm = { ...updatedForm, last_name_error:  "Please enter a valid name" };
        }
        if (formData.address == "") {
            updatedForm = { ...updatedForm, address_error: "Please enter a address" };
        }
        if (formData.mobile == "") {
            updatedForm = { ...updatedForm, mobile_error: "Please enter a mobile No." };
        }
        if (formData.mobile != "" && !mobilePattern.test(formData.mobile)) {
            updatedForm = { ...updatedForm, mobile_error: "Please enter a valid mobile no." };
        }
        if(formData.reports_to == ""){
            updatedForm = { ...updatedForm, reports_to_error: "Please enter to whom this employee reports" };
        }
        
        if (formData.pan_no == "") {
            updatedForm = { ...updatedForm, pan_no_error: "Please enter pan no" };
        } else if (panVal.length != 10) {
            updatedForm = {
                ...updatedForm,
                pan_no_error: 'Length should be restricted to 10 digits and should not allow anything more or less'
            };
        } else if (!firstFivePattern.test(firstFive)) {
            updatedForm = {
                ...updatedForm, pan_no_error: 'First Five characters of PAN No should be alphabets'
            };
        } else if (!sixtoNinePattern.test(sixtoNine)) {
            updatedForm = {
                ...updatedForm, pan_no_error: 'Six to Nine characters of PAN No should be number'
            };
        } else if (!lastValPattern.test(lastVal)) {
            updatedForm = {
                ...updatedForm,
                pan_no_error: 'Last characters of PAN No should be alphabets'
            };
        } else (
            updatedForm = {
                ...updatedForm,
                pan_no_error: null
            })

        if (formData.ctc == "") {
            updatedForm = { ...updatedForm, ctc_error: "Please enter ctc" };
        }
        if (formData.date_of_joining == null) {
            updatedForm = { ...updatedForm, date_of_joining_error: "Please enter a date of joining" };
        }

        setFormData(updatedForm)
        if (updatedForm.department_error == null && updatedForm.email_error == null && updatedForm.first_name_error == null &&
            updatedForm.last_name_error == null && updatedForm.address_error == null && updatedForm.mobile_error == null && updatedForm.pan_no_error == null && updatedForm.ctc_error == null && updatedForm.date_of_joining_error == null && updatedForm.reports_to_error == null) {
            const { department_error, email_error, address_error, mobile_error, pan_no_error, ctc_error, date_of_joining_error, first_name_error,last_name_error,reports_to_error, ...payload } = updatedForm
            let finalData = payload;

            isEdit ?
                ApiPut(`staff/edit-staff?id=${isEdit}`, finalData)
                    .then((res: any) => {
                        // setFormData(staffData)
                        getStaffdata()
                        setAddOpen(false)
                        if(res){
                            toast.success("Updated successfully", {
                                position: "top-right",
                                theme: "colored"
                              });
                        }                                         
                    })
                    .then(() => {
                        setTimeout(() => { setLoader(false) }, 250)
                    })
                    .catch((error) => {
                        setLoader(false)
                        console.log(error)
                    })
                :
                ApiPost("staff/register", finalData)
                    .then((res: any) => {
                        setFormData(staffData)
                        getStaffdata()
                        setAddOpen(false)
                        if(res){
                            toast.success("Added successfully", {
                                position: "top-right",
                                theme: "colored"
                              });
                        }                     })
                    .then(() => {
                        setTimeout(() => { setLoader(false) }, 250)
                    })
                    .catch((error) => {
                        setLoader(false)
                        // toast.error(error.error, {
                        //     position: "top-right",
                        //     theme: "colored"
                        // })
                    })
        }
    }

    const getOptionsData = () => {
        setLoader(true);
        ApiGet(`options/staff`).then((res: any) => {
            const departments = convertToObjectArray(res.data.department);
            setDepartmentOptions(departments);
        });
    }

    const getOrgChartData = () => {
        ApiGet(`staff/get-org-chart`).then((res: any) => {
            setOrgChartData(res.data.orgData)
            const transformedData = transformData(res.data.orgData);
            setOrgChartData(transformedData)
        });
    }
    const getInitials = (label) => {
        const names = label.split(' ');
        const firstInitial = names[0].charAt(0).toUpperCase();
        const lastInitial = names[names.length - 1].charAt(0).toUpperCase();
        return `${firstInitial}${lastInitial}`;
      };

    const transformData=(data)=> {
        return data.map(node => ({
          expanded: node.expanded,
          data: {
            name: node.label,
            image: createImageUrl(node.label),
          },
          children: node.children ? transformData(node.children) : []
        }));
      }

    const   createImageUrl=(name)=> {
        const initials = getInitials(name);
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.width = 100;
        canvas.height = 100;
      
        // Draw a background color
        context.fillStyle = '#252C48'; // Background color
        context.fillRect(0, 0, canvas.width, canvas.height);
      
        // Draw the initials
        context.font = '50px Arial';
        context.fillStyle = '#fff'; // Text color
        context.textAlign = 'center';
        context.textBaseline = 'middle';
        context.fillText(initials, canvas.width / 2, canvas.height / 2);
        // Convert canvas to base64 image
        return canvas.toDataURL();
      }

    const getReportsToOptionsData = () => {
        setLoader(true);
        ApiGet(`staff/reports-to`).then((res: any) => {
            const reportsto=res.data.result.map((res)=>({
             label:res.full_name,
             value:res._id
            }))
            setReportToData(reportsto)
        });
    }

    const convertToObjectArray = obj => {
        return Object.keys(obj).map(key => ({
            label: obj[key],
            value: key
        }));
    };

    const getStaffdata = async () => {
        setLoader(true);
        const modifiedLazyState = { ...lazyState, limit: lazyState.rows };
        delete modifiedLazyState.rows;
        delete modifiedLazyState['multiSortMeta'];
        delete modifiedLazyState['sortField'];
        delete modifiedLazyState['sortOrder'];

        // Function to replace empty arrays in the "value" field with null
        const replaceEmptyValueArrays = (obj) => {
            for (const key in obj) {
                if (key === 'value' && Array.isArray(obj[key]) && obj[key].length === 0) {
                    obj[key] = null;
                } else if (typeof obj[key] === 'object') {
                    replaceEmptyValueArrays(obj[key]);
                }
            }
            return obj;
        };

        // Call the function to replace empty arrays in the "value" field with null
        const finalModifiedLazyState = replaceEmptyValueArrays(modifiedLazyState);
        finalModifiedLazyState.page++;
        await ApiPost(`staff/get-staff`, finalModifiedLazyState)
            .then((res: any) => {
                setTotalRecords(Number(res.data.count.count));
                let temp = res.data.result.map((item: any, index: number) => {
                    return {
                        id: item._id,
                        SRNO: (lazyState.first) + index + 1,
                        Name: item.first_name + " " + item.last_name,
                        Department: item.department,
                        CTC: item.ctc,
                        pan_no:item.pan_no,
                        mobile:item.mobile,
                        address:item.address,
                        first_name: item.first_name,
                        middle_name: item.middle_name,
                        last_name: item.last_name,    
                        email:item.email,
                        date_of_joining:item.date_of_joining ,  
                        Reports_to :item.reports_to,
                        reporting_id : item.reporting_id,
                        Status:item.status,
                        user_id:item.user_id
                    }
                })
                setStaffDetails(temp)
                setState({
                    ...state,
                    totalItems: 10
                })
            }).catch((error) => {
                setStaffDetails([])
            })
    }
    const deleteStaff = async (_id: string) => {
        await ApiPut(`staff/remove/${_id}`, {})
            .then((res: any) => {
                getStaffdata()
            })
    }
    const editStaff = async (_id: string) => {
        setAddOpen(true)
        setIsEdit(_id)
        await ApiGet(`staff/${_id}`)
            .then((res: any) => {
                let data = {
                    department: res?.data?.staff[0].department,
                    first_name: res?.data?.staff[0].first_name,
                    middle_name: res?.data?.staff[0].middle_name,
                    last_name: res?.data?.staff[0].last_name,
                    email: res?.data?.staff[0].email,
                    address: res?.data?.staff[0].address,
                    mobile: res?.data?.staff[0].mobile,
                    pan_no: res?.data?.staff[0].pan_no,
                    ctc: res?.data?.staff[0].ctc,
                    date_of_joining: new Date(res?.data?.staff[0].date_of_joining),
                    // incentive_list: res?.data?.staff?.incentive_list,
                    // salary_justification_ratio: res?.data?.staff?.salary_justification_ratio
                }
                setFormData(data)
            })
    }
    const handleAddDataPopup = async () => {
        setAddOpen(true)
        setIsEdit("")
        setFormData(staffData)
        setSelectedReportTo(null)
    }

    useEffect(() => {
        Promise.all([
            getStaffdata(),
            getOptionsData(),
            getReportsToOptionsData(),
            getOrgChartData()
        ]).then(() => {
            setTimeout(() => { setLoader(false) }, 250)
        }).catch((error) => {
            setLoader(false)
        })
    }, [lazyState])

    const rowClick = (data: any) => {
        navigate(`/staff/${data.id}`, { replace: true });
    }

    const onPage = (event) => {
        let filter = { ...lazyState };
        filter = event;
        filter['search'] = lazyState.search;
        setlazyState(filter);
    };

    const onFilter = (event) => {
        event['first'] = 0;
        event['page'] = 0;
        setlazyState(event);
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-end">
                <span>
                    <InputText
                        className="mr-2"
                        value={searchValue}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        placeholder="Keyword Search"
                    />
                    <Button icon="pi pi-search" onClick={handleSearchClick} />
                </span>
            </div>
        );
    };

    const onGlobalFilterChange = (e) => {
        const value = e;
        let filter = { ...lazyState };

        filter['search'] = value;

        setlazyState(filter);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            onGlobalFilterChange(searchValue);
        }
    };

    const handleSearchClick = () => {
        onGlobalFilterChange(searchValue);
    };

    const handleInputChange = (event) => {
        setSearchValue(event.target.value);
    };

    const header = renderHeader();

    const departmentRowFilterTemplate = (options) => {
        return (
            <MultiSelect
                value={options.value}
                options={departmentOptions}
                onChange={(e) => options.filterApplyCallback(e.value)}
                optionLabel="label"
                placeholder="Select"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '14rem' }}
                filter
                filterPlaceholder="Search"
            />
        );
    };
    const menuRef = useRef(null);
    const selectedRowData = useRef(null);
    const handleEllipsisClick = (event, rowData) => {
        event.stopPropagation(); // Prevent event from bubbling up
        selectedRowData.current = rowData;
        if (menuRef.current) {
            menuRef.current.toggle(event);
        }
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div>
                <img src="../assets/menu-cirlce.svg" alt="menu-icon" style={{ cursor: 'pointer' }} onClick={(e) => handleEllipsisClick(e, rowData)} />
                <TieredMenu model={generateMenuItems()} popup ref={menuRef} />
            </div>
        );
    };
    const statusBodyTemplate = (rowData) => {
        return (
          <div>
            <InputSwitch checked={rowData.Status == 'Active' ? true : false} onChange={() => handleToggle(rowData)} />
          </div>
        );
      };
      const handleToggle = (user) => {
        setActiveStatus(user.Status == 'Active' ? true : false);
        setSelectedUser(user.user_id);
        selectedRowData.current=user;
        setShowDialog(true);
      };
      const cancelToggle = () => {
        setShowDialog(false);
        setSelectedUser(null);
      };
      const confirmToggle = async () => {
        let request;
        if (selectedUser) {
          if(selectedRowData.current.Status == 'Active'){
            request={
              "status":'inactive'
            }
          }
          else{
            request={
              "status":'active'
            }
          }
          
         ApiPut(`user/change-user-status?id=${selectedUser}`, request).then((res: any) => {
            if (res && res.success) {
              toast.success(res.data.message, {
                position: "top-right",
                theme: "colored"
              });
              let newUser= staffDetails.map((userData)=>
                selectedRowData.current.user_id == userData.user_id ? {...userData, Status:activeStatus ? 'Inactive' : 'Active'} : userData
              );
              setStaffDetails(newUser);
              setShowDialog(false);
              setSelectedUser(null);
            }
          }).catch((error)=>{
            //  console.log(error)
          });      
        }
      };
    const customStyles = (hasError) => ({
        control: (base, state) => ({
          ...base,
          borderColor: hasError ? 'red' : base.borderColor,
          '&:hover': {
            borderColor: hasError ? 'red' : base.borderColor,
          },
        }),
      });

    const generateMenuItems = () => {
        return [
            { label: 'Edit', command: () => handleMenuItemClick('Edit') }
        ];
    };

    const handleNumericInput = (e) => {
        e.target.value = e.target.value.replace(/[^0-9]/g, '');
    };
    const handleNameInput = (e) => {
        e.target.value=e.target.value.replace(/[^A-Za-z\s]/g, '');
    };
    
    const handleMenuItemClick = (itemLabel) => {
        if (itemLabel === 'Edit') {
            setAddOpen(true)
            setIsEdit(selectedRowData.current.id)
            setSelectedReportTo(reportToData.find(opt=> opt.value === selectedRowData.current.reporting_id ))
            // isEdit ?
            // editStaff(selectedRowData.current.id);
            console.log('--------------------',selectedRowData)
            let data = {
                department: selectedRowData.current.Department,
                first_name: selectedRowData.current.first_name,
                middle_name: selectedRowData.current.middle_name,
                last_name: selectedRowData.current.last_name,
                email: selectedRowData.current.email,
                address: selectedRowData.current.address,
                mobile: selectedRowData.current.mobile,
                pan_no: selectedRowData.current.pan_no,
                ctc: selectedRowData.current.CTC,
                date_of_joining: new Date(selectedRowData.current.date_of_joining),
                reports_to:selectedRowData.current.reports_to,
                reporting_id : selectedRowData.current.reporting_id
                // incentive_list: res?.data?.staff?.incentive_list,
                // salary_justification_ratio: res?.data?.staff?.salary_justification_ratio
            }
            setFormData(data)
            
        }
    };

    const nodeTemplate = (node) => {
        return (
            <div className="flex flex-column align-items-center" style={{ textAlign: 'center' }}>
<img alt={node.data.name} src={node.data.image} className="mb-2 w-3rem h-3rem" style={{ borderRadius: '50%' }} />
<div className="flex flex-column">
<span style={{ fontSize: '1.2rem' }} className="font-bold">{node.data.name}</span>
</div>
</div>

        );
    
};

    return (<div className="overflow-x-hidden p-6">
        {/* <Sidebar />
    <Header /> */}
        {loader ?
            <>
                {/* START::OVERLAY */}
                <div className="fixed inset-0 w-full h-screen bg-white z-[9999] flex items-center justify-center">
                    <img src="assets/logo.svg" />
                </div>
                {/* END::OVERLAY */}
            </>
            :
            <>

                {/* <main className="lg:pl-[280px] pt-[92px]"> */}
                <div className="p-4 sm:p-9 space-y-5">

                    {/* START::DASHBOARD HEADER */}
                    <div className="flex flex-wrap gap-5 items-center justify-between">
                        <div className="md:flex w-full lg:w-[unset] items-center lg:justify-between md:space-x-9 space-y-5 md:space-y-0">
                        <ul className="inline-flex items-center p-[2px] text-white bg-primary rounded-[18px] whitespace-nowrap overflow-x-auto">
                                <li>
                                <button className={`min-w-[170px] py-2 px-7 text-xs font-medium ${activeTab === "hrlist" && "bg-white rounded-[18px] text-primary"}`} 
                                    onClick={
                                      () =>{
                                        setOrgCgartTab(false);
                                        setActiveTab("hrlist")
                                    }}>
                                      HR List
                                      </button>
                                </li>
                                <li>
                                <button className={`min-w-[170px] py-2 px-7 text-xs font-medium ${activeTab === "orgchart" && "bg-white rounded-[18px] text-primary"}`} 
                                    onClick={() => {
                                        getOrgChartData()
                                        setOrgCgartTab(true);
                                        setActiveTab("orgchart")
                                    }}>Org Chart</button>
                                </li>
                            </ul>                        </div>

                        {/* START::EXPORT */}
                        <ul className="flex space-x-[18px]">
                            <li>
                                <button className="flex items-center space-x-[6px] py-2 px-6 rounded-[18px] border border-light-gray text-xs font-semibold bg-primary text-white" onClick={() => handleAddDataPopup()}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" />
                                    </svg>
                                    <span>Add Staff</span>
                                </button>
                            </li>


                        </ul>
                        {/* END::EXPORT */}
                    </div>
                    {/* END::DASHBOARD HEADER */}
                </div>
                { !orgChartTab && <div>
                <DataTable
                    value={staffDetails}
                    lazy
                    // dataKey="id"
                    showGridlines
                    paginator
                    first={lazyState.first}
                    filters={lazyState.filters}
                    onFilter={onFilter}
                    rows={lazyState.rows}
                    rowsPerPageOptions={[10, 20, 30, 40, 50]}
                    totalRecords={totalRecords}
                    onPage={onPage}
                    loading={loader}
                    size={'small'}
                    scrollable
                    scrollHeight="600px"
                    className="custom-datatable"
                    header={header}
                    emptyMessage="No records found."
                >
                    <Column
                        field="SRNO"
                        frozen
                        header="SR.NO."
                    />
                    <Column
                        field="Name"
                        header="Name"
                    />
                    <Column
                        field="Department"
                        header="Department"
                        showFilterMatchModes={false} filterMenuStyle={{ minWidth: '14rem' }}
                        filter filterElement={departmentRowFilterTemplate}
                    />
                    <Column
                        field="Reports_to"
                        header="Reports To"
                    />
                   <Column
              field="Status"
              header="Status"
              body={statusBodyTemplate}/>
                    <Column
                        field="Action"
                        header="Action"
                        body={actionBodyTemplate}
                    />
                </DataTable>
                <Dialog
        header={`${activeStatus ? 'Dectivate' : 'Activate'  } user `}
        visible={showDialog}
        onHide={cancelToggle}
        footer={
          <div>
            <Button label="No" icon="pi pi-times" onClick={cancelToggle} className="p-button-text" />
            <Button label="Yes" icon="pi pi-check" onClick={confirmToggle} autoFocus />
          </div>
        }
      >{ activeStatus && (<div>
            <p>  Are you sure you want to disable the user. </p>
            <p>  Note:- Case resgistration and login will be disabled</p>
      </div>)}
      { !activeStatus && (<div>
            <p>  Are you sure you want to enable the user. </p>
            <p>  Note:- Case resgistration and login will be enabled</p>
      </div>)}

       
      </Dialog>
                {/* Start::Add staff Data Modal */}
                <div className={`tw-modal ${addOpen ? 'flex' : 'hidden'} !items-start`}>
                    <div className="tw-modal-content">
                        <div className="tw-modal-title">
                            <div>{isEdit ? "Edit Staff Data" : "Add Staff Data"}</div>
                            <button onClick={() => setAddOpen(false)}>
                                <img src="assets/close-outline.svg" alt="" />
                            </button>
                        </div>

                        <div className='tw-modal-body'>
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-[22px]">
                                <div className="space-y-1">
                                    <label htmlFor="product" className={`input-label ${formData.department_error ? "text-red-500" : ""}`}>Department*</label>
                                        {/* <select name="product" value={formData.department} onChange={(e) => setFormData({ ...formData, department: e.target.value, department_error: null })} className="w-full text-sm text-[#808080]">
                                    <label htmlFor="department" className={`input-label ${formData.department_error ? "text-red-500" : ""}`}>Departement</label>
                                    <div id="department" className={`input-wrap ${formData.department_error ? "border-red-500" : ""}`}>
                                        <img src="assets/product.svg" alt="" />
                                        <select name="department" value={formData.department} onChange={(e) => setFormData({ ...formData, department: e.target.value, department_error: null })} className="w-full text-sm text-[#808080]">
                                            <option value="" selected>Select Product</option>
                                            <option value="sales" selected>Sales Team</option>

                                        </select> */}
                                        <Select
                                            options={departmentOptions}
                                            value={departmentOptions.find((opt: any) => opt.value === formData.department)}
                                            onChange={handleDepartmentChange}
                                            className="w-full text-sm py-2"
                                            placeholder="Type to search..."
                                            styles={customStyles(formData.department_error)}
                                        />
                                    <p className="text-red-500 text-sm">{formData.department_error}</p>
                                </div>
                                <div className="space-y-1">
                                    <label htmlFor="first_name" className={`input-label ${formData.first_name_error ? "text-red-500" : ""}`}>First Name*</label>
                                    <div id="first_name" className={`input-wrap ${formData.first_name_error ? "border-red-500" : ""}`}>
                                        <img src="assets/user.svg" alt="" />
                                        <input type="text" name="first_name" value={formData.first_name} onInput={handleNameInput} onChange={(e: any) => {
                                            setFormData({ ...formData, first_name: e.target.value, first_name_error: null });
                                        }} className="w-full text-sm" placeholder="Enter First name"  />
                                    </div>
                                    <p className="text-red-500 text-sm">{formData.first_name_error}</p>
                                </div>
                                <div className="space-y-1">
                                    <label htmlFor="middle_name" className="input-label ">Middle Name</label>
                                    <div id="middle_name" className="input-wrap" >
                                        <img src="assets/user.svg" alt="" />
                                        <input type="text" name="middle_name" value={formData.middle_name} onInput={handleNameInput}  onChange={(e: any) => {
                                            setFormData({ ...formData, middle_name: e.target.value });
                                        }} className="w-full text-sm" placeholder="Enter Middle name" />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label htmlFor="borrower_name" className={`input-label ${formData.last_name_error ? "text-red-500" : ""}`}>Last Name*</label>
                                    <div id="last_name" className={`input-wrap ${formData.last_name_error ? "border-red-500" : ""}`}>
                                        <img src="assets/user.svg" alt="" />
                                        <input type="text" name="last_name" value={formData.last_name} onInput={handleNameInput} onChange={(e: any) => {
                                            setFormData({ ...formData, last_name: e.target.value, last_name_error: null });
                                        }} className="w-full text-sm" placeholder="Enter last name" />
                                    </div>
                                    <p className="text-red-500 text-sm">{formData.last_name_error}</p>
                                </div>
                                <div className="space-y-1">
                                    <label htmlFor="address" className={`input-label ${formData.address_error ? "text-red-500" : ""}`}>Address*</label>
                                    <div id="address" className={`input-wrap ${formData.address_error ? "border-red-500" : ""}`}>
                                        <img src="assets/location.svg" alt="" />
                                        <input type="text" name="address" value={formData.address} onChange={(e: any) => {
                                            setFormData({ ...formData, address: e.target.value, address_error: null });
                                        }} className="w-full text-sm" placeholder="Enter Address" />
                                    </div>
                                    <p className="text-red-500 text-sm">{formData.address_error}</p>
                                </div>
                                <div className="space-y-1">
                                    <label htmlFor="ctc" className={`input-label ${formData.ctc_error ? "text-red-500" : ""}`}>CTC*</label>
                                    <div id="ctc" className={`input-wrap ${formData.ctc_error ? "border-red-500" : ""}`}>
                                        <img src="assets/wallet-solid.svg" alt="" />
                                        <input type="text" name="ctc"  onInput={handleNumericInput} value={formData.ctc} onChange={(e: any) => {
                                            setFormData({ ...formData, ctc: e.target.value, ctc_error: null });
                                        }} className="w-full text-sm" placeholder="Enter CTC" />
                                    </div>
                                    <p className="text-red-500 text-sm">{formData.ctc_error}</p>
                                </div>
                                <div className="space-y-1">
                                    <label htmlFor="email" className={`input-label ${formData.email_error ? "text-red-500" : ""}`}>Email*</label>
                                    <div id="email" className={`input-wrap ${formData.email_error ? "border-red-500" : ""}`}>
                                        <img src="assets/email.svg" alt="" />
                                        <input type="text" name="email" value={formData.email} onChange={(e: any) => {
                                            setFormData({ ...formData, email: e.target.value, email_error: null });
                                        }} className="w-full text-sm" placeholder="johndue@xyz.com" />
                                    </div>
                                    <p className="text-red-500 text-sm">{formData.email_error}</p>
                                </div>
                                <div className="space-y-1">
                                    <label htmlFor="mobile" className={`input-label ${formData.mobile_error ? "text-red-500" : ""}`}>Mobile No.*</label>
                                    <div id="mobile" className={`input-wrap ${formData.mobile_error ? "border-red-500" : ""}`}>
                                        <img src="assets/phone-solid.svg" alt="" />
                                        <input type="text" name="pincode" value={formData.mobile} maxLength={13} onChange={(e: any) => {
                                            setFormData({ ...formData, mobile: e.target.value, mobile_error: null });
                                        }} className="w-full text-sm" placeholder="Enter Mobile No" />
                                    </div>
                                    <p className="text-red-500 text-sm">{formData.mobile_error}</p>
                                </div>
                                <div className="space-y-1">
                                    <label htmlFor="date_of_joining" className={`input-label ${formData.date_of_joining_error ? "text-red-500" : ""}`}>Month of Joining*</label>
                                    <div id="date_of_joining" className={`input-wrap ${formData.date_of_joining_error ? "border-red-500" : ""}`}>
                                        <img src="assets/date.svg" alt="" />
                                        {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <Stack spacing={4}>
                                                <DatePicker
                                                    label='Date Picker'
                                                    renderInput={(params: any) => <TextField {...params} />}
                                                    value={formData.date_of_joining}
                                                    onChange={(e:any) => 
                                                        setFormData({ ...formData, date_of_joining: e.target.value, date_of_joining_error: null })}
                                                />
                                            </Stack>
                                        </LocalizationProvider> */}
                                        <DatePicker
                                            selected={formData.date_of_joining}
                                            onChange={(date: any) => setFormData({ ...formData, date_of_joining: date, date_of_joining_error: null })}
                                            dateFormat="MM/yyyy"
                                            showMonthYearPicker
                                            showFullMonthYearPicker
                                        />
                                        {/* <select name="date_of_joining" value={formData.date_of_joining} onChange={(e) => setFormData({ ...formData, date_of_joining: e.target.value, date_of_joining_error: null })} className="w-full text-sm text-[#808080]">
                                            <option value="" selected>Select Month</option>
                                            {monthData?.map((item: any) => (
                                                <option value={item}>{item}</option>
                                            ))}
                                        </select> */}
                                    </div>
                                    <p className="text-red-500 text-sm">{formData.date_of_joining_error}</p>
                                </div>
                                <div className="space-y-1">
                                    <label htmlFor="pan_no" className={`input-label ${formData.pan_no_error ? "text-red-500" : ""}`}>Pan No.*</label>
                                    <div id="pan_no" className={`input-wrap ${formData.pan_no_error ? "border-red-500" : ""}`}>

                                        <img src="assets/credit-card-solid.svg" width="20px"
                                            alt="" />
                                        <input type="text" name="pan_no" maxLength={10} value={formData.pan_no} onChange={(e: any) => {
                                            setFormData({ ...formData, pan_no: e.target.value, pan_no_error: null });
                                        }} className="w-full text-sm" placeholder="Enter PAN No." />
                                    </div>
                                    <p className="text-red-500 text-sm">{formData.pan_no_error}</p>
                                </div>
                                <div className="space-y-1">
                                    <label htmlFor="reports_to" className={`input-label ${formData.reports_to_error ? "text-red-500" : ""} `}> Report To*</label>
                                    <div id="reports_to" >
                                    <Select
                                       options={reportToData}
                                       value={selectedReportTo}
                                    onChange={handleReportToChange}
                                    className="w-full text-sm py-2"
                                    placeholder="Type to search..."
                                    styles={customStyles(formData.reports_to_error)}
                                    />
                                </div>
                                <p className="text-red-500 text-sm">{formData.reports_to_error}</p>
                </div>

                            </div>


                        </div>

                        <div className='tw-modal-footer'>
                            <button className='tw-modal-footer-button bg-secondary text-white' onClick={addStaffData}>Save</button>
                            <button className='tw-modal-footer-button bg-secondary bg-opacity-10 text-secondary' onClick={() => setAddOpen(false)}>Cancel</button>
                        </div>
                    </div>
                </div>
                {/* End::Add staff Data Modal */}
                </div>}
                { orgChartTab && 
                <div style={{ width: '100%', height: '100%', overflow: 'auto' ,border:' 1px solid #ccc',
                    borderRadius:' 5px',
                    backgroundColor: "#f0f0f0",
                    padding: '10px 10px 10px 0px'}}>
            <OrganizationChart value={orgChartData}  nodeTemplate={nodeTemplate}/>
        </div>

                }
                
            </>
        }
    </div >)
}

export default Staff
import { TableCell, TableRow, Tooltip } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import TableComponent from "../../components/Tables";
import { STATE } from "../../constants";
import { ApiGet, ApiPost, ApiPut } from "../../helper/API/ApiData";
import { getImageUrl, getImageUrl2, numberFormat } from "../../helper/util";
// import Header from './components/header';
// import Sidebar from './components/sidebar'
import { Button } from "primereact/button";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RootState } from "../../redux/store";
import { PERMISSION_TYPE, userPermission } from "./../../helper/permission";
import {
  FileUpload,
  FileUploadHeaderTemplateOptions,
  FileUploadSelectEvent,
  FileUploadUploadEvent,
} from "primereact/fileupload";
import { ProgressBar } from "primereact/progressbar";
import { Height } from "@mui/icons-material";
import _ from "lodash";
import { ConfirmDialog } from "primereact/confirmdialog";

function KycAdmin() {
  let document_data = {
    proof_document: "",
    proof_document_image: "",
    proof_document_link: "Choose Image",
    remark: "",
    adjustment_amount: "",
  };

  const reject_data = {
    comment: "",
  };
  const main_data = {
    id: "",
    business_image: "",
    business_image_link: "",
    profile_image: "",
    profile_image_link: "",
    pan_card: "",
    pan_card_name: "",
    pan_card_link: "Choose Pan Card",
    pan_card_image: "",
    pan_card_error: null,
  };
  const bank_data = {
    account_number: "",
    ifsc: "",
    cheque_image: "",
    cheque_image_name: "",
    cheque_image_link: "Choose Cheque",
    account_number_error: null,
    ifsc_error: null,
    cheque_image_error: null,
  };
  const gst_data = {
    gst_image: "",
    gst_image_name: "",
    gst_image_error: null,
    gstNo_error: null,
    gst_image_link: "Choose Gst",
  };

  const dialog_data = {
    first: false,
    second: false,
    third: false,
    open: false,
  };
  const user = useSelector(
    (state: RootState) => state?.authUser?.userData?.user
  );
  const [activeTab, setActiveTab] = useState(user.role === 'staff_sales' ? "advance-request" : "kyc-review");
  const [kycAdmin, setKycAdmin] = useState([]);
  const [expencePopup, setExpencePopup] = useState(false);
  const [expenceList, setExpenceList] = useState([]);
  const [advanceList, setAdvanceList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [documentPopup, setDocumentPopup] = useState<boolean>(false);
  const [rejectPopup, setRejectPopup] = useState<boolean>(false);
  const [documentPreview, setDocumentPreview] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [permission, setPermission] = useState<any>({});
  const [kycPopUp, setKycPopUp] = useState(dialog_data);
  const decline_data={
   id:'',
   open:false,
   string:''
  }
  const [declinePopUp, setDeclinePopUp] = useState(decline_data);
  const [expenceDocumentOpen, setExpenceDocumentOpen] = useState(false);
  const [expenceData, setExpenceData] = useState<any>({});
  const [formData, setFormData] = useState<any>({
    ...main_data,
    ...bank_data,
    ...gst_data,
  });
  const [constData, setConstData] = useState({
    acc_no: "",
    ifsc_no: "",
    gst_no: "",
  });
  const [reason,setReason]=useState('');
  const [documentData, setDocumentData] = useState<any>(document_data);
  const [documentRejectData, setDocumentRejectData] =
    useState<any>(reject_data);
  const [rejectedId, setRejectedId] = useState<string>("");
  
  const [imageData, setimageData] = useState<string>("");
  const [url, setUrl] = useState<any>("");

  const [kycstate, setKycState] = useState<any>(STATE.DEFAULT_STATE);
  const [advancestate, setAdvanceState] = useState<any>(STATE.DEFAULT_STATE);
  const [expencestate, setExpenceState] = useState<any>(STATE.DEFAULT_STATE);
  const [staffadvancestate, setStaffAdvanceState] = useState<any>(STATE.DEFAULT_STATE);

  const [levelPopUp, setLevelPopUp] = useState<boolean>(false);
  const [levelPopUpId, setLevelPopUpId] = useState<string>("");
  const [selectedLevel, setSelectedLevel] = useState<string>("");

  const [changeDocPopUp, setChangeDocPopUp] = useState(dialog_data);
  const [fileUpload1Size, setFileUpload1Size] = useState(0);
  const fileUploadRef1 = useRef<FileUpload>(null);
  const [fileUpload1Files, setFileUpload1Files] = useState([]);

  const [fileUpload2Size, setFileUpload2Size] = useState(0);
  const fileUploadRef2 = useRef<FileUpload>(null);
  const [fileUpload2Files, setFileUpload2Files] = useState([]);

  const [fileUpload3Size, setFileUpload3Size] = useState(0);
  const fileUploadRef3 = useRef<FileUpload>(null);
  const [fileUpload3Files, setFileUpload3Files] = useState([]);

  const [uploadProofPopup,setUploadProofPopup]=useState(false);
  const [proofUploadSize, setProofUploadSize] = useState(0);
  const proofUploadRef = useRef<FileUpload>(null);
  const [proofUploadFiles, setProofUploadFiles] = useState([]);
  const [bankProofFormData , setBankProofFormData]=useState({
    file:null,
    file_error:null,
    file_link:''
  });
  const[staffAdvance,setStaffAdvance]=useState([]);
  const[caseId,setCaseId]=useState(null);
  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 0,
    search: "",
    
  });
  const [bankData, setBankData] = useState<any>({});
  const [deleteConfirmVisible, setDeleteConfirmVisible] =
    useState<boolean>(false);
  const accept = () => {
    handleFinalSubmit();
  };

  const reject = () => {
    setDeleteConfirmVisible(false);
  };

  const columns = [
    { id: "SRNO", label: "SR.NO." },
    { id: "Name", label: "Name" },
    { id: "City", label: "City" },
    { id: "State", label: "State" },
    { id: "ContactNo", label: "Contact No." },
    { id: "role", label: "Role" },
    { id: "Action", label: "Action" },
  ];

  let advance_columns = [
    { id: "SRNO", label: "SR.NO." },
    { id: "borrower_name", label: "Borrower Name" },
    { id: "financer_name", label: "Financer Name" },
    { id: "loan_amount", label: "Loan Amount" },
    { id: "gross_payout", label: "Gross Payout" },
    { id: "advance_payout", label: "Advance Gross Payout" },
  ];

  let staff_advance_columns = [
    { id: "SRNO", label: "SR.NO." },
    { id: "request_title", label: "Request Title" },
    { id: "requested_by", label: "Request By" },
    { id: "requested_at", label: "Requested Date" },
    // { id: "advance_payout", label: "Advance Gross Payout" },
    { id: "Action", label: "Action" }
  ];

  if (permission[PERMISSION_TYPE.ADMIN]) {
    advance_columns = [
      ...advance_columns,
      { id: "cheker_type", label: "Checker Type" },
      { id: "queueName", label: "Queue" },
    ];
  }

  advance_columns = [...advance_columns, { id: "Action", label: "Action" }];

  const expence_columns = [
    { id: "SRNO", label: "SR.NO." },
    { id: "expense_type", label: "Expence Type" },
    { id: "remark", label: "Remark" },
    { id: "status", label: "Status" },
    { id: "Action", label: "Action" },
  ];

  // const base64ToUint8Array = (base64) => {
  //   const raw = atob(base64);
  //   const uint8Array = new Uint8Array(new ArrayBuffer(raw.length));
  //   for (let i = 0; i < raw.length; i++) {
  //       uint8Array[i] = raw.charCodeAt(i);
  //   }
  //   return uint8Array;
  // };
  const base64toBlob = (data: string) => {
    // Cut the prefix data:application/pdf;base64 from the raw base 64
    const base64WithoutPrefix = data.substr(
      "data:application/pdf;base64,".length
    );

    const bytes = atob(base64WithoutPrefix);
    let length = bytes.length;
    let out = new Uint8Array(length);

    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }

    return new Blob([out], { type: "application/pdf" });
  };
  const handlePageData = (limit: number, page: number = 1) => {
    if (activeTab == "kyc-review") {
      setKycState({
        ...kycstate,
        limit,
        page,
      });
    }
    if (activeTab == "advance-payout-review") {
      setAdvanceState({
        ...advancestate,
        limit,
        page,
      });
    }
    if (activeTab == "other-review") {
      setExpenceState({
        ...expencestate,
        limit,
        page,
      });
    }
    if (activeTab == "advance-request") {
      setStaffAdvanceState({
        ...staffadvancestate,
        limit,
        page,
      });
    }
    
  };

  const handlePage = (page: number) => {
    if (activeTab == "kyc-review") {
      setKycState({
        ...kycstate,
        page,
      });
    }
    if (activeTab == "advance-payout-review") {
      setAdvanceState({
        ...advancestate,
        page,
      });
    }
    if (activeTab == "other-review") {
      setExpenceState({
        ...expencestate,
        page,
      });
    }
    if (activeTab == "advance-request") {
      setStaffAdvanceState({
        ...staffadvancestate,
        page,
      });
    }
  };

  const getkycadmindata = async () => {
    if(user.role === 'admin')
    {await ApiGet(
      `kyc/list-kyc?page=${kycstate.page}&limit=${kycstate.limit}`
    ).then((res: any) => {
      let temp = res.data.result.map((item: any, index: number) => {
        return {
          id: item.kyc_id,
          SRNO: (lazyState.first) + index + 1 ,
          Name: item.first_name,
          City: item.city,
          State: item.state,
          ContactNo: item.mobile,
          Role:item.role,
          business_image: item?.business_image,
          profile_image: item?.profile_image,
          pan_card_image: item?.pan_card_image,
          pan_card_no: item?.pan_no,
          // // pan_card_image_name: isEmpty(item?.pan_card_image_name) ? "Choose Pan Card" : item?.pan_card_image_name,
          cheque_image: item?.cheque_image,
          // // cheque_image_name: isEmpty(item?.cheque_image_name) ? "Choose Cheque" : item?.cheque_image_name,
          // // gst_image_name: isEmpty(item?.gst_image_name) ? "Choose Gst" : item?.gst_image_name,
          gst_image: item?.gst_image,
          gst_no: item?.gst_no ?? "",
          account_number: item?.account_number ?? "",
          ifsc: item?.ifsc ?? "",
          profile_status: item?.profile_completed,
          // cheque_status: item?.cheque_status ?? "",
          // gst_status: item?.gst_status ?? ""
          bank_status: item.kyc_validation?.bank?.status ?? 0,
          personal_status: item.kyc_validation?.personal?.status ?? 0,
          gst_status: item.kyc_validation?.gst?.status ?? 0,
          level_status: item.kyc_validation?.level?.status ?? 0,
          Kyc_validation_gst_des:item?.kyc_validation.gst.description,
          Kyc_validation_personal_des:item?.kyc_validation?.personal.description ?? "",
          Kyc_validation_bank_des:item?.kyc_validation?.bank.description ?? "",
        };
      });
      setKycAdmin(temp);
      setFormData({ ...formData, temp });
      setKycState({
        ...kycstate,
        totalItems: 10,
      });

    });}
  };

  const getStaffAdvancedata = async () => {
    if(user.role === 'staff_sales')
    {
    const modifiedLazyState = { ...lazyState, limit: lazyState.rows };
    delete modifiedLazyState.rows;
    delete modifiedLazyState["multiSortMeta"];
    delete modifiedLazyState["sortField"];
    delete modifiedLazyState["sortOrder"];

    // Function to replace empty arrays in the "value" field with null
    const replaceEmptyValueArrays = (obj) => {
      for (const key in obj) {
        if (
          key === "value" &&
          Array.isArray(obj[key]) &&
          obj[key].length === 0
        ) {
          obj[key] = null;
        } else if (typeof obj[key] === "object") {
          replaceEmptyValueArrays(obj[key]);
        }
      }
      return obj;
    };

    // Call the function to replace empty arrays in the "value" field with null
    const finalModifiedLazyState = replaceEmptyValueArrays(modifiedLazyState);
    finalModifiedLazyState.page++;

    ApiPost(`disbursement/get-all-approve-request`, finalModifiedLazyState).then((res: any) => {
    
      let temp = res.data.result.map((item: any, index: number) => {
        return {
          id:item.entity_id,
          SRNO: (lazyState.first) + index + 1 ,
          logincodelist_id:item.logincodelist_id,
          request_title:item.borrower_name,
          requested_by:item.dsa_name,
          requested_at:new Date(item.disbursement_date).toLocaleString(),
          path:item.advance_proof_path,

        };
      });
setStaffAdvance(temp);      
    });
  }
  };

  const getDowloadProofLink = async (path: any) => {
    if (path != undefined) {
      await ApiPost(`disbursement/download-proof`, {
        path,
      }).then((res: any) => {
        const linkElement = document.createElement("a");
        linkElement.href = res.data.url;
        linkElement.click();
      }).catch((error)=>{
        //  console.log(error)
      });
    } else {
      toast.error("Doc not uploaded", {
        position: "top-right",
        theme: "colored",
      });
    }
  };

  const getLoginCodeList = async () => {
    await ApiGet(`loginCodeList/option-codelist`)
      .then((res: any) => {
        let temp = res.data.result.map((item: any, index: number) => {
          return {
            bank: `${item.bank_name} ${item.product}`,
            id: item._id,
          };
        });
        setBankData(temp)
      })
      .catch((error) => {
        setBankData({});
      });
  };

  const getImageData = async (path: any) => {
    if (path != undefined) {
      await ApiPost(`kyc/preview-image`, {
        path,
      }).then((res: any) => {
        // const blob = base64toBlob(res.data.imageData);
        // 'data:application/pdf;base64,'+
        // const url = URL.createObjectURL(blob);
        // setUrl(url)
        setimageData(res.data.imageData);
      });
    } else {
      toast.error("Doc not uploaded", {
        position: "top-right",
        theme: "colored",
      });
    }
  };

  const getDowloadLink = async (path: any) => {
    if (path != undefined) {
      await ApiPost(`kyc/get-image-dowload-link`, {
        path,
      }).then((res: any) => {
        const linkElement = document.createElement("a");
        linkElement.href = res.data.url;
        linkElement.click();
      });
    } else {
      toast.error("Doc not uploaded", {
        position: "top-right",
        theme: "colored",
      });
    }
  };
  const getAdvanceData = async () => {
    if(user.role === 'admin')
   { await ApiGet(
      `disbursement/get-advance-payout?page=${advancestate.page}&limit=${advancestate.limit}`
    ).then((res: any) => {
      let temp = res?.data?.result?.map((item: any, index: number) => {
        return {
          SRNO: (advancestate.page - 1) * advancestate.limit + index + 1,
          queueName: "maker",
          path:item.advance_proof_path,
          ...item,
        };
      });
      setAdvanceList(temp);
      setAdvanceState({
        ...advancestate,
        totalItems: 10,
      });
    });}
  };

  const editExpence = async (type: string) => {
    const form_data = new FormData();
    if (expenceData.expense_type == "agreement") {
      form_data.append("expense_type", expenceData?.expense_type);
      form_data.append("first_party", expenceData?.first_party);
      form_data.append("second_party", expenceData?.second_party);
      form_data.append("type_of_agreement", expenceData?.type_of_agreement);
      form_data.append("stamp_duty_amount", expenceData?.stamp_duty_amount);
      form_data.append("remark", expenceData?.remark);
      form_data.append("date", expenceData?.date);
      form_data.append("upload_document", expenceData?.upload_document);
    }
    if (expenceData.expense_type == "petrol") {
      form_data.append("expense_type", expenceData?.expense_type);
      form_data.append("travel_from", expenceData?.travel_from);
      form_data.append("travel_to", expenceData?.travel_to);
      form_data.append("total_kms", expenceData?.total_kms);
      form_data.append("total_amount", expenceData?.total_amount);
      form_data.append("date", expenceData?.date);
      form_data.append("remark", expenceData?.remark);
      form_data.append("upload_document", expenceData?.upload_document);
    }
    if (expenceData.expense_type == "hospitality") {
      form_data.append("expense_type", expenceData?.expense_type);
      form_data.append("total_amount", expenceData?.total_amount);
      form_data.append("date", expenceData?.date);
      form_data.append("remark", expenceData?.remark);
      form_data.append("upload_document", expenceData?.upload_document);
    }
    if (expenceData.expense_type == "other") {
      form_data.append("expense_type", expenceData?.expense_type);
      form_data.append("type_of_expense", expenceData?.type_of_expense);
      form_data.append("reason", expenceData?.reason);
      form_data.append("expense_amount", expenceData?.expense_amount);
      form_data.append("remark", expenceData?.remark);
      form_data.append("date", expenceData?.date);
      form_data.append("upload_document", expenceData?.upload_document);
    }
    if (type == "approve") {
      form_data.append("status", "Verified");
    }
    if (type == "reject") {
      form_data.append("status", "Rejected");
    }
    await ApiPut(
      `miscellaneous/under-review/${expenceData._id}`,
      form_data
    ).then((res: any) => {
      setExpenceData({});
      setExpencePopup(false);
      // getexpenceData()
    });
  };

  const handleStatusChange = async (
    id: string,
    type: string,
    status: string
  ) => {
    if (type != "level") {
      await ApiPut(`kyc/verify?id=${id}`, {
        type,
        status,reason
      })
        .then(async (response: any) => {
          setKycPopUp(dialog_data);
          setFormData({ ...main_data, ...bank_data, ...gst_data });
          getkycadmindata();
          setSelectedLevel("");
          setLevelPopUpId("");
          setReason('');
          setDeclinePopUp(decline_data)          
        })
        .catch((error: any) => console.log("Error", error));
    }
    else{
    await ApiPut(`kyc/verify?id=${id}`, {
      type,
      status,
      level: selectedLevel,
    })
      .then(async (response: any) => {
        setKycPopUp(dialog_data);
        setFormData({ ...main_data, ...bank_data, ...gst_data });
        getkycadmindata();
        setSelectedLevel("");
        setLevelPopUpId("");
      })
      .catch((error: any) => console.log("Error", error));}
  };

  useEffect(() => {
    Promise.all([getkycadmindata(),
      getStaffAdvancedata(),
      getLoginCodeList()
    ])
      .then(() => {
        setTimeout(() => {
          setLoader(false);
        }, 250);
      })
      .catch((error) => {
        setLoader(false);
      });
  }, [kycstate.page, kycstate.limit]);

  useEffect(() => {
    getAdvanceData();
  }, [advancestate.page, advancestate.limit]);

  // useEffect(() => {
  //   getexpenceData()
  // }, [expencestate.page, expencestate.limit])

  useEffect(() => {
    const tempPermission = {
      [PERMISSION_TYPE.ADMIN]: userPermission(user, PERMISSION_TYPE.ADMIN),
      [PERMISSION_TYPE.STAFF]: userPermission(user, PERMISSION_TYPE.STAFF),
      [PERMISSION_TYPE.ADVANCE_ACTION_UPLOAD]: userPermission(
        user,
        PERMISSION_TYPE.ADVANCE_ACTION_UPLOAD
      ),
      [PERMISSION_TYPE.ADVANCE_ACTION_APPROVE]: userPermission(
        user,
        PERMISSION_TYPE.ADVANCE_ACTION_APPROVE
      ),
      [PERMISSION_TYPE.ADVANCE_ACTION_REJECT]: userPermission(
        user,
        PERMISSION_TYPE.ADVANCE_ACTION_REJECT
      ),
    };
    setPermission(tempPermission);
  }, [user]);

  const handleKyc = async (id: string, key: string) => {
    setLoader(true)
    let error = { ...formData };
    const ifsc_pattern = /^[A-Z]{4}0[A-Z0-9]{6}$/;
    const bank_account_pattern = /^[0-9]{8,17}$/;
    let gstVal = formData.gst_no;
    if (key === "main") {
      if (fileUploadRef1.current.getFiles().length != 0) {
        const form_data = new FormData();
        form_data.append("business_image", formData.business_image);
        form_data.append("profile_image", formData.profile_image);
        form_data.append("pan_card_image", formData.pan_card);
        if (formData.pan_card_error == null) {
          await ApiPut(`kyc/update?id=${id}`, form_data)
            .then(async (response: any) => {
              if (response) {
                setLoader(false)
                toast.success(" Pan card updated Successfully", {
                  position: "top-right",
                  theme: "colored",
                });
              }
              setChangeDocPopUp(dialog_data);
              // setFormData({ formData });
              getkycadmindata();
              fileUploadRef1.current.clear();
              fileUploadRef2.current.clear();
              fileUploadRef3.current.clear();

            })
            .catch((error: any) => console.log("Error", error));
        }
      } else {
        toast.error("Please Upload Document", {
          position: "top-right",
          theme: "colored",
        });
      }
    }
    if (key === "cheque") {
      if (fileUploadRef2.current.getFiles().length != 0) {
        if (formData.account_number == "") {
          error = {
            ...error,
            account_number_error: "Please Enter Bank Account Number",
          };
        }
        if (
          formData.account_number != "" &&
          !bank_account_pattern.test(formData.account_number)
        ) {
          error = {
            ...error,
            account_number_error: "Please Enter valid Bank Account Number",
          };
        }
        if (formData.ifsc == "") {
          error = { ...error, ifsc_error: "Please Enter IFSC code" };
        }
        if (formData.ifsc != "" && !ifsc_pattern.test(formData.ifsc)) {
          error = { ...error, ifsc_error: "Please Enter valid IFSC code" };
        }
        if (
          formData.cheque_image_link == "Choose Cheque" ||
          formData.Kyc_validation_bank_des == "Rejected"
        ) {
          error = { ...error, cheque_image_error: "Please Upload Document" };
        }

        setFormData(error);
        const form_data = new FormData();
        form_data.append("account_number", formData.account_number);
        form_data.append("ifsc", formData.ifsc);
        form_data.append("cheque_image", formData.cheque_image);
        if (
          error.account_number_error == null &&
          error.ifsc_error == null &&
          error.cheque_image_error == null
        ) {
          await ApiPut(`kyc/update?id=${id}`, form_data)
            .then(async (response: any) => {
              if (response) {
                setLoader(false)
                toast.success("Cheque updated Successfully", {
                  position: "top-right",
                  theme: "colored",
                });
              }
              setChangeDocPopUp(dialog_data);
              // setFormData({ formData });
              getkycadmindata();
              fileUploadRef1.current.clear();
              fileUploadRef2.current.clear();
              fileUploadRef3.current.clear();
            })
            .catch((error: any) => console.log("Error", error));
        }
      } else {
        toast.error("Please Upload Document", {
          position: "top-right",
          theme: "colored",
        });
      }
    }
    if (key === "gst") {
      if (fileUploadRef3.current.getFiles().length != 0) {
        let statecode = gstVal.substring(0, 2);
        let patternstatecode = /^[0-9]{2}$/;
        let threetoseven = gstVal.substring(2, 7);
        let patternthreetoseven = /^[a-zA-Z]{5}$/;
        let seventoten = gstVal.substring(7, 11);
        let patternseventoten = /^[0-9]{4}$/;
        let Twelveth = gstVal.substring(11, 12);
        let patternTwelveth = /^[a-zA-Z]{1}$/;
        let Thirteen = gstVal.substring(12, 13);
        let patternThirteen = /^[1-9a-zA-Z]{1}$/;
        let fourteen = gstVal.substring(13, 14);
        let patternfourteen = /^[zZ]{1}$/;
        let fifteen = gstVal.substring(14, 15);
        let patternfifteen = /^[0-9a-zA-Z]{1}$/;
        if (gstVal != "") {
          if (gstVal.length != 15) {
            error = {
              ...error,
              gstNo_error:
                "Length should be restricted to 15 digits and should not allow anything more or less",
            };
          }
           else if (!patternstatecode.test(statecode)) {
            error = {
              ...error,
              gstNo_error: "First two characters of GST No should be numbers",
            };
          } else if (!patternthreetoseven.test(threetoseven)) {
            error = {
              ...error,
              gstNo_error:
                "Third to seventh characters of GST No should be alphabets",
            };
          } else if (!patternseventoten.test(seventoten)) {
            error = {
              ...error,
              gstNo_error:
                "Eighth to Eleventh characters of GST No should be numbers",
            };
          } else if (!patternTwelveth.test(Twelveth)) {
            error = {
              ...error,
              gstNo_error: "Twelveth character of GST No should be alphabet",
            };
          } else if (!patternThirteen.test(Thirteen)) {
            error = {
              ...error,
              gstNo_error:
                "Thirteen characters of GST No can be either alphabet or numeric",
            };
          } else if (!patternfourteen.test(fourteen)) {
            error = {
              ...error,
              gstNo_error: "Fourteen characters of GST No should be Z",
            };
          } else if (!patternfifteen.test(fifteen)) {
            error = {
              ...error,
              gstNo_error:
                "Fifteen characters of GST No can be either alphabet or numeric",
            };
          } else if (!gstVal.toUpperCase().includes(gstVal.toUpperCase())) {
            error = { ...error, gstNo_error: "Invalid GST No. Please check!" };
          } else
            error = {
              ...error,
              gstNo_error: null,
            };
        }
        setFormData(error);

        const form_data = new FormData();
        form_data.append("gst_image", formData.gst_image);
        form_data.append("gst_no", formData.gst_no);

        if (formData.gstNo_error == null) {
          await ApiPut(`kyc/update?id=${id}`, form_data)
            .then(async (response: any) => {
              if (response) {
                setLoader(false)
                toast.success("GST updated Successfully", {
                  position: "top-right",
                  theme: "colored",
                });
              }
              setChangeDocPopUp(dialog_data);
              // setFormData({ formData});
              getkycadmindata();
              fileUploadRef1.current.clear();
              fileUploadRef2.current.clear();
              fileUploadRef3.current.clear();
            })
            .catch((error: any) => console.log("Error", error));
        }
      } else {
        toast.error("Please Upload Document", {
          position: "top-right",
          theme: "colored",
        });
      }
    }
  };

  const handleProofUpload = async () => {
      if (proofUploadRef.current.getFiles().length != 0) {
        const form_data = new FormData();
        form_data.append("proof_image", bankProofFormData.file);
        if (bankProofFormData.file != null) {
          await ApiPost(`disbursement/upload-advance-proof?id=${caseId}&upload=true`, form_data)
            .then(async (response: any) => {
              if (response) {
                toast.success(" Proof uploaded Successfully", {
                  position: "top-right",
                  theme: "colored",
                });
              }
              // setFormData({ formData });
              getStaffAdvancedata()
              proofUploadRef.current.clear();
              setUploadProofPopup(false);
              setCaseId(null)
            })
            .catch((error: any) => console.log("Error", error));
        }
      } else {
        toast.error("Please Upload Document", {
          position: "top-right",
          theme: "colored",
        });
      }
    
    
  };

  const handleFinalSubmit = async () => {
        await ApiPost(`disbursement/upload-advance-proof?id=${caseId}`)
          .then(async (response: any) => {
            if (response) {
              toast.success(" Request Sent Successfully", {
                position: "top-right",
                theme: "colored",
              });
            }
            // setFormData({ formData });
            getStaffAdvancedata()
            setCaseId(null)

          })
          .catch((error: any) => console.log("Error", error));
      }   
  
  
  const actionAdminYes = async () => {
    const form_data = new FormData();
    form_data.append("proof_document", documentData.proof_document);
    form_data.append("remark", documentData.remark);
    form_data.append("adjustment_amount", documentData.adjustment_amount);
    await ApiPut(
      `disbursement/advance-payout-document/${selectedId}`,
      form_data
    )
      .then((res: any) => {
        setSelectedId("");
        setDocumentPopup(false);
        getAdvanceData();
      })
      .catch((error: any) => {
        toast.error("Something went wrong!", {
          position: "top-right",
          theme: "colored",
        });
      });
  };

  const actionApprove = async (id: string, status: any) => {
    await ApiPut(`disbursement/edit-advance-payout-status?_id=${id}`, {
      status: status,
    })
      .then((res: any) => {
        getAdvanceData();
      })
      .catch((error: any) => {
        toast.error("Something went wrong!", {
          position: "top-right",
          theme: "colored",
        });
      });
  };

  const actionReject = async (id: string) => {
    setRejectPopup(true);

    await ApiPut(`disbursement/reject/${id}`, {
      cheker_type: "maker",
      comment: documentRejectData.comment,
    })
      .then((res: any) => {
        setRejectPopup(false);
        getAdvanceData();
      })
      .catch((error: any) => {
        toast.error("Something went wrong!", {
          position: "top-right",
          theme: "colored",
        });
      });
  };

  const onSelect = (e: FileUploadSelectEvent, setTotalSize, setFiles, link) => {
    let totalSize = 0;
    let files = e.files;

    for (let i = 0; i < files.length; i++) {
      totalSize += files[i].size || 0;
    }

    setTotalSize(totalSize);
    setFiles(files);
  };

  const onUpload = (e: FileUploadUploadEvent, setTotalSize) => {
    let totalSize = 0;

    e.files.forEach((file) => {
      totalSize += file.size || 0;
    });
    setTotalSize(totalSize);
  };

  const onClear = (setTotalSize) => {
    setTotalSize(0);
  };

  const onRemove = (e, totalSize, setTotalSize) => {
    setTotalSize(totalSize - (e.file.size || 0));
  };
  const headerTemplate =
    (totalSize) => (options: FileUploadHeaderTemplateOptions) => {
      const { className, chooseButton } = options;
      const value = totalSize / 10000;
      const formattedValue = totalSize ? `${totalSize / 1000} KB` : "0 B";

      return (
        <div
          className={className}
          style={{
            backgroundColor: "transparent",
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              flex: "1",
            }}
          >
            {chooseButton}
          </div>
          <div className="progress-container">
            <span>{formattedValue} / 1 MB</span>
            <ProgressBar
              value={value}
              showValue={false}
              className="custom-progress-bar"
            ></ProgressBar>
          </div>
        </div>
      );
    };

  const itemTemplate = (file, props) => {
    return (
      <div className="flex align-items-center flex-wrap">
        <div className="flex align-items-center" style={{ width: "40%" }}>
          <span>{file.name}</span>
        </div>
        <Button
          type="button"
          raised
          severity="info"
          icon="pi pi-times"
          className="p-button-outlined p-button-rounded p-button-danger ml-auto"
          onClick={() => props.onRemove(file)}
        />
      </div>
    );
  };

  const customStyles = (hasError) => ({
    control: (base, state) => ({
      ...base,
      borderColor: hasError ? "red" : base.borderColor,
      "&:hover": {
        borderColor: hasError ? "red" : base.borderColor,
      },
    }),
  });

  //  console.log("url  ",url)

  return (
    <div className="overflow-x-hidden">
      {/* <Sidebar />
    <Header /> */}
      {loader ? (
        <>
          {/* START::OVERLAY */}
          <div className="fixed inset-0 w-full h-screen bg-white z-[9999] flex items-center justify-center">
            <img src="assets/logo.svg" />
          </div>
          {/* END::OVERLAY */}
        </>
      ) : (
        <>
          {/* <main className="lg:pl-[280px] pt-[92px]"> */}
          <div className="p-4 sm:p-9 space-y-5">
            {/* START::DASHBOARD HEADER */}
            <div className="flex flex-wrap gap-5 items-center justify-between">
              <div className="md:flex w-full lg:w-[unset] items-center lg:justify-between md:space-x-9 space-y-5 md:space-y-0">
                <h1 className="text-[22px] text-light-gray font-semibold white-nowrap">
                  Pending For Review 
                </h1>

                {/* 
                            if(tab active) 
                                Add class "bg-white rounded-[18px] text-primary"
                            else 
                                Remove class "bg-white rounded-[18px] text-primary"
                        */}
                <ul className="inline-flex items-center p-[2px] text-white bg-primary rounded-[18px] whitespace-nowrap overflow-x-auto">
                  {permission[PERMISSION_TYPE.ADMIN] && <><li>
                      <button
                        className={`min-w-[170px] py-2 px-7 text-xs font-medium ${activeTab === "kyc-review" &&
                          "bg-white rounded-[18px] text-primary"}`}
                        onClick={() => setActiveTab("kyc-review")}
                      >
                        KYC Review
                      </button>
                    </li><li>
                        <button
                          className={`min-w-[170px] py-2 px-7 text-xs font-medium ${activeTab === "advance-payout-review" &&
                            "bg-white rounded-[18px] text-primary"}`}
                          onClick={() => setActiveTab("advance-payout-review")}
                        >
                          Advance Payout Review
                        </button>
                      </li></>}
                  {permission[PERMISSION_TYPE.STAFF] && <li>
                    <button
                      className={`min-w-[170px] py-2 px-7 text-xs font-medium ${
                        activeTab === "advance-request" &&
                        "bg-white rounded-[18px] text-primary"
                      }`}
                      onClick={() => setActiveTab("advance-request")}
                    >
                      Approve Advance Request
                    </button>
                  </li>}
                  {/* <li>
                  <button className={`min-w-[170px] py-2 px-7 text-xs font-medium ${activeTab === "other-review" && "bg-white rounded-[18px] text-primary"}`} onClick={() => setActiveTab("other-review")}>Other Review</button>
                </li> */}
                </ul>
              </div>

              {/* START::EXPORT */}
              <ul className="flex space-x-[18px]">
                {/* <li>
                <button className="flex items-center space-x-[6px] py-2 px-6 rounded-[18px] border border-light-gray text-xs font-semibold">
                  <img src="assets/pdf.svg" alt="" />
                  <span>PDF</span>
                </button>
              </li>
              <li>
                <button className="flex items-center space-x-[6px] py-2 px-6 rounded-[18px] border border-light-gray text-xs font-semibold">
                  <img src="assets/excel.svg" alt="" />
                  <span>EXCEL</span>
                </button>
              </li> */}
              </ul>
              {/* END::EXPORT */}
            </div>
            {/* END::DASHBOARD HEADER */}
            {activeTab == "kyc-review" && (
              <TableComponent
                rows={kycAdmin}
                handlePageData={handlePageData}
                handlePage={handlePage}
                state={kycstate}
                columns={columns}
                renderColumn={(column: any) => {
                  return (
                    <TableCell
                      align="center"
                      className="!bg-gray-200"
                      key={column.id}
                      style={{ background: "gray" }}
                    >
                      {column.label}
                    </TableCell>
                  );
                }}
                renderRow={(row: any) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      <TableCell align="center" component="th" scope="row">
                        {row.SRNO}
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        {row.Name}
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        {row.City}
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        {row.State}
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        {row.ContactNo}
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        {row.Role === 'user' ? 'DSA' : 'Connector'}
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        <ul className="flex space-x-2 justify-center">
                          <li>
                            <Tooltip title="Personal Data">
                              <button
                                className={` ${(((row?.Kyc_validation_personal_des === "Rejected"   ) || ( row?.Kyc_validation_personal_des !== "")) && ( row?.Kyc_validation_personal_des !== "approved" ) ) === true ? 'bg-red-500' : ''} ${row.personal_status === 1 ? 'bg-[#22C55E]': ''}`}
                                onClick={() => {
                                  if (row.personal_status == 1) {
                                    toast.success(
                                      "Personal Details Already Approved",
                                      {
                                        position: "top-right",
                                        theme: "colored",
                                      }
                                    );
                                  } else {
                                    let finalData = {
                                      id: row.id,
                                      business_image_link: getImageUrl2(
                                        row?.business_image
                                      ),
                                      profile_image_link: getImageUrl2(
                                        row?.profile_image
                                      ),
                                      pan_card_name: row?.pan_card_image_name,
                                      pan_card_no: row?.pan_card_no,
                                      pan_card_link: getImageUrl2(
                                        row?.pan_card_image
                                      ),
                                      pan_card_image: row?.pan_card_image,
                                    };
                                    setFormData({ ...formData, ...finalData });
                                    setKycPopUp({ ...kycPopUp, first: true });
                                    setConstData({
                                      acc_no: row.account_number,
                                      ifsc_no: row.ifsc,
                                      gst_no: row.gst_no,
                                    });
                                    setReason(row.Kyc_validation_personal_des)
                                  }
                                }}
                              >
                                <img src="assets/user-card.svg" alt="" 
                                />
                              </button>
                            </Tooltip>
                          </li>
                          <li>
                            <Tooltip title="Bank Data">
                              <button
                                className={` ${(((row?.Kyc_validation_bank_des === "Rejected"  ) || ( row?.Kyc_validation_bank_des !== "")) && ( row?.Kyc_validation_bank_des !== "approved") ) === true ? 'bg-red-500' : ''} ${row.bank_status === 1 ? 'bg-[#22C55E]': ''}`}
                              onClick={() => {
                                  if (row.bank_status == 1) {
                                    toast.success(
                                      "Bank Details Already Approved",
                                      {
                                        position: "top-right",
                                        theme: "colored",
                                      }
                                    );
                                  } else {
                                    let finalData = {
                                      id: row.id,
                                      cheque_image_name: row?.cheque_image_name,
                                      cheque_image_link: getImageUrl2(
                                        row?.cheque_image
                                      ),
                                      account_number: row?.account_number ?? "",
                                      ifsc: row?.ifsc ?? "",
                                      cheque_image: row?.cheque_image,
                                    };
                                    setFormData({ ...formData, ...finalData });
                                    setKycPopUp({ ...kycPopUp, second: true });
                                    setConstData({
                                      acc_no: row.account_number,
                                      ifsc_no: row.ifsc,
                                      gst_no: row.gst_no,
                                    });
                                    setReason(row.Kyc_validation_bank_des)
                                  }
                                }}
                              >
                                <img src="assets/cheque.svg" alt="" />
                              </button>
                            </Tooltip>
                          </li>
                          <li>
                            <Tooltip title="Gst Data">
                              <button
                                className={` ${(((row?.Kyc_validation_gst_des === "Rejected" ) || ( row?.Kyc_validation_gst_des !== "") )&& ( row?.Kyc_validation_gst_des !== "approved") ) === true ? 'bg-red-500' : ''} ${row.gst_status === 1 ? 'bg-[#22C55E]': ''}`}
                                onClick={() => {
                                  if (row.gst_status == 1) {
                                    toast.success(
                                      "Gst Details Already Approved",
                                      {
                                        position: "top-right",
                                        theme: "colored",
                                      }
                                    );
                                  } else {
                                    let finalData = {
                                      id: row.id,
                                      gst_no: row?.gst_no,
                                      gst_image_name: row?.gst_image_name,
                                      gst_image_link: getImageUrl(
                                        row?.gst_image
                                      ),
                                      gst_image: row?.gst_image,
                                    };
                                    setFormData({ ...formData, ...finalData });
                                    setKycPopUp({ ...kycPopUp, third: true });
                                    setConstData({
                                      acc_no: row.account_number,
                                      ifsc_no: row.ifsc,
                                      gst_no: row.gst_no,
                                    });
                                    setReason(row.Kyc_validation_gst_des)
                                  }
                                }}
                              >
                                <img src="assets/discount.svg" alt="" />
                              </button>
                            </Tooltip>
                          </li>
                          {  row.Role !== 'connector' && <li>
                            <Tooltip title="Level">
                              <button
                                className={`  ${row.level_status === 1 ? 'bg-[#22C55E]': ''}`}
                                onClick={() => {
                                  if (row.level_status == 1) {
                                    toast.success("Level Already Assgined", {
                                      position: "top-right",
                                      theme: "colored",
                                    });
                                  } else {
                                    setLevelPopUp(true);
                                    setLevelPopUpId(row.id);
                                  }
                                }}
                              >
                                <img src="assets/user-card.svg" alt="" />
                              </button>
                            </Tooltip>
                          </li>}
                        </ul>
                      </TableCell>
                    </TableRow>
                  );
                }}
              />
            )}
            {activeTab === "advance-payout-review" && (
              <TableComponent
                rows={advanceList}
                handlePageData={handlePageData}
                handlePage={handlePage}
                state={advancestate}
                columns={advance_columns}
                renderColumn={(column: any) => {
                  return (
                    <TableCell
                      align="center"
                      className="!bg-gray-200"
                      key={column.id}
                      style={{ background: "gray" }}
                    >
                      {column.label}
                    </TableCell>
                  );
                }}
                renderRow={(row: any) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      <TableCell align="center" component="th" scope="row">
                        {row.SRNO}
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        {row.borrower_name}
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        {row.financer_name}
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        {numberFormat(row.loan_amount)}
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        {row.gross_payout ?? 0}
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        {row.advance_gross ?? 0}
                      </TableCell>
                      {permission[PERMISSION_TYPE.ADMIN] && (
                        <>
                          <TableCell align="center" component="th" scope="row">
                            {row.cheker_type}
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            {row.queueName}
                          </TableCell>
                        </>
                      )}
                      <TableCell align="center" component="th" scope="row">
                        <ul className="flex space-x-2 justify-end">
                          {/* {permission[PERMISSION_TYPE.ADVANCE_ACTION_UPLOAD] &&
                          <li>
                            <Tooltip title="Upload Data">
                              <button className="tw-modal-footer-button py-2 px-4 font-normal rounded-sm  w-[unset] bg-secondary bg-opacity-10 text-secondary" onClick={() => {
                                setSelectedId(row._id)
                                setDocumentPopup(true)
                              }}>Upload Data</button>
                            </Tooltip>
                          </li>
                        } */}
                          {permission[
                            PERMISSION_TYPE.ADVANCE_ACTION_APPROVE
                          ] && (
                            <li>
                              <Tooltip title="Approve">
                                <button
                                  className="tw-modal-footer-button py-2 px-4 font-normal rounded-sm  w-[unset] bg-secondary text-white"
                                  onClick={() => {
                                    actionApprove(row._id, "approve");
                                  }}
                                >
                                  Approve
                                </button>
                              </Tooltip>
                            </li>
                          )}
                          {permission[
                            PERMISSION_TYPE.ADVANCE_ACTION_REJECT
                          ] && (
                            <li>
                              <Tooltip title="Rejected">
                                <button
                                  className="tw-modal-footer-button py-2 px-4 font-normal rounded-sm  w-[unset] bg-secondary bg-opacity-10 text-secondary"
                                  onClick={() => {
                                    // setRejectedId(row._id)
                                    actionApprove(row._id, "reject");
                                    // setRejectPopup(true)
                                  }}
                                >
                                  Reject
                                </button>
                              </Tooltip>
                            </li>
                          )}
                          <li>
                              <Tooltip title="Rejected">
                                <button
                                  className="tw-modal-footer-button py-2 px-4 font-normal rounded-sm  w-[unset] bg-secondary bg-opacity-10 text-secondary"
                                  onClick={() => {
                                    getDowloadProofLink(row.path);
                                  }}
                                >
                                  Download
                                </button>
                              </Tooltip>
                            </li>
                        </ul>
                      </TableCell>
                    </TableRow>
                  );
                }}
              />
            )}
            {activeTab === "advance-request" && (
              <TableComponent
                rows={staffAdvance}
                handlePageData={handlePageData}
                handlePage={handlePage}
                state={staffadvancestate}
                columns={staff_advance_columns}
                renderColumn={(column: any) => {
                  return (
                    <TableCell
                      align="center"
                      className="!bg-gray-200"
                      key={column.id}
                      style={{ background: "gray" }}
                    >
                      {column.label}
                    </TableCell>
                  );
                }}
                renderRow={(row: any) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      <TableCell align="center" component="th" scope="row">
                        {row.SRNO}
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        {row.request_title+" - "+bankData.find(item => item.id == row.logincodelist_id).bank}
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        {row.requested_by}
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        {row.requested_at}
                      </TableCell>
                     
                      {permission[PERMISSION_TYPE.STAFF] && <TableCell align="center" component="th" scope="row">
                        <ul className="flex space-x-2 justify-end">
                          {/* {permission[PERMISSION_TYPE.ADVANCE_ACTION_UPLOAD] &&
                          <li>
                            <Tooltip title="Upload Data">
                              <button className="tw-modal-footer-button py-2 px-4 font-normal rounded-sm  w-[unset] bg-secondary bg-opacity-10 text-secondary" onClick={() => {
                                setSelectedId(row._id)
                                setDocumentPopup(true)
                              }}>Upload Data</button>
                            </Tooltip>
                          </li>
                        } */}
                          
                            <li>
                              <Tooltip title="Approve">
                                <button
                                  className="tw-modal-footer-button py-2 px-4 font-normal rounded-sm  w-[unset] bg-secondary text-white"
                                  onClick={() => {
                                    setUploadProofPopup(true)
                                    setCaseId(row.id)
                                  }}
                                >
                                  Upload
                                </button>
                              </Tooltip>
                            </li>
                         
                            <li>
                              <Tooltip title="Rejected">
                                <button
                                  className="tw-modal-footer-button py-2 px-4 font-normal rounded-sm  w-[unset] bg-secondary bg-opacity-10 text-secondary"
                                  onClick={() => {
                                    // setRejectedId(row._id)
                                    // setRejectPopup(true)
                                    if(row.path != null){
                                      setDeleteConfirmVisible(true);
                                      setCaseId(row.id)
                                    }
                                    else{
                                      toast.error("Please Upload Document", {
                                        position: "top-right",
                                        theme: "colored",
                                      });
                                    }
                                    
                                  }}
                                >
                                  Move Forward 
                                </button>
                              </Tooltip>
                            </li>
                            
                          
                        </ul>
                      </TableCell>}
                      
                    </TableRow>
                  );
                }}
              />
            )}
            {activeTab === "other-review" && (
              <TableComponent
                rows={expenceList}
                handlePageData={handlePageData}
                handlePage={handlePage}
                state={expencestate}
                columns={expence_columns}
                renderColumn={(column: any) => {
                  return (
                    <TableCell
                      align="center"
                      className="!bg-gray-200"
                      key={column.id}
                      style={{ background: "gray" }}
                    >
                      {column.label}
                    </TableCell>
                  );
                }}
                renderRow={(row: any) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      <TableCell align="center" component="th" scope="row">
                        {row.SRNO}
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        {row.expense_type}
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        {row.remark}
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        {row.status}
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        <ul className="flex space-x-2 justify-center">
                          <li>
                            <Tooltip title="Expence Data">
                              <button
                                onClick={() => {
                                  setExpenceData(row);
                                  setExpenceDocumentOpen(false);
                                  setExpencePopup(true);
                                }}
                              >
                                <img src="assets/user-card.svg" alt="" />
                              </button>
                            </Tooltip>
                          </li>
                        </ul>
                      </TableCell>
                    </TableRow>
                  );
                }}
              />
            )}
          </div>

          <div
            className={`tw-modal ${
              expencePopup ? "flex" : "hidden"
            } !items-start`}
          >
            <div className="tw-modal-content">
              <div className="tw-modal-title">
                <div>Add {expenceData.expense_type} Data</div>
                <button onClick={() => setExpencePopup(false)}>
                  <img src="assets/close-outline.svg" alt="" />
                </button>
              </div>
              {expenceData.expense_type == "agreement" && (
                <div className="tw-modal-body">
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-[22px]">
                    <div className="space-y-1">
                      <label
                        htmlFor="first_party"
                        className={`input-label ${
                          expenceData?.first_party_error ? "text-red-500" : ""
                        }`}
                      >
                        First Party
                      </label>
                      <div
                        id="first_party"
                        className={`input-wrap ${
                          expenceData?.first_party_error ? "border-red-500" : ""
                        }`}
                      >
                        <img src="assets/user.svg" alt="" />
                        <input
                          type="text"
                          name="first_party"
                          value={expenceData.first_party}
                          onChange={(e: any) => {
                            setExpenceData({
                              ...expenceData,
                              first_party: e.target.value,
                              first_party_error: null,
                            });
                          }}
                          className="w-full text-sm"
                          placeholder="Enter first party"
                        />
                      </div>
                      <p className="text-red-500 text-sm">
                        {expenceData?.first_party_error}
                      </p>
                    </div>
                    <div className="space-y-1">
                      <label
                        htmlFor="second_party"
                        className={`input-label ${
                          expenceData?.second_party_error ? "text-red-500" : ""
                        }`}
                      >
                        Second Party
                      </label>
                      <div
                        id="second_party"
                        className={`input-wrap ${
                          expenceData?.second_party_error
                            ? "border-red-500"
                            : ""
                        }`}
                      >
                        <img src="assets/user.svg" alt="" />
                        <input
                          type="text"
                          name="second_party"
                          value={expenceData.second_party}
                          onChange={(e: any) => {
                            setExpenceData({
                              ...expenceData,
                              second_party: e.target.value,
                              second_party_error: null,
                            });
                          }}
                          className="w-full text-sm"
                          placeholder="Enter second party"
                        />
                      </div>
                      <p className="text-red-500 text-sm">
                        {expenceData?.second_party_error}
                      </p>
                    </div>
                    <div className="space-y-1">
                      <label
                        htmlFor="type_of_agreement"
                        className={`input-label ${
                          expenceData?.type_of_agreement_error
                            ? "text-red-500"
                            : ""
                        }`}
                      >
                        Type Of Agreement
                      </label>
                      <div
                        id="type_of_agreement"
                        className={`input-wrap ${
                          expenceData?.type_of_agreement_error
                            ? "border-red-500"
                            : ""
                        }`}
                      >
                        <img src="assets/user.svg" alt="" />
                        <input
                          type="text"
                          name="type_of_agreement"
                          value={expenceData.type_of_agreement}
                          onChange={(e: any) => {
                            setExpenceData({
                              ...expenceData,
                              type_of_agreement: e.target.value,
                              type_of_agreement_error: null,
                            });
                          }}
                          className="w-full text-sm"
                          placeholder="Enter type of agreement"
                        />
                      </div>
                      <p className="text-red-500 text-sm">
                        {expenceData?.type_of_agreement_error}
                      </p>
                    </div>
                    <div className="space-y-1">
                      <label
                        htmlFor="stamp_duty_amount"
                        className={`input-label ${
                          expenceData?.stamp_duty_amount_error
                            ? "text-red-500"
                            : ""
                        }`}
                      >
                        Type Of Agreement
                      </label>
                      <div
                        id="stamp_duty_amount"
                        className={`input-wrap ${
                          expenceData?.stamp_duty_amount_error
                            ? "border-red-500"
                            : ""
                        }`}
                      >
                        <img src="assets/user.svg" alt="" />
                        <input
                          type="number"
                          name="stamp_duty_amount"
                          value={expenceData.stamp_duty_amount}
                          onChange={(e: any) => {
                            setExpenceData({
                              ...expenceData,
                              stamp_duty_amount: e.target.value,
                              stamp_duty_amount_error: null,
                            });
                          }}
                          className="w-full text-sm"
                          placeholder="Enter stamp duty amount"
                        />
                      </div>
                      <p className="text-red-500 text-sm">
                        {expenceData?.stamp_duty_amount_error}
                      </p>
                    </div>
                    <div className="space-y-1">
                      <label
                        htmlFor="remark"
                        className={`input-label ${
                          expenceData?.remark_error ? "text-red-500" : ""
                        }`}
                      >
                        Remark
                      </label>
                      <div
                        id="remark"
                        className={`input-wrap ${
                          expenceData?.remark_error ? "border-red-500" : ""
                        }`}
                      >
                        <img src="assets/user.svg" alt="" />
                        <input
                          type="text"
                          name="remark"
                          value={expenceData?.remark}
                          onChange={(e: any) => {
                            setExpenceData({
                              ...expenceData,
                              remark: e.target.value,
                              remark_error: null,
                            });
                          }}
                          className="w-full text-sm"
                          placeholder="Enter Remark"
                        />
                      </div>
                      <p className="text-red-500 text-sm">
                        {expenceData?.remark_error}
                      </p>
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="pincode" className={`input-label`}>
                        Date
                      </label>
                      <div id="date_of_joining" className={`input-wrap`}>
                        <img src="assets/date.svg" alt="" />
                        <DatePicker
                          selected={expenceData?.date}
                          onChange={(date: any) => {
                            setExpenceData({ ...expenceData, date: date });
                          }}
                          dateFormat="MM/yyyy"
                          showMonthYearPicker
                          showFullMonthYearPicker
                        />
                      </div>
                    </div>
                    <div className="space-y-1">
                      <label
                        htmlFor="amount_income"
                        className={`input-label ${
                          expenceData?.upload_document_error
                            ? "text-red-500"
                            : ""
                        }`}
                      >
                        Upload Document
                      </label>
                      <div
                        id="amount_income"
                        className={`md:flex md:items-start md:space-x-[20px] space-y-4 md:space-y-0 ${
                          expenceData?.upload_document_error
                            ? "border-red-500"
                            : ""
                        }`}
                      >
                        <div className="w-full space-y-1">
                          <div className="input-wrap w-full">
                            <label
                              htmlFor="upload_document"
                              className="block w-full text-sm text-[#808080]"
                            >
                              {expenceData?.upload_document_name
                                ? expenceData?.upload_document_name
                                : "Please Upload Document"}
                            </label>
                          </div>
                          <input
                            type="file"
                            id="upload_document"
                            onChange={(e: any) => {
                              setExpenceData({
                                ...expenceData,
                                upload_document_image: URL.createObjectURL(
                                  e.target.files[0]
                                ),
                                upload_document: e.target.files?.[0],
                                upload_document_name: e.target.files?.[0]?.name,
                              });
                            }}
                            className="text-sm hidden"
                          />
                        </div>
                        <button
                          className="px-8 py-[18px] text-semibold text-sm bg-secondary text-white rounded-lg border border-secondary"
                          onClick={() =>
                            setExpenceDocumentOpen(!expenceDocumentOpen)
                          }
                        >
                          Preview
                        </button>
                      </div>
                      <p className="text-red-500 text-sm">
                        {expenceData?.upload_document_error}
                      </p>
                    </div>
                  </div>
                  {expenceDocumentOpen && (
                    <img
                      src={expenceData.upload_document_image}
                      className="w-full h-full object-center object-cover lg:w-full lg:h-full"
                    />
                  )}
                </div>
              )}
              {expenceData.expense_type == "petrol" && (
                <div className="tw-modal-body">
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-[22px]">
                    <div className="space-y-1">
                      <label
                        htmlFor="amount_income"
                        className={`input-label ${
                          expenceData?.travel_from_error ? "text-red-500" : ""
                        }`}
                      >
                        Travel From
                      </label>
                      <div
                        id="amount_income"
                        className={`input-wrap ${
                          expenceData?.travel_from_error ? "border-red-500" : ""
                        }`}
                      >
                        <img src="assets/user.svg" alt="" />
                        <input
                          type="text"
                          name="amount_income"
                          value={expenceData?.travel_from}
                          onChange={(e: any) => {
                            setExpenceData({
                              ...expenceData,
                              travel_from: e.target.value,
                              travel_from_error: null,
                            });
                          }}
                          className="w-full text-sm"
                          placeholder="Enter Travel From"
                        />
                      </div>
                      <p className="text-red-500 text-sm">
                        {expenceData?.travel_from_error}
                      </p>
                    </div>
                    <div className="space-y-1">
                      <label
                        htmlFor="amount_income"
                        className={`input-label ${
                          expenceData?.travel_to_error ? "text-red-500" : ""
                        }`}
                      >
                        Travel To
                      </label>
                      <div
                        id="amount_income"
                        className={`input-wrap ${
                          expenceData?.travel_to_error ? "border-red-500" : ""
                        }`}
                      >
                        <img src="assets/user.svg" alt="" />
                        <input
                          type="text"
                          name="amount_income"
                          value={expenceData?.travel_to}
                          onChange={(e: any) => {
                            setExpenceData({
                              ...expenceData,
                              travel_to: e.target.value,
                              travel_to_error: null,
                            });
                          }}
                          className="w-full text-sm"
                          placeholder="Enter Travel To"
                        />
                      </div>
                      <p className="text-red-500 text-sm">
                        {expenceData?.travel_to_error}
                      </p>
                    </div>
                    <div className="space-y-1">
                      <label
                        htmlFor="amount_income"
                        className={`input-label ${
                          expenceData?.total_kms_error ? "text-red-500" : ""
                        }`}
                      >
                        Total Kms
                      </label>
                      <div
                        id="amount_income"
                        className={`input-wrap ${
                          expenceData?.total_kms_error ? "border-red-500" : ""
                        }`}
                      >
                        <img src="assets/user.svg" alt="" />
                        <input
                          type="text"
                          name="amount_income"
                          value={expenceData?.total_kms}
                          onChange={(e: any) => {
                            setExpenceData({
                              ...expenceData,
                              total_kms: e.target.value,
                              total_kms_error: null,
                            });
                          }}
                          className="w-full text-sm"
                          placeholder="Enter Total Kms"
                        />
                      </div>
                      <p className="text-red-500 text-sm">
                        {expenceData?.total_kms_error}
                      </p>
                    </div>
                    <div className="space-y-1">
                      <label
                        htmlFor="total_amount"
                        className={`input-label ${
                          expenceData?.total_amount_error ? "text-red-500" : ""
                        }`}
                      >
                        Total Amount
                      </label>
                      <div
                        id="total_amount"
                        className={`input-wrap ${
                          expenceData?.total_amount_error
                            ? "border-red-500"
                            : ""
                        }`}
                      >
                        <img src="assets/user.svg" alt="" />
                        <input
                          type="text"
                          name="total_amount"
                          value={expenceData?.total_amount}
                          onChange={(e: any) => {
                            setExpenceData({
                              ...expenceData,
                              total_amount: e.target.value,
                              total_amount_error: null,
                            });
                          }}
                          className="w-full text-sm"
                          placeholder="Enter Total Amount"
                        />
                      </div>
                      <p className="text-red-500 text-sm">
                        {expenceData?.total_amount_error}
                      </p>
                    </div>
                    <div className="space-y-1">
                      <label
                        htmlFor="remark"
                        className={`input-label ${
                          expenceData?.remark_error ? "text-red-500" : ""
                        }`}
                      >
                        Remark
                      </label>
                      <div
                        id="remark"
                        className={`input-wrap ${
                          expenceData?.remark_error ? "border-red-500" : ""
                        }`}
                      >
                        <img src="assets/user.svg" alt="" />
                        <input
                          type="text"
                          name="remark"
                          value={expenceData?.remark}
                          onChange={(e: any) => {
                            setExpenceData({
                              ...expenceData,
                              remark: e.target.value,
                              remark_error: null,
                            });
                          }}
                          className="w-full text-sm"
                          placeholder="Enter Remark"
                        />
                      </div>
                      <p className="text-red-500 text-sm">
                        {expenceData?.remark_error}
                      </p>
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="pincode" className={`input-label`}>
                        Date
                      </label>
                      <div id="date_of_joining" className={`input-wrap`}>
                        <img src="assets/date.svg" alt="" />
                        <DatePicker
                          selected={expenceData?.date}
                          onChange={(date: any) => {
                            setExpenceData({ ...expenceData, date: date });
                          }}
                          dateFormat="MM/yyyy"
                          showMonthYearPicker
                          showFullMonthYearPicker
                        />
                      </div>
                    </div>
                    <div className="space-y-1">
                      <label
                        htmlFor="amount_income"
                        className={`input-label ${
                          expenceData?.upload_document_error
                            ? "text-red-500"
                            : ""
                        }`}
                      >
                        Upload Document
                      </label>
                      <div
                        id="amount_income"
                        className={`md:flex md:items-start md:space-x-[20px] space-y-4 md:space-y-0 ${
                          expenceData?.upload_document_error
                            ? "border-red-500"
                            : ""
                        }`}
                      >
                        <div className="w-full space-y-1">
                          <div className="input-wrap w-full">
                            <label
                              htmlFor="upload_document"
                              className="block w-full text-sm text-[#808080]"
                            >
                              {expenceData?.upload_document_name}
                            </label>
                          </div>
                          <input
                            type="file"
                            id="upload_document"
                            onChange={(e: any) => {
                              setExpenceData({
                                ...expenceData,
                                upload_document_image: URL.createObjectURL(
                                  e.target.files[0]
                                ),
                                upload_document: e.target.files?.[0],
                                upload_document_name: e.target.files?.[0]?.name,
                              });
                            }}
                            className="text-sm hidden"
                          />
                        </div>
                        <button
                          className="px-8 py-[18px] text-semibold text-sm bg-secondary text-white rounded-lg border border-secondary"
                          onClick={() =>
                            setExpenceDocumentOpen(!expenceDocumentOpen)
                          }
                        >
                          Preview
                        </button>
                      </div>
                      <p className="text-red-500 text-sm">
                        {expenceData?.upload_document_error}
                      </p>
                    </div>
                  </div>
                  {expenceDocumentOpen && (
                    <img
                      src={expenceData.upload_document_image}
                      className="w-full h-full object-center object-cover lg:w-full lg:h-full"
                    />
                  )}
                </div>
              )}
              {expenceData.expense_type == "hospitality" && (
                <div className="tw-modal-body">
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-[22px]">
                    <div className="space-y-1">
                      <label
                        htmlFor="total_amount"
                        className={`input-label ${
                          expenceData?.total_amount_error ? "text-red-500" : ""
                        }`}
                      >
                        Total Amount
                      </label>
                      <div
                        id="total_amount"
                        className={`input-wrap ${
                          expenceData?.total_amount_error
                            ? "border-red-500"
                            : ""
                        }`}
                      >
                        <img src="assets/user.svg" alt="" />
                        <input
                          type="text"
                          name="total_amount"
                          value={expenceData?.total_amount}
                          onChange={(e: any) => {
                            setExpenceData({
                              ...expenceData,
                              total_amount: e.target.value,
                              total_amount_error: null,
                            });
                          }}
                          className="w-full text-sm"
                          placeholder="Enter Travel From"
                        />
                      </div>
                      <p className="text-red-500 text-sm">
                        {expenceData?.total_amount_error}
                      </p>
                    </div>
                    <div className="space-y-1">
                      <label
                        htmlFor="remark"
                        className={`input-label ${
                          expenceData?.remark_error ? "text-red-500" : ""
                        }`}
                      >
                        Remark
                      </label>
                      <div
                        id="remark"
                        className={`input-wrap ${
                          expenceData?.remark_error ? "border-red-500" : ""
                        }`}
                      >
                        <img src="assets/user.svg" alt="" />
                        <input
                          type="text"
                          name="remark"
                          value={expenceData?.remark}
                          onChange={(e: any) => {
                            setExpenceData({
                              ...expenceData,
                              remark: e.target.value,
                              remark_error: null,
                            });
                          }}
                          className="w-full text-sm"
                          placeholder="Enter Remark"
                        />
                      </div>
                      <p className="text-red-500 text-sm">
                        {expenceData?.remark_error}
                      </p>
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="pincode" className={`input-label`}>
                        Date
                      </label>
                      <div id="date_of_joining" className={`input-wrap`}>
                        <img src="assets/date.svg" alt="" />
                        <DatePicker
                          selected={expenceData?.date}
                          onChange={(date: any) => {
                            setExpenceData({ ...expenceData, date: date });
                          }}
                          dateFormat="MM/yyyy"
                          showMonthYearPicker
                          showFullMonthYearPicker
                        />
                      </div>
                    </div>
                    <div className="space-y-1">
                      <label
                        htmlFor="amount_income"
                        className={`input-label ${
                          expenceData?.upload_document_error
                            ? "text-red-500"
                            : ""
                        }`}
                      >
                        Upload Document
                      </label>
                      <div
                        id="amount_income"
                        className={`md:flex md:items-start md:space-x-[20px] space-y-4 md:space-y-0 ${
                          expenceData?.upload_document_error
                            ? "border-red-500"
                            : ""
                        }`}
                      >
                        <div className="w-full space-y-1">
                          <div className="input-wrap w-full">
                            <label
                              htmlFor="upload_document"
                              className="block w-full text-sm text-[#808080]"
                            >
                              {expenceData?.upload_document_name}
                            </label>
                          </div>
                          <input
                            type="file"
                            id="upload_document"
                            onChange={(e: any) => {
                              setExpenceData({
                                ...expenceData,
                                upload_document_image: URL.createObjectURL(
                                  e.target.files[0]
                                ),
                                upload_document: e.target.files?.[0],
                                upload_document_name: e.target.files?.[0]?.name,
                              });
                            }}
                            className="text-sm hidden"
                          />
                        </div>
                        <button
                          className="px-8 py-[18px] text-semibold text-sm bg-secondary text-white rounded-lg border border-secondary"
                          onClick={() =>
                            setExpenceDocumentOpen(!expenceDocumentOpen)
                          }
                        >
                          Preview
                        </button>
                      </div>
                      <p className="text-red-500 text-sm">
                        {expenceData?.upload_document_error}
                      </p>
                    </div>
                  </div>
                  {expenceDocumentOpen && (
                    <img
                      src={expenceData.upload_document_image}
                      className="w-full h-full object-center object-cover lg:w-full lg:h-full"
                    />
                  )}
                </div>
              )}
              {expenceData.expense_type == "other" && (
                <div className="tw-modal-body">
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-[22px]">
                    <div className="space-y-1">
                      <label
                        htmlFor="type_of_expense"
                        className={`input-label ${
                          expenceData?.type_of_expense_error
                            ? "text-red-500"
                            : ""
                        }`}
                      >
                        Type Of Expense
                      </label>
                      <div
                        id="type_of_expense"
                        className={`input-wrap ${
                          expenceData?.type_of_expense_error
                            ? "border-red-500"
                            : ""
                        }`}
                      >
                        <img src="assets/user.svg" alt="" />
                        <input
                          type="text"
                          name="type_of_expense"
                          value={expenceData?.type_of_expense}
                          onChange={(e: any) => {
                            setExpenceData({
                              ...expenceData,
                              type_of_expense: e.target.value,
                              type_of_expense_error: null,
                            });
                          }}
                          className="w-full text-sm"
                          placeholder="Enter Travel From"
                        />
                      </div>
                      <p className="text-red-500 text-sm">
                        {expenceData?.type_of_expense_error}
                      </p>
                    </div>
                    <div className="space-y-1">
                      <label
                        htmlFor="reason"
                        className={`input-label ${
                          expenceData?.reason_error ? "text-red-500" : ""
                        }`}
                      >
                        Reason
                      </label>
                      <div
                        id="reason"
                        className={`input-wrap ${
                          expenceData?.reason_error ? "border-red-500" : ""
                        }`}
                      >
                        <img src="assets/user.svg" alt="" />
                        <input
                          type="text"
                          name="reason"
                          value={expenceData?.reason}
                          onChange={(e: any) => {
                            setExpenceData({
                              ...expenceData,
                              reason: e.target.value,
                              reason_error: null,
                            });
                          }}
                          className="w-full text-sm"
                          placeholder="Enter Travel To"
                        />
                      </div>
                      <p className="text-red-500 text-sm">
                        {expenceData?.reason_error}
                      </p>
                    </div>
                    <div className="space-y-1">
                      <label
                        htmlFor="expense_amount"
                        className={`input-label ${
                          expenceData?.expense_amount_error
                            ? "text-red-500"
                            : ""
                        }`}
                      >
                        Expense Amount
                      </label>
                      <div
                        id="expense_amount"
                        className={`input-wrap ${
                          expenceData?.expense_amount_error
                            ? "border-red-500"
                            : ""
                        }`}
                      >
                        <img src="assets/user.svg" alt="" />
                        <input
                          type="text"
                          name="expense_amount"
                          value={expenceData?.expense_amount}
                          onChange={(e: any) => {
                            setExpenceData({
                              ...expenceData,
                              expense_amount: e.target.value,
                              expense_amount_error: null,
                            });
                          }}
                          className="w-full text-sm"
                          placeholder="Enter Total Kms"
                        />
                      </div>
                      <p className="text-red-500 text-sm">
                        {expenceData?.expense_amount_error}
                      </p>
                    </div>
                    <div className="space-y-1">
                      <label
                        htmlFor="remark"
                        className={`input-label ${
                          expenceData?.remark_error ? "text-red-500" : ""
                        }`}
                      >
                        Remark
                      </label>
                      <div
                        id="remark"
                        className={`input-wrap ${
                          expenceData?.remark_error ? "border-red-500" : ""
                        }`}
                      >
                        <img src="assets/user.svg" alt="" />
                        <input
                          type="text"
                          name="remark"
                          value={expenceData?.remark}
                          onChange={(e: any) => {
                            setExpenceData({
                              ...expenceData,
                              remark: e.target.value,
                              remark_error: null,
                            });
                          }}
                          className="w-full text-sm"
                          placeholder="Enter Remark"
                        />
                      </div>
                      <p className="text-red-500 text-sm">
                        {expenceData?.remark_error}
                      </p>
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="pincode" className={`input-label`}>
                        Date
                      </label>
                      <div id="date_of_joining" className={`input-wrap`}>
                        <img src="assets/date.svg" alt="" />
                        <DatePicker
                          selected={expenceData?.date}
                          onChange={(date: any) => {
                            setExpenceData({ ...expenceData, date: date });
                          }}
                          dateFormat="MM/yyyy"
                          showMonthYearPicker
                          showFullMonthYearPicker
                        />
                      </div>
                    </div>
                    <div className="space-y-1">
                      <label
                        htmlFor="amount_income"
                        className={`input-label ${
                          expenceData?.upload_document_error
                            ? "text-red-500"
                            : ""
                        }`}
                      >
                        Upload Document
                      </label>
                      <div
                        id="amount_income"
                        className={`md:flex md:items-start md:space-x-[20px] space-y-4 md:space-y-0 ${
                          expenceData?.upload_document_error
                            ? "border-red-500"
                            : ""
                        }`}
                      >
                        <div className="w-full space-y-1">
                          <div className="input-wrap w-full">
                            <label
                              htmlFor="upload_document"
                              className="block w-full text-sm text-[#808080]"
                            >
                              {expenceData?.upload_document_name}
                            </label>
                          </div>
                          <input
                            type="file"
                            id="upload_document"
                            onChange={(e: any) => {
                              setExpenceData({
                                ...expenceData,
                                upload_document_image: URL.createObjectURL(
                                  e.target.files[0]
                                ),
                                upload_document: e.target.files?.[0],
                                upload_document_name: e.target.files?.[0]?.name,
                              });
                            }}
                            className="text-sm hidden"
                          />
                        </div>
                        <button
                          className="px-8 py-[18px] text-semibold text-sm bg-secondary text-white rounded-lg border border-secondary"
                          onClick={() =>
                            setExpenceDocumentOpen(!expenceDocumentOpen)
                          }
                        >
                          Preview
                        </button>
                      </div>
                      <p className="text-red-500 text-sm">
                        {expenceData?.upload_document_error}
                      </p>
                    </div>
                  </div>
                  {expenceDocumentOpen && (
                    <img
                      src={expenceData.upload_document_image}
                      className="w-full h-full object-center object-cover lg:w-full lg:h-full"
                    />
                  )}
                </div>
              )}
              <div className="tw-modal-footer">
                <button
                  className="tw-modal-footer-button bg-secondary text-white"
                  onClick={() => editExpence("")}
                >
                  Change Data
                </button>
                <button
                  className="tw-modal-footer-button bg-secondary text-white"
                  onClick={() => editExpence("approve")}
                >
                  Approve
                </button>
                <button
                  className="tw-modal-footer-button bg-secondary bg-opacity-10 text-secondary"
                  onClick={() => {
                    editExpence("reject");
                  }}
                >
                  Reject
                </button>
              </div>
            </div>
          </div>

          {/* Start::KYC Admin Pan Card Modal */}
          <div className={`tw-modal ${kycPopUp.first ? "flex" : "hidden"} !items-start`}>
            <div
              className="tw-modal-content"
              style={{
                minHeight: "300px",
                maxHeight: "1000px",
                overflowY: "hidden",
              }}
            >
              <div className="tw-modal-title">
                <div>KYC Admin</div>
                <button onClick={() => setKycPopUp(dialog_data)}>
                  <img src="assets/close-outline.svg" alt="" />
                </button>
              </div>
              <div className="tw-modal-body">
                {/* <div className="md:flex items-center md:space-x-10 space-y-10 md:space-y-0">
                  <div className="flex items-center space-x-[26px] pb-5">
                    <div className="w-[150px] h-[150px]">
                      <label
                        htmlFor="business_image"
                        className="overflow-hidden w-full h-full border-2 flex items-center justify-center border-dashed border-black rounded-full text-[#C2C2C2]"
                      >
                        {formData.business_image_link == "" && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-12 w-12"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 4v16m8-8H4"
                            />
                          </svg>
                        )}
                        {formData.business_image_link != "" && (
                          <img
                            src={formData.business_image_link}
                            className="w-full h-full object-center object-cover lg:w-full lg:h-full"
                          />
                        )}
                      </label>
                      <input
                        type="file"
                        id="business_image"
                        className="hidden"
                        onChange={(e: any) => {
                          setFormData({
                            ...formData,
                            business_image: e.target.files[0],
                            business_image_link: URL.createObjectURL(
                              e.target.files[0]
                            ),
                          });
                        }}
                      />
                    </div>
                    <h4 className="font-semibold text-light-gray text-lg">
                      Add Business <br /> Premises Photos
                    </h4>
                  </div>

                  <div className="flex items-center space-x-[26px] pb-5">
                    <div className="w-[150px] h-[150px]">
                      <label
                        htmlFor="profile_image"
                        className="overflow-hidden w-full h-full border-2 flex items-center justify-center border-dashed border-black rounded-full text-[#C2C2C2]"
                      >
                        {formData.profile_image_link == "" && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-12 w-12"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 4v16m8-8H4"
                            />
                          </svg>
                        )}
                        {formData.profile_image_link != "" && (
                          <img
                            src={formData.profile_image_link}
                            className="w-full h-full object-center object-cover lg:w-full lg:h-full"
                          />
                        )}
                      </label>
                      <input
                        type="file"
                        id="profile_image"
                        onChange={(e: any) => {
                          setFormData({
                            ...formData,
                            profile_image: e.target.files[0],
                            profile_image_link: URL.createObjectURL(
                              e.target.files[0]
                            ),
                          });
                        }}
                        className="hidden"
                      />
                    </div>
                    <h4 className="font-semibold text-light-gray text-lg">
                      Add Photo
                    </h4>
                  </div>
                </div> */}
                <p className="input-label" style={{ fontSize: '20px'}}>Pancard Details</p>
                {/* START::PAN CARD */}
                <div className="space-y-1">
                  <div className="md:flex md:items-start md:space-x-[20px] space-y-4 md:space-y-0">
                  <div className="w-full md:flex md:space-x-5 md:space-y-0 space-y-5 !mb-4">
                    <span>
                      Pan Card {": " + formData?.pan_card_no}
                    </span>
                    </div>
                    </div>
                    <div className="md:flex md:items-start md:space-x-[20px] space-y-4 md:space-y-0 mt-3">
                    <Button
                      icon="pi pi-expand"
                      raised
                      severity="info"
                      aria-label="Preview"
                      onClick={() => {
                        getImageData(formData.pan_card_image);
                        setKycPopUp({ ...kycPopUp, open: !kycPopUp.open });
                      }}
                    >
                      <span style={{ marginLeft: "8px" }}>Preview</span>
                    </Button>
                    <Button
                      icon="pi pi-download"
                      raised
                      severity="info"
                      aria-label="Download"
                      onClick={() => {
                        getDowloadLink(formData.pan_card_image);
                      }}
                    >
                      <span style={{ marginLeft: "8px" }}>Download</span>
                    </Button>
                    <Button
                      icon="pi pi-pen-to-square"
                      raised
                      severity="info"
                      aria-label="Change document"
                      onClick={() => {
                        setChangeDocPopUp({ ...changeDocPopUp, first: true });
                      }}
                    >
                      <span style={{ marginLeft: "8px" }}>Change Document</span>
                    </Button>
                    </div>
                  {/* {(kycPopUp.open && formData.pan_card_link != "") && <img src={formData.pan_card_link} className="w-full h-full object-center object-cover lg:w-full lg:h-full" />
                } */}
                </div>
                {/* END::PAN CARD */}
              </div>
              {kycAdmin.map((kyc: any, index) => {
                return kyc.id == formData.id
                  ? kycPopUp.open &&
                      kyc.pan_card_image != "" &&
                      (kyc.pan_card_image?.split(".").pop() == "pdf" ? (
                        <iframe
                          src={`data:application/pdf;base64,${imageData}`}
                          title="Preview PDF"
                          width="100%"
                          height="500px"
                        />
                      ) : (
                        <img
                          style={{ height: "600px", width: "auto" }}
                          src={`data:image/jpeg;base64,${imageData}`}
                          className="ml-5 object-center object-cover"
                        />
                      ))
                  : null;
              })}

              <div className="space-y-1 mb-3 mt-3 ml-1">
                <div className="md:flex md:items-start md:space-x-[20px] space-y-4 md:space-y-0">
                  <Button
                    className="ml-4"
                    icon="pi pi-check"
                    raised
                    severity="info"
                    aria-label="Accept"
                    onClick={() =>
                      handleStatusChange(formData.id, "profile", "approved")
                    }
                  >
                    <span style={{ marginLeft: "8px", marginRight: "8px" }}>
                      Accept
                    </span>
                  </Button>
                  <Button
                    icon="pi pi-times"
                    raised
                    severity="danger"
                    aria-label="Cancel"
                    onClick={() => {
                      setDeclinePopUp({id:formData.id, string:'profile', open:true})
                    }}
                  >
                    <span style={{ marginLeft: "8px", marginRight: "8px" }}>
                      Decline
                    </span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {/* End::KYC Admin Pan Card Modal */}
          <div
            className={`tw-modal ${changeDocPopUp.first ? "flex" : "hidden"}`}
          >
            <div className="tw-modal-content">
              <div className="tw-modal-title">
                Change Pan card Document
                <button
                  onClick={() => {
                    setChangeDocPopUp(dialog_data);
                    fileUploadRef1.current.clear();
                  }}
                >
                  <img src="assets/close-outline.svg" alt="" />
                </button>
              </div>
              <div className={`tw-modal-body `}>
                <div
                  className={`${
                    formData.pan_card_error ? " border-2 border-red-500 " : ""
                  }`}
                >
                  <FileUpload
                    // mode="basic"
                    ref={fileUploadRef1}
                    name="demo[]"
                    accept=".jpg,.jpeg,.png,.gif,.pdf"
                    maxFileSize={1000000}
                    // onUpload={(e) => onUpload(e, setFileUpload1Size)}
                    onSelect={(e) => {
                      onSelect(
                        e,
                        setFileUpload1Size,
                        setFileUpload1Files,
                        "pan"
                      );
                      setFormData({
                        ...formData,
                        pan_card: e.files[0],
                        pan_card_link: e.files[0].name,
                        pan_card_error: null,
                      });
                    }}
                    onError={() => onClear(setFileUpload1Size)}
                    onClear={() => onClear(setFileUpload1Size)}
                    onRemove={(e) =>
                      onRemove(e, fileUpload1Size, setFileUpload1Size)
                    }
                    headerTemplate={headerTemplate(fileUpload1Size)}
                    itemTemplate={itemTemplate}
                    emptyTemplate={
                      <p className="m-0">
                        Drag and drop files to here to upload.
                      </p>
                    }
                    disabled={formData.Kyc_validation_personal === 1}
                  />
                </div>
                <p className="text-red-500 text-sm">
                  {formData.pan_card_error}
                </p>
              </div>
              <div className="tw-modal-footer">
                <Button
                  icon="pi pi-check"
                  raised
                  severity="info"
                  onClick={() => {
                    handleKyc(formData.id, "main");
                  }}
                >
                  <span style={{ marginLeft: "8px" }}> Save</span>
                </Button>
                <Button
                  icon="pi pi-times"
                  raised
                  severity="danger"
                  onClick={() => {
                    setChangeDocPopUp(dialog_data);
                    fileUploadRef1.current.clear();
                  }}
                >
                  {" "}
                  <span style={{ marginLeft: "8px" }}> Cancel</span>
                </Button>
              </div>
            </div>
          </div>
          {/* Start::KYC Admin Cheque Modal */}
          <div className={`tw-modal ${kycPopUp.second ? "flex" : "hidden"} !items-start`}>
            <div
              className="tw-modal-content"
              style={{
                minHeight: "300px",
                maxHeight: "1000px",
                overflowY: "hidden",
              }}
            >
              <div className="tw-modal-title">
                <div>KYC Admin</div>
                <button onClick={() => setKycPopUp(dialog_data)}>
                  <img src="assets/close-outline.svg" alt="" />
                </button>
              </div>
              <div className="tw-modal-body">
              <p className="input-label" style={{ fontSize: '20px'}}>Bank Details</p>
                <div className="space-y-1">
                  <div className="md:flex md:items-start md:space-x-[20px] space-y-4 md:space-y-0">
                    <div className="w-full md:flex md:space-x-5 md:space-y-0 space-y-5 !mb-4">
                      <div>
                        <span> Bank Account No: {" " + constData?.acc_no}</span>
                      </div>
                      <div>
                        <span> IFSC No: {" " + constData?.ifsc_no}</span>
                      </div>
                    </div>
                    </div>
                  <div className="md:flex md:items-start md:space-x-[20px] space-y-4 md:space-y-0 ">
                    <Button
                      icon="pi pi-expand"
                      raised
                      severity="info"
                      aria-label="Preview"
                      onClick={() => {
                        getImageData(formData.cheque_image);
                        setKycPopUp({ ...kycPopUp, open: !kycPopUp.open });
                      }}
                    >
                      <span style={{ marginLeft: "8px" }}>Preview</span>
                    </Button>
                    <Button
                      icon="pi pi-download"
                      raised
                      severity="info"
                      aria-label="Download"
                      onClick={() => {
                        // Retrieve the download link
                        getDowloadLink(formData.cheque_image);
                      }}
                    >
                      <span style={{ marginLeft: "8px" }}>Download</span>
                    </Button>
                    <Button
                      icon="pi pi-pen-to-square"
                      raised
                      severity="info"
                      aria-label="Change document"
                      onClick={() => {
                        setChangeDocPopUp({ ...changeDocPopUp, second: true });
                      }}
                    >
                      <span style={{ marginLeft: "8px" }}>Change Document</span>
                    </Button>
                  </div>
                  {/* {(kycPopUp.open && formData.cheque_image_link != "") && <img src={formData.cheque_image_link} className="w-full h-full object-center object-cover lg:w-full lg:h-full" />
               } */}
                </div>
              </div>
              {kycPopUp.open &&
                // formData.cheque_image_link != "" &&
                // (url ? <Worker workerUrl={'https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js'}> <Viewer fileUrl={url}  /> </Worker>: null)

                (formData.cheque_image_link?.split(".").pop() == "pdf" ? (
                  <iframe
                    src={`data:application/pdf;base64,${imageData}`}
                    title="Preview PDF"
                    width="100%"
                    height="500px"
                  />
                ) : (
                  //  <a target="_blank" href={`http://139.59.17.191:5009${formData.cheque_image_link}`}>
                  //    <button className='px-16 mt-2 py-[18px] text-semibold text-sm bg-secondary text-white rounded-lg border border-secondary'>Open Document</button>
                  //  </a>
                  <img
                    src={`data:image;base64,${imageData}`}
                    alt="Image not Found"
                    className="object-center object-cover "
                    style={{ height: "600px", width: "auto" }}
                  />
                ))}
              <div className="space-y-1 mt-3 mb-3">
                <div className="md:flex md:items-start md:space-x-[20px] space-y-4 md:space-y-0">
                  <Button
                    className="ml-5 mb-3"
                    icon="pi pi-check"
                    raised
                    severity="info"
                    aria-label="Accept"
                    onClick={() =>
                      handleStatusChange(formData.id, "cheque", "approved")
                    }
                  >
                    <span style={{ marginLeft: "8px" }}>Accept</span>
                  </Button>
                  <Button
                    className="mb-3"
                    icon="pi pi-times"
                    raised
                    severity="danger"
                    aria-label="Cancel"
                    onClick={() => {
                      setDeclinePopUp({id:formData.id, string:'cheque', open:true})
                    }}
                  >
                    <span style={{ marginLeft: "8px" }}>Decline</span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {/* End::KYC Admin cheque Modal */}
          <div
            className={`tw-modal ${changeDocPopUp.second ? "flex" : "hidden"}`}
          >
            <div className="tw-modal-content">
              <div className="tw-modal-title">
                Change Bank Document
                <button
                  onClick={() => {
                    setChangeDocPopUp(dialog_data);
                    fileUploadRef2.current.clear();
                  }}
                >
                  <img src="assets/close-outline.svg" alt="" />
                </button>
              </div>
              <div className="tw-modal-body">
                <div className="w-full md:flex md:space-x-5 md:space-y-0 space-y-5 !mb-4">
                  <div className="w-full space-y-1">
                    <label htmlFor="bank" className="input-label">
                      Bank Account No.
                    </label>
                    <div
                      id="bank"
                      className={`input-wrap w-full ${
                        formData.account_number_error ? "border-red-500" : ""
                      }`}
                    >
                      <input
                        type="text"
                        className={`block w-full text-sm text-[#808080] ${
                          formData.account_number_error ? "text-red-500" : ""
                        }`}
                        placeholder="Bank Account Number"
                        value={formData.account_number}
                        onChange={(e: any) => {
                          setFormData({
                            ...formData,
                            account_number: e.target.value,
                            account_number_error: null,
                          });
                        }}
                      />
                    </div>
                    <p className="text-red-500 text-sm">
                      {formData.account_number_error}
                    </p>
                  </div>
                  <div className="w-full space-y-1">
                    <label htmlFor="bank" className="input-label">
                      IFSC code
                    </label>
                    <div
                      id="email"
                      className={`input-wrap w-full ${
                        formData.ifsc_error ? "border-red-500" : ""
                      }`}
                    >
                      <input
                        type="text"
                        value={formData.ifsc}
                        onChange={(e: any) => {
                          setFormData({
                            ...formData,
                            ifsc: e.target.value,
                            ifsc_error: null,
                          });
                        }}
                        className={`block w-full text-sm text-[#808080] ${
                          formData.ifsc_error ? "text-red-500" : ""
                        }`}
                        placeholder="IFSC Code"
                      />
                    </div>
                    <p className="text-red-500 text-sm">
                      {formData.ifsc_error}
                    </p>
                  </div>
                </div>
                <div
                  className={`${
                    formData.cheque_image_error
                      ? " border-2 border-red-500 "
                      : ""
                  }`}
                >
                  <FileUpload
                    // mode="basic"
                    ref={fileUploadRef2}
                    name="demo[]"
                    accept=".jpg,.jpeg,.png,.gif,.pdf"
                    maxFileSize={1000000}
                    // onUpload={(e) => onUpload(e, setFileUpload1Size)}
                    onSelect={(e) => {
                      onSelect(
                        e,
                        setFileUpload2Size,
                        setFileUpload2Files,
                        "pan"
                      );
                      setFormData({
                        ...formData,
                        cheque_image: e.files[0],
                        cheque_image_link: e.files[0].name,
                        cheque_image_error: null,
                      });
                    }}
                    onError={() => onClear(setFileUpload2Size)}
                    onClear={() => onClear(setFileUpload2Size)}
                    onRemove={(e) =>
                      onRemove(e, fileUpload2Size, setFileUpload2Size)
                    }
                    headerTemplate={headerTemplate(fileUpload2Size)}
                    itemTemplate={itemTemplate}
                    emptyTemplate={
                      <p className="m-0">
                        Drag and drop files to here to upload.
                      </p>
                    }
                  />
                </div>
                <p className="text-red-500 text-sm">
                  {" "}
                  {formData.cheque_image_error}
                </p>
              </div>
              <div className="tw-modal-footer">
                <Button
                  icon="pi pi-check"
                  raised
                  severity="info"
                  onClick={() => {
                    handleKyc(formData.id, "cheque");
                  }}
                >
                  <span style={{ marginLeft: "8px" }}> Save</span>
                </Button>
                <Button
                  icon="pi pi-times"
                  raised
                  severity="danger"
                  onClick={() => {
                    setChangeDocPopUp(dialog_data);
                    fileUploadRef2.current.clear();
                  }}
                >
                  {" "}
                  <span style={{ marginLeft: "8px" }}> Cancel</span>
                </Button>
              </div>
            </div>
          </div>
          {/* Start::KYC Admin GST Modal */}
          <div className={`tw-modal  ${kycPopUp.third ? "flex" : "hidden"} !items-start`}>
            <div
              className="tw-modal-content"
              style={{
                minHeight: "300px",
                maxHeight: "1000px",
                overflowY: "hidden",
              }}
            >
              <div className="tw-modal-title">
                <div>KYC Admin</div>
                <button onClick={() => setKycPopUp(dialog_data)}>
                  <img src="assets/close-outline.svg" alt="" />
                </button>
              </div>
              <div className="tw-modal-body ">
              <p className="input-label " style={{ fontSize: "20px" }}> GST Details
                    </p>
                <div className="space-y-1">
                  <div className="md:flex md:items-start md:space-x-[20px] space-y-4 md:space-y-0">
                  <div className="w-full md:flex md:space-x-5 md:space-y-0 space-y-5 !mb-4">
                    <span>
                      GST No. {": " + constData?.gst_no}
                    </span>
                    </div>
                    </div>
                    <div className="md:flex md:items-start md:space-x-[20px] space-y-4 md:space-y-0">
                    <Button
                      className=""
                      icon="pi pi-expand"
                      raised
                      severity="info"
                      aria-label="Preview"
                      onClick={() => {
                        getImageData(formData.gst_image);
                        setKycPopUp({ ...kycPopUp, open: !kycPopUp.open });
                      }}
                    >
                      <span style={{ marginLeft: "8px" }}>Preview</span>
                    </Button>
                    <Button
                      icon="pi pi-download"
                      raised
                      severity="info"
                      aria-label="Download"
                      onClick={() => {
                        getDowloadLink(formData.gst_image);
                      }}
                    >
                      <span style={{ marginLeft: "8px" }}>Download</span>
                    </Button>
                    <Button
                      icon="pi pi-pen-to-square"
                      raised
                      severity="info"
                      aria-label="Change document"
                      onClick={() => {
                        setChangeDocPopUp({ ...changeDocPopUp, third: true });
                      }}
                    >
                      {" "}
                      <span style={{ marginLeft: "8px" }}>Change Document</span>
                    </Button>
                  </div>
                </div>
              </div>
              {kycPopUp.open &&
                formData.gst_image_link != "" &&
                (formData.gst_image_link?.split(".").pop() == "pdf" ? (
                  <iframe
                    src={`data:application/pdf;base64,${imageData}`}
                    title="Preview PDF"
                    width="100%"
                    height="500px"
                  />
                ) : (
                  <img
                    style={{ height: "600px", width: "auto" }}
                    src={`data:image/jpeg;base64,${imageData}`}
                    alt="Image not Found"
                    className="object-center object-cover "
                  />
                ))}
              <div className="space-y-1 mb-3 mt-3">
                <div className="md:flex md:items-start md:space-x-[20px] space-y-4 md:space-y-0">
                  <Button
                    className="ml-6"
                    icon="pi pi-check"
                    raised
                    severity="info"
                    aria-label="Accept"
                    onClick={() =>
                      handleStatusChange(formData.id, "gst", "approved")
                    }
                  >
                    <span style={{ marginLeft: "8px" }}>Accept</span>
                  </Button>
                  <Button
                    icon="pi pi-times"
                    raised
                    severity="danger"
                    aria-label="Cancel"
                    onClick={() => {
                      setDeclinePopUp({id:formData.id, string:'gst', open:true})
                    }}
                  >
                    <span style={{ marginLeft: "8px" }}>Decline</span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {/* End::KYC GST Modal */}
          <div
            className={`tw-modal ${changeDocPopUp.third ? "flex" : "hidden"}`}
          >
            <div className="tw-modal-content">
              <div className="tw-modal-title">
                Change GST Document
                <button
                  onClick={() => {
                    setChangeDocPopUp(dialog_data);
                    fileUploadRef3.current.clear();
                  }}
                >
                  <img src="assets/close-outline.svg" alt="" />
                </button>
              </div>
              <div className="tw-modal-body">
                <div className="w-full md:flex md:space-x-5 md:space-y-0 space-y-5 !mb-4">
                  <div id="email" className="w-full space-y-1">
                    <label htmlFor="gst" className="input-label">
                      GST No.
                    </label>
                    <div
                      id="gst_no"
                      className={`input-wrap w-full ${
                        formData.gstNo_error ? "border-red-500" : ""
                      }`}
                    >
                      <input
                        type="text"
                        value={formData.gst_no}
                        onChange={(e: any) => {
                          setFormData({
                            ...formData,
                            gst_no: e.target.value,
                            gstNo_error: null,
                          });
                        }}
                        className={`block w-full text-sm text-[#808080] ${
                          formData.gstNo_error ? "text-red-500" : ""
                        }`}
                        placeholder="Enter GST Number"
                        disabled={
                          formData.Kyc_validation_gst === 1 ? true : false
                        }
                      />
                    </div>
                    <p className="text-red-500 text-sm">
                      {formData.gstNo_error}
                    </p>
                  </div>
                </div>
                <div
                  className={`${
                    formData.gst_image_error ? " border-2 border-red-500 " : ""
                  }`}
                >
                  <FileUpload
                    // mode="basic"
                    ref={fileUploadRef3}
                    name="demo[]"
                    accept=".jpg,.jpeg,.png,.gif,.pdf"
                    maxFileSize={1000000}
                    // onUpload={(e) => onUpload(e, setFileUpload1Size)}
                    onSelect={(e) => {
                      onSelect(
                        e,
                        setFileUpload3Size,
                        setFileUpload3Files,
                        "pan"
                      );
                      setFormData({
                        ...formData,
                        gst_image: e.files[0],
                        gst_image_link: e.files[0].name,
                        gst_image_error: null,
                      });
                    }}
                    onError={() => onClear(setFileUpload3Size)}
                    onClear={() => onClear(setFileUpload3Size)}
                    onRemove={(e) =>
                      onRemove(e, fileUpload3Size, setFileUpload3Size)
                    }
                    headerTemplate={headerTemplate(fileUpload3Size)}
                    itemTemplate={itemTemplate}
                    emptyTemplate={
                      <p className="m-0">
                        Drag and drop files to here to upload.
                      </p>
                    }
                    disabled={formData.Kyc_validation_personal === 1}
                  />
                </div>
                <p className="text-red-500 text-sm">
                  {" "}
                  {formData.gst_image_error}
                </p>
              </div>
              <div className="tw-modal-footer">
                <Button
                  icon="pi pi-check"
                  raised
                  severity="info"
                  onClick={() => {
                    handleKyc(formData.id, "gst");
                  }}
                >
                  <span style={{ marginLeft: "8px" }}> Save</span>
                </Button>
                <Button
                  icon="pi pi-times"
                  raised
                  severity="danger"
                  onClick={() => {
                    setChangeDocPopUp(dialog_data);
                    fileUploadRef3.current.clear();
                  }}
                >
                  {" "}
                  <span style={{ marginLeft: "8px" }}> Cancel</span>
                </Button>
              </div>
            </div>
          </div>
          <div
            className={`tw-modal ${
              documentPopup ? "flex" : "hidden"
            } !items-start`}
          >
            <div className="tw-modal-content">
              <div className="tw-modal-title">
                <div>Document</div>
                <button onClick={() => setDocumentPopup(false)}>
                  <img src="assets/close-outline.svg" alt="" />
                </button>
              </div>

              <div className="tw-modal-body">
                <div className="space-y-1">
                  {/* <p className="input-label">Cheque</p> */}
                  <div className="md:flex md:items-start md:space-x-[20px] space-y-4 md:space-y-0">
                    <div className="w-full md:flex md:space-x-5 md:space-y-0 space-y-5 !mb-4">
                      <div className="w-full space-y-1">
                        <div id="remark" className="input-wrap w-full">
                          <input
                            type="text"
                            className="block w-full text-sm text-[#808080]"
                            placeholder="Remark"
                            value={documentData.remark}
                            onChange={(e: any) => {
                              setDocumentData({
                                ...documentData,
                                remark: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="w-full space-y-1">
                        <div
                          id="adjustment_amount"
                          className="input-wrap w-full"
                        >
                          <input
                            type="text"
                            value={documentData.adjustment_amount}
                            onChange={(e: any) => {
                              setDocumentData({
                                ...documentData,
                                adjustment_amount: e.target.value,
                              });
                            }}
                            className="block w-full text-sm text-[#808080]"
                            placeholder="Adjustment Amount"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="md:flex md:items-start md:space-x-[20px] space-y-4 md:space-y-0">
                    <div className="w-full space-y-1">
                      <div className="input-wrap w-full">
                        <label
                          htmlFor="proof_document"
                          className="block w-full text-sm text-[#808080]"
                        >
                          {documentData.proof_document_link}
                        </label>
                      </div>
                      <input
                        type="file"
                        id="proof_document"
                        onChange={(e: any) => {
                          setDocumentData({
                            ...documentData,
                            proof_type: e.target.files[0].type
                              ?.split("/")
                              .pop(),
                            proof_document: e.target.files[0],
                            proof_document_link: e.target.files?.[0]?.name,
                            proof_document_image: URL.createObjectURL(
                              e.target.files[0]
                            ),
                          });
                        }}
                        className="text-sm hidden"
                        accept="image/*, .pdf"
                      />
                    </div>
                    <button
                      className="px-16 py-[18px] text-semibold text-sm bg-secondary text-white rounded-lg border border-secondary"
                      onClick={() => setDocumentPreview(!documentPreview)}
                    >
                      Preview
                    </button>
                  </div>
                  {/* {(documentPreview && documentData.proof_document_image != "") && 
                <img src={documentData.proof_document_image} className="w-full h-full object-center object-cover lg:w-full lg:h-full" />
                } */}
                  {documentPreview &&
                  documentData.proof_document_image != "" &&
                  documentData.proof_type == "pdf" ? (
                    <a target="_blank" href={documentData.proof_document_image}>
                      <button className="px-16 mt-2 py-[18px] text-semibold text-sm bg-secondary text-white rounded-lg border border-secondary">
                        Open Document
                      </button>
                    </a>
                  ) : (
                    documentPreview &&
                    documentData.proof_document_image != "" && (
                      <img
                        src={documentData.proof_document_image}
                        className="w-full h-full object-center object-cover lg:w-full lg:h-full"
                      />
                    )
                  )}
                </div>
              </div>
              {/* <a target="_blank" href={`http://139.59.17.191:5009${kyc.pan_card_image}`}>
                              <button className='px-16 mt-2 py-[18px] text-semibold text-sm bg-secondary text-white rounded-lg border border-secondary'>Open Document</button>
                            </a> */}
              <div className="tw-modal-footer">
                <button
                  className="tw-modal-footer-button bg-secondary text-white"
                  onClick={() => {
                    actionAdminYes();
                  }}
                >
                  Submit
                </button>
                <button
                  className="tw-modal-footer-button bg-secondary bg-opacity-10 text-secondary"
                  onClick={() => {
                    setDocumentPopup(false);
                  }}
                >
                  Cancle
                </button>
              </div>
            </div>
          </div>

          <div
            className={`tw-modal ${
              rejectPopup ? "flex" : "hidden"
            } !items-start`}
          >
            <div className="tw-modal-content">
              <div className="tw-modal-title">
                <div>Document</div>
                <button onClick={() => setDocumentRejectData(false)}>
                  <img src="assets/close-outline.svg" alt="" />
                </button>
              </div>

              <div className="tw-modal-body">
                <div className="space-y-1">
                  {/* <p className="input-label">Cheque</p> */}
                  <div className="md:flex md:items-start md:space-x-[20px] space-y-4 md:space-y-0">
                    <div className="w-full md:flex md:space-x-5 md:space-y-0 space-y-5 !mb-4">
                      <div className="w-full space-y-1">
                        <div id="comment " className="input-wrap w-full">
                          <input
                            type="text"
                            className="block w-full text-sm text-[#808080]"
                            placeholder="Remark"
                            value={documentRejectData.comment}
                            onChange={(e: any) => {
                              setDocumentRejectData({
                                ...documentRejectData,
                                comment: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="tw-modal-footer">
                <button
                  className="tw-modal-footer-button bg-secondary text-white"
                  onClick={() => {
                    actionReject(rejectedId);
                  }}
                >
                  Submit
                </button>
                <button
                  className="tw-modal-footer-button bg-secondary bg-opacity-10 text-secondary"
                  onClick={() => {
                    setRejectPopup(false);
                  }}
                >
                  Cancle
                </button>
              </div>
            </div>
          </div>

          <div
            className={`tw-modal ${
              declinePopUp.open ? "flex" : "hidden"
            } !items-start`}
          >
            <div className="tw-modal-content">
              <div className="tw-modal-title">
                <div>Decline{declinePopUp.open}</div>
                <button onClick={() => setDeclinePopUp(decline_data)}>
                  <img src="assets/close-outline.svg" alt="" />
                </button>
              </div>

              <div className="tw-modal-body">
                <div className="space-y-1">
                  <p className="input-label">Decline Reason</p>
                  <div className="md:flex md:items-start md:space-x-[20px] space-y-4 md:space-y-0">
                    <div className="w-full md:flex md:space-x-5 md:space-y-0 space-y-5 !mb-4">
                      <div className="w-full space-y-1">
                        <div id="reason" className="input-wrap w-full">
                          <input
                            type="text"
                            className="block w-full text-sm text-[#808080]"
                            placeholder="Enter reason for rejection"
                            value={reason}
                            onChange={(e: any) => {
                              setReason(e.target.value);
                            }}
                          />
                          <img src="assets/eye-gray.svg" alt="" />
                        </div>
                      </div>
                      {/* <div className="w-full space-y-1">
            <div id="email" className="input-wrap w-full">
              <input type="text" value={formData.ifsc} onChange={(e: any) => {
                setFormData({ ...formData, ifsc: e.target.value })
              }} className="block w-full text-sm text-[#808080]" placeholder="IFSC Code" />
              <img src="assets/eye-gray.svg" alt="" />
            </div>
          </div> */}
                    </div>
                    <button
                      disabled
                      className="hidden md:block opacity-0 px-16 py-[18px] text-semibold text-sm bg-secondary text-white rounded-lg border border-secondary"
                    >
                      Upload
                    </button>
                  </div>
                  {/* <div className='md:flex md:items-start md:space-x-[20px] space-y-4 md:space-y-0'>
        <div className="w-full space-y-1">
          <div id="email" className="input-wrap w-full">
            <label htmlFor="cheque_image" className="block w-full text-sm text-[#808080]">{formData.cheque_image_name}</label>
          </div>
          <input type="file" id="cheque_image" onChange={(e: any) => {
            setFormData({ ...formData, cheque_image: e.target.files[0], cheque_image_name: e.target.files?.[0]?.name, cheque_image_link: URL.createObjectURL(e.target.files[0]) })
          }} className="text-sm hidden" />
        </div>
        <button className='px-16 py-[18px] text-semibold text-sm bg-secondary text-white rounded-lg border border-secondary' onClick={() => setKycPopUp({ ...kycPopUp, open: !kycPopUp.open })}>Preview</button>
      </div> */}
                  {/* {(kycPopUp.open && formData.cheque_image_link != "") && <img src={formData.cheque_image_link} className="w-full h-full object-center object-cover lg:w-full lg:h-full" />
      } */}
                  {/* {
        kycPopUp.open && formData.cheque_image_link != "" &&
        (
          formData.cheque_image_link?.split(".").pop() == "pdf" ?
            <a target="_blank" href={`http://139.59.17.191:5009${formData.cheque_image_link}`}>
              <button className='px-16 mt-2 py-[18px] text-semibold text-sm bg-secondary text-white rounded-lg border border-secondary'>Open Document</button>
            </a>
            : <img src={formData.cheque_image_link} alt="Image not Found" className="w-full h-full object-center object-cover lg:w-full lg:h-full" />
        )
      } */}
                </div>
              </div>

              <div className="tw-modal-footer">
                {/* <button className='tw-modal-footer-button bg-secondary bg-opacity-10 text-secondary' onClick={() => {
      handleKyc(formData.id, "cheque");
    }}>Change Document</button> */}
                
                <Button
                  raised
                  severity="info"
                  className="tw-modal-footer-button text-white text-secondary"
                  onClick={() => {
                    handleStatusChange(declinePopUp.id, declinePopUp.string, "rejected");
                  }}
                >
                  Save
                </Button>
                <Button
                  raised
                  severity="danger"
                  className="tw-modal-footer-button text-white"
                  onClick={() => setDeclinePopUp(decline_data)}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>

          <div
            className={`tw-modal ${
              levelPopUp ? "flex" : "hidden"
            } items-start `}
          >
            <div className="tw-modal-content ">
              <div className="tw-modal-title">
                <div className="text-2xl font-bold">Set-Level</div>
                <button onClick={() => setLevelPopUp(false)}>
                  <img src="assets/close-outline.svg" alt="Close" />
                </button>
              </div>
              <label
                htmlFor="dropdown"
                className="block mb-2 text-sm font-medium"
              >
                {/* Level: */}
              </label>
              <div className="tw-modal-body">
                <div className="space-y-1">
                  <select
                    id="dropdown"
                    name="level"
                    required
                    className={`w-full text-sm text-[#808080]  px-5 py-3  mb-10  border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 `}
                    aria-describedby="level-error"
                    onChange={(e) => setSelectedLevel(e.target.value)}
                    // className="w-full text-sm text-[#808080]"
                  >
                    <option value="">Select a level...</option>
                    <option value="bronze">Bronze</option>
                    <option value="silver">Silver</option>
                    <option value="gold">Gold</option>
                  </select>
                  <p
                    id="level-error"
                    className="text-red-500 text-sm"
                    style={{ display: "none" ,fontSize:'15px'}}
                  >
                    Please select a level.
                  </p>
                </div>
              </div>
              <p className="ml-20 mb-5">
                **NOTE :- Once level applied it will not changed afterwards. **
              </p>

              <div className="tw-modal-footer">
                <Button
                  icon="pi pi-check"
                  raised
                  severity="info"
                  onClick={() => {
                    if (!selectedLevel) {
                      document.getElementById("level-error").style.display =
                        "block";
                    } else {
                      handleStatusChange(levelPopUpId, "level", "");
                      setLevelPopUp(false);
                    }
                  }}
                >
                  <span style={{ marginLeft: "8px" }}> Save</span>
                </Button>
                <Button
                  icon="pi pi-times"
                  raised
                  severity="danger"
                  onClick={() => {
                    setLevelPopUp(false);
                    setSelectedLevel("");
                    setLevelPopUpId("");
                  }}
                >
                  {" "}
                  <span style={{ marginLeft: "8px" }}> Cancel</span>
                </Button>
              </div>
            </div>
          </div>

          <div
            className={`tw-modal ${uploadProofPopup ? "flex" : "hidden"}`}
          >
            <div className="tw-modal-content">
              <div className="tw-modal-title">
                Upload Bank Proof
                <button
                  onClick={() => {
                    setUploadProofPopup(false);
                    proofUploadRef.current.clear();
                  }}
                >
                  <img src="assets/close-outline.svg" alt="" />
                </button>
              </div>
              <div className={`tw-modal-body `}>
                <div
                  className={`${
                    bankProofFormData.file_error ? " border-2 border-red-500 " : ""
                  }`}
                >
                  <FileUpload
                    // mode="basic"
                    ref={proofUploadRef}
                    name="demo[]"
                    accept=".jpg,.jpeg,.png,.gif,.pdf"
                    maxFileSize={1000000}
                    // onUpload={(e) => onUpload(e, setFileUpload1Size)}
                    onSelect={(e) => {
                      onSelect(
                        e,
                        setProofUploadSize,
                        setProofUploadFiles,
                        "pan"
                      );
                      setBankProofFormData({
                        ...bankProofFormData,
                        file: e.files[0],
                        file_link: e.files[0].name,
                        file_error: null,
                      });
                    }}
                    onError={() => onClear(setProofUploadSize)}
                    onClear={() => onClear(setProofUploadSize)}
                    onRemove={(e) =>
                      onRemove(e, proofUploadSize, setProofUploadSize)
                    }
                    headerTemplate={headerTemplate(proofUploadSize)}
                    itemTemplate={itemTemplate}
                    emptyTemplate={
                      <p className="m-0">
                        Drag and drop files to here to upload.
                      </p>
                    }
                  />
                </div>
                <p className="text-red-500 text-sm">
                  {bankProofFormData.file_error}
                </p>
              </div>
              <div className="tw-modal-footer">
                <Button
                  icon="pi pi-check"
                  raised
                  severity="info"
                  onClick={() => {
                    handleProofUpload()
                  }}
                >
                  <span style={{ marginLeft: "8px" }}> Save</span>
                </Button>
                <Button
                  icon="pi pi-times"
                  raised
                  severity="danger"
                  onClick={() => {
                    setUploadProofPopup(false);
                    proofUploadRef.current.clear();
                    setCaseId(null)
                  }}
                >
                  {" "}
                  <span style={{ marginLeft: "8px" }}> Cancel</span>
                </Button>
              </div>
            </div>
          </div>
          <ConfirmDialog
            // group="declarative"
            visible={deleteConfirmVisible}
            onHide={() => setDeleteConfirmVisible(false)}
            message="Are you sure you want to move forward with the request?"
            header="Confirmation"
            icon="pi pi-exclamation-triangle"
            accept={accept}
            reject={reject}
            style={{ width: "50vw" }}
            breakpoints={{ "1100px": "75vw", "960px": "100vw" }}
          />
          {/* </main> */}
        </>
      )}
    </div>
  );
}

export default KycAdmin;

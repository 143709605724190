import { useState } from "react"
import { useNavigate } from "react-router";
import { ApiPost } from "../helper/API/ApiData";
import CryptoJS from "crypto-js";


function ForgotPassword() {
    let navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [msgshow, setMsgShow] = useState("");


    const resetpassword = () => {

        ApiPost("auth/send-email", { email })
            .then((res:any) => {
                setMsgShow(res.data.message)
            })
    // navigate("/dashboard",{replace: true});

    }
    return (
        <section className="auth-page-section">

            {/* START::DIALOG */}
            <div className="popup-box max-w-[500px]">

                {/* START::HEADER */}
                <div className="flex items-center justify-between">
                    <h1 className="text-[22px] font-semibold">Forgot Password</h1>
                </div>
                {/* END::HEADER */}

                {/* START::FORM */}
                <div className="space-y-[22px]">
                    <div className="space-y-1">
                        <label htmlFor="email" className="input-label">Email</label>
                        <div id="email" className="p-[18px] border border-[#DEDEDE] flex items-center space-x-2 rounded-lg">
                            <img src="assets/email.svg" alt="" />
                            <input type="text"
                                className="w-full text-sm"
                                placeholder="johndue@xyz.com"
                                name="email"
                                value={email}
                                onChange={(e: any) => { setEmail(e.target.value) }}
                            />
                        </div>
                    </div>
                </div>
<span className="space-x-[22]">{msgshow}</span>
                <button className="form-submit-button" onClick={resetpassword}>Reset Password</button>
                {/* END::FORM */}

                {/* START::FOOTER */}
                <div className="flex items-center justify-center space-x-2">
                    <span>Back to</span>
                    <a href="/" className="font-semibold text-[#008BD0]">Sign in?</a>
                </div>
                {/* END::FOOTER */}

            </div>
            {/* END::DIALOG */}

        </section >
    )
}

export default ForgotPassword
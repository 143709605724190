import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { ApiGet, ApiPost, ApiPut } from '../../helper/API/ApiData'
import { getImageUrl, isEmpty } from '../../helper/util'
import { Panel } from 'primereact/panel'
import './KycStatus.css'
import { Button } from 'primereact/button'
import { FileUpload, FileUploadHeaderTemplateOptions, FileUploadSelectEvent, FileUploadUploadEvent } from 'primereact/fileupload'
import { ProgressBar } from 'primereact/progressbar'
import { deserialize } from 'v8'
import './KycStatus.css'
const Kyc = () => {

  const main_data = {
    business_image: "",
    business_image_link: "",
    profile_image: "",
    profile_image_link: "",
    pan_card: "",
    pan_card_error: null,
    pan_card_link: "Choose Pan Card"
  }
  const bank_data = {
    account_number: "",
    account_number_error: null,
    ifsc: "",
    ifsc_error: null,
    cheque_image: "",
    cheque_image_error: null,
    cheque_image_link: "Choose Cheque",
  }
  const gst_data = {
    gst_image: "",
    gst_image_error: null,
    gst_image_link: "Choose Gst",
    gstNo_error:null
  }
  const status = {
    profile_status: "",
    cheque_status: "",
    gst_status: ""
  }

  const [formData, setFormData] = useState<any>({ ...main_data, ...bank_data, ...gst_data, ...status });
  const [UserDetails, setUserDetails] = useState<any>([]);

  const [response1, setResponse1] = useState(null);
  const [response2, setResponse2] = useState(null);
  const [response3, setResponse3] = useState(null);
  const [response4, setResponse4] = useState(null);

  const [fileUpload1Size, setFileUpload1Size] = useState(0);
  const fileUploadRef1 = useRef<FileUpload>(null);
  const [fileUpload1Files, setFileUpload1Files] = useState([]);

  const [fileUpload2Size, setFileUpload2Size] = useState(0);
  const fileUploadRef2 = useRef<FileUpload>(null);
  const [fileUpload2Files, setFileUpload2Files] = useState([]);

  const [fileUpload3Size, setFileUpload3Size] = useState(0);
  const fileUploadRef3 = useRef<FileUpload>(null);
  const [fileUpload3Files, setFileUpload3Files] = useState([]);

  const [businessImageSize, setBusinessImageSize] = useState(0);
  const businessImageRef = useRef<FileUpload>(null);
  const [businessImageFile, setBusinessImageFile] = useState([]);

  const [profilePhotoSize, setProfilePhotoSize] = useState(0);
  const profilePhotoRef = useRef<FileUpload>(null);
  const [profilePhotoFile, setProfilePhotoFile] = useState([]);



  const [gstData, setGstData] = useState<any>({
    gst_no: "",
    gstNo_error: null,
  });
  const [loader, setLoader] = useState(true);

  const getData = () => {
    ApiGet(`kyc/kyc-details`)
      .then((res: any) => {
        let finalData = {
          business_image_link: getImageUrl(res?.data?.user?.business_image),
          profile_image_link: getImageUrl(res?.data?.user?.profile_image),
          pan_card_link: isEmpty(res?.data?.user?.pan_card_image) ? "Choose Pan Card" : "Reupload",
          cheque_image_link: isEmpty(res?.data?.user?.cheque_image) ? "Choose Cheque" : "Reupload",
          gst_image_link: isEmpty(res?.data?.user?.gst_image_name) ? "Choose Gst" : res?.data?.user?.gst_image_name,
          business_image: getImageUrl(res?.data?.user?.business_image),
          profile_image: getImageUrl(res?.data?.user?.profile_image),
          pan_card: res?.data?.user?.pan_card_image,
          cheque_image: res?.data?.user?.cheque_image ,
          gst_image: res?.data?.user?.gst_image_name,
          account_number: res?.data?.user?.account_number ?? "",
          ifsc: res?.data?.user?.ifsc ?? "",
          profile_status: res?.data?.user?.profile_status ?? "",
          gst_no:res?.data?.user?.gst_no,
          final_kyc_status: res?.data?.user?.kyc_status ?? "",
          gst_status: res?.data?.user?.gst_status ?? "",
          Kyc_validation_gst:res?.data?.user?.kyc_validation.gst.status,
          Kyc_validation_personal:res?.data?.user?.kyc_validation?.personal.status ?? "",
          Kyc_validation_bank:res?.data?.user?.kyc_validation?.bank.status ?? "",
          Kyc_validation:res?.data?.user?.kyc_validation,
          Kyc_validation_gst_des:res?.data?.user?.kyc_validation.gst.description ?? '',
          Kyc_validation_personal_des:res?.data?.user?.kyc_validation?.personal.description ?? "",
          Kyc_validation_bank_des:res?.data?.user?.kyc_validation?.bank.description ?? "",
        }
        setFormData({ ...finalData })
      })
      .catch((error) => {
      })
  }
  const getUserData = () => {
    ApiGet(`kyc/get-user`)
      .then((res: any) => {
        setGstData({ ...gstData, gst_no: res?.data?.user?.gst_no ?? "" })
      })
      .catch((error) => {
      })
  }

  useEffect(() => {
    Promise.all([
      getData()
      // getUserData()
    ]).then(() => {
      setTimeout(() => { setLoader(false) }, 1000)
    }).catch((error) => {
      setLoader(false)
    })
  }, []);

  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  const handleKyc = async (key: string) => {
    let error = { ...formData };
    let errorGST = { ...gstData };  
    console.log(formData) 
    const bank_account_pattern = /^[0-9]{8,17}$/;
    const ifsc_pattern= /^[A-Z]{4}0[A-Z0-9]{6}$/;
    let gstVal = formData.gst_no;
        
      if (formData.pan_card_link == "Choose Pan Card"  ) {
        error = { ...error, pan_card_error: "Please Upload Document" };
      }
      if (formData.pan_card != null ) {
        error = { ...error, pan_card_error:null };
      }
      if (formData.cheque_image != null ) {
        error = { ...error, cheque_image_error:null };
      }
      if (formData.account_number == "") {
        error = { ...error, account_number_error: "Please Enter Bank Account Number" };
      }
      if(formData.account_number != "" && !bank_account_pattern.test(formData.account_number)){
        error = { ...error, account_number_error: "Please Enter valid Bank Account Number" };
      }
      if(formData.account_number != "" && bank_account_pattern.test(formData.account_number)){
        error = { ...error, account_number_error: null };
      }
      if (formData.ifsc == "") {
        error = { ...error, ifsc_error: "Please Enter IFSC code" };
      }
      if (formData.ifsc != "" && !ifsc_pattern.test(formData.ifsc)) {
        error = { ...error, ifsc_error: "Please Enter valid IFSC code" };
      }
      if (formData.ifsc != "" && ifsc_pattern.test(formData.ifsc)) {
        error = { ...error, ifsc_error: null };
      }
      if (formData.cheque_image_link == "Choose Cheque"  ) {
        error = { ...error, cheque_image_error: "Please Upload Document" };
      }
      console.log(gstVal != undefined)
      if (gstVal != undefined) {
        let statecode = gstVal.substring(0, 2);
        let patternstatecode = /^[0-9]{2}$/;
        let threetoseven = gstVal.substring(2, 7);
        let patternthreetoseven = /^[a-zA-Z]{5}$/;
        let seventoten = gstVal.substring(7, 11);
        let patternseventoten = /^[0-9]{4}$/;
        let Twelveth = gstVal.substring(11, 12);
        let patternTwelveth = /^[a-zA-Z]{1}$/;
        let Thirteen = gstVal.substring(12, 13);
        let patternThirteen = /^[1-9a-zA-Z]{1}$/;
        let fourteen = gstVal.substring(13, 14);
        let patternfourteen = /^[zZ]{1}$/;
        let fifteen = gstVal.substring(14, 15);
        let patternfifteen = /^[0-9a-zA-Z]{1}$/;
        if (gstVal.length != 15) {
            error = {
                ...error,
                gstNo_error: 'Length should be restricted to 15 digits and should not allow anything more or less'
            };
        } else if (!patternstatecode.test(statecode)) {
            error = {
                ...error, gstNo_error: 'First two characters of GST No should be numbers'
            };
        } else if (!patternthreetoseven.test(threetoseven)) {
            error = {
                ...error,
                gstNo_error: 'Third to seventh characters of GST No should be alphabets'
            };
        } else if (!patternseventoten.test(seventoten)) {
            error = {
                ...error,
                gstNo_error: 'Eighth to Eleventh characters of GST No should be numbers'
            };
        } else if (!patternTwelveth.test(Twelveth)) {
            error = {
                ...error,
                gstNo_error: 'Twelveth character of GST No should be alphabet'
            };
        } else if (!patternThirteen.test(Thirteen)) {
            error = {
                ...error,
                gstNo_error: 'Thirteen characters of GST No can be either alphabet or numeric'
            };
        } else if (!patternfourteen.test(fourteen)) {
            error = {
                ...error,
                gstNo_error: 'Fourteen characters of GST No should be Z'
            };
        } else if (!patternfifteen.test(fifteen)) {
            error = {
                ...error,
                gstNo_error: 'Fifteen characters of GST No can be either alphabet or numeric'
            };
        }
         else if (!gstVal.toUpperCase().includes(gstVal.toUpperCase())) {
            error = { ...error, gstNo_error: "Invalid GST No. Please check!" }
        }
        else (
            error = {
                ...error,
                gstNo_error: null
            })
    }
    else{
      error = {
        ...error,
        gstNo_error: null
    }
    }
    setFormData(error)
      // if (formData.gst_image_link == "Choose Gst") {
      //   error = { ...error, gst_image_error: "Please Upload Document" };
      // }
      // if (gstData.gst_no == "") {
      //   errorGST = { ...errorGST, gstNo_error: "Please Enter Gst No" };
      // }
      
      const form_data = new FormData();
      form_data.append('business_image', formData.business_image)
      form_data.append('profile_image', formData.profile_image)

      const form_data_pan = new FormData();      
      form_data_pan.append('pan_card_image', formData.pan_card)

      const form_data_cheque = new FormData();
      form_data_cheque.append('account_number', formData.account_number)
      form_data_cheque.append('ifsc', formData.ifsc)
      form_data_cheque.append('cheque_image', formData.cheque_image)

      const form_data_gst = new FormData();
      form_data_gst.append('gst_image', formData.gst_image)
      form_data_gst.append('gst_no', formData.gst_no)

      // -------
      
      // -----------
      console.log(error.pan_card_error)
      console.log(error.account_number_error)
      console.log(error.ifsc_error)
      console.log(error.cheque_image_error)
      console.log(error.gstNo_error)

      if (error.account_number_error == null && error.ifsc_error == null && error.gstNo_error == null && error.cheque_image_error == null && error.pan_card_error == null) {
        try{
            ApiPost("kyc/upload-detail", form_data_pan).then(async (response: any) => {
              if (response.data && response.success) {
                if(response){
                  ApiPost("kyc/upload-detail", form_data).then(async (response: any) => {
                    if (response.data && response.success) {
      
                    } 
                  }).catch((error: any) =>  
                    {
                      setResponse2(error.error)
                  //   toast.error(error.error, {
                  //   position: "top-right",
                  //   theme: "colored"
                  // })
                })
                  ApiPost("kyc/upload-detail", form_data_cheque).then(async (response: any) => {
                    if (response.data && response.success) {
      
                    } 
                  }).catch((error: any) =>  
                    {
                      setResponse3(error.error)
                  //   toast.error(error.error, {
                  //   position: "top-right",
                  //   theme: "colored"
                  // })
                })
                ApiPost("kyc/upload-detail", form_data_gst).then(async (response: any) => {
                  if (response.data && response.success) {
      
                  } 
                }).catch((error: any) =>  
                  {
                    setResponse4(error.error)
                //   toast.error(error.error, {
                //   position: "top-right",
                //   theme: "colored"
                // })
              })
                }
              } 
            }).catch((error: any) =>  
              {
                setResponse1(error.error)
            //   toast.error(error.error, {
            //   position: "top-right",
            //   theme: "colored"
            // })
          })         
          

          if( response1 == null && response2 == null && response3 == null && response4 == null ){
            fileUploadRef1.current.clear();
            fileUploadRef2.current.clear();
            fileUploadRef3.current.clear();
            businessImageRef.current.clear();
            profilePhotoRef.current.clear();
            getData()
            toast.success(" KYC upload Successfull.", {
              position: "top-right",
              theme: "colored"
            })
          }
        }
        catch{
          if( response1 != null  ){
            toast.error(response1, {
                position: "top-right",
                theme: "colored"
              })          
            }
            if(  response2 != null  ){
              toast.error(response2, {
                  position: "top-right",
                  theme: "colored"
                })          
              }if(  response3 != null  ){
                toast.error(response3, {
                    position: "top-right",
                    theme: "colored"
                  })          
                }if( response4 != null ){
                  toast.error(response4, {
                      position: "top-right",
                      theme: "colored"
                    })          
                  }
      }        
  }
}

  const getBasicDetails = async () => {
    await ApiGet(`userReffered/setting`)
        .then((res: any) => {
            setUserDetails(res?.data?.user);
        })
}

// useEffect(() => {
//   getBasicDetails()
// },[])

  const handleGSTData = async () => {
    let error = { ...formData };
    let errorGST = { ...gstData };   
    //PAN validation
    let panVal = UserDetails?.pan_no;

    //GST validation
    let mobilePatten = /^\d+$/;
    let emailPatten = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let gstVal = gstData.gst_no;
    let statecode = gstVal.substring(0, 2);
    let patternstatecode = /^[0-9]{2}$/
    let threetoseven = gstVal.substring(2, 7);
    let patternthreetoseven = /^[a-zA-Z]{5}$/
    let seventoten = gstVal.substring(7, 11);
    let patternseventoten = /^[0-9]{4}$/
    let Twelveth = gstVal.substring(11, 12);
    let patternTwelveth = /^[a-zA-Z]{1}$/
    let Thirteen = gstVal.substring(12, 13);
    let patternThirteen = /^[1-9a-zA-Z]{1}$/
    let fourteen = gstVal.substring(13, 14);
    let patternfourteen = /^[zZ]{1}$/
    let fifteen = gstVal.substring(14, 15);
    let patternfifteen = /^[0-9a-zA-Z]{1}$/
    if (gstVal != "") {
      if (gstVal.length != 15) {
          error = {
              ...error,
              gstNo_error: 'Length should be restricted to 15 digits and should not allow anything more or less'
          };
      } else if (!patternstatecode.test(statecode)) {
          error = {
              ...error, gstNo_error: 'First two characters of GST No should be numbers'
          };
      } else if (!patternthreetoseven.test(threetoseven)) {
          error = {
              ...error,
              gstNo_error: 'Third to seventh characters of GST No should be alphabets'
          };
      } else if (!patternseventoten.test(seventoten)) {
          error = {
              ...error,
              gstNo_error: 'Eighth to Eleventh characters of GST No should be numbers'
          };
      } else if (!patternTwelveth.test(Twelveth)) {
          error = {
              ...error,
              gstNo_error: 'Twelveth character of GST No should be alphabet'
          };
      } else if (!patternThirteen.test(Thirteen)) {
          error = {
              ...error,
              gstNo_error: 'Thirteen characters of GST No can be either alphabet or numeric'
          };
      } else if (!patternfourteen.test(fourteen)) {
          error = {
              ...error,
              gstNo_error: 'Fourteen characters of GST No should be Z'
          };
      } else if (!patternfifteen.test(fifteen)) {
          error = {
              ...error,
              gstNo_error: 'Fifteen characters of GST No can be either alphabet or numeric'
          };
      }
       else if (!gstVal.toUpperCase().includes(panVal.toUpperCase())) {
          error = { ...error, gstNo_error: "Invalid GST No. Please check!" }
      }
      else (
          error = {
              ...error,
              gstNo_error: null
          })
  }

    if (formData.gst_image_link == "Choose Gst") {
      error = { ...error, gst_image_error: "Please Upload Document" };
    }
    if (gstData.gst_no == "") {
      errorGST = { ...errorGST, gstNo_error: "Please Enter Gst No" };
    }
    setFormData(error)
    setGstData(errorGST)
    if (error.gst_image_error == null && error.gstNo_error == null) {
      await ApiPut(`kyc/update-user`, {
        gst_no: gstData.gst_no
      }).then(async (response: any) => {
        getUserData();
      }).catch((error: any) => console.log("Error", error))

      const form_data = new FormData();
      form_data.append('gst_image', formData.gst_image)
      await ApiPost("kyc/upload-gst", form_data).then(async (response: any) => {
        getData();
        if (response.data && response.success) {
          toast.success("upload Successfully.", {
            position: "top-right",
            theme: "colored"
          })
        } else {
          console.log("No Data found")
        }
      }).catch((error: any) => console.log("Error", error))
    }

  }
  const getStatus = (status:any) => {
    // if (status == "") {
    //   return null;
    // }
    if (status === false ) {
      return <small className="text-[#FF9800] text-xs">Under review</small>
    }
    // if (status === 'Verified') {
    //   return <small className="text-[#37B551] text-xs">Verified</small>
    // }
    // if (status === 'rejected') {
    //   return <small className="text-[#e74141] text-xs">Rejected</small>
    // }
  }

  const onSelect = (e: FileUploadSelectEvent, setTotalSize,setFiles,link) => {
    let totalSize = 0;
    let files = e.files;

    for (let i = 0; i < files.length; i++) {
        totalSize += files[i].size || 0;
    }

    setTotalSize(totalSize);
    setFiles(files)

    switch (link) {
      case 'pan':
        setFormData({...formData, pan_card: e.files[0], pan_card_link: e.files[0].name, pan_card_error: null })
          break;
      case 'gst':
        setFormData({...formData, gst_image: e.files[0], gst_image_link: e.files[0].name, gst_image_error: null })
          break;
      case 'bank':
        setFormData({...formData, cheque_image: e.files[0], cheque_image_link: e.files[0].name, cheque_image_error: null })
          break;
      
  }
   
};

const onUpload = (e: FileUploadUploadEvent, setTotalSize) => {
    let totalSize = 0;

    e.files.forEach((file) => {
        totalSize += file.size || 0;
    });
    setTotalSize(totalSize);
};

const onClear = (setTotalSize) => {
    setTotalSize(0);
};

const onRemove = (e, totalSize, setTotalSize) => {
  setTotalSize(totalSize - (e.file.size || 0));
};
const headerTemplate = (totalSize) => (options: FileUploadHeaderTemplateOptions) => {
    const { className, chooseButton } = options;
    const value = totalSize / 10000;
    const formattedValue = totalSize ? `${totalSize / 1000} KB` : '0 B';

    return (
        <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center', flex: '1' }}>
                {chooseButton}
            </div>
            <div className="progress-container">
                <span>{formattedValue} / 1 MB</span>
                <ProgressBar value={value} showValue={false} className="custom-progress-bar"></ProgressBar>
            </div>
        </div>
    );
};

const itemTemplate = (file, props) => {
    return (
        <div className="flex align-items-center flex-wrap">
            <div className="flex align-items-center" style={{ width: '40%' }}>
                <span>{file.name}</span>
            </div>
            <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => props.onRemove(file)} />
        </div>
    );
};

  return (<div className="overflow-x-hidden">
    {/* 
    <Sidebar />
    <Header /> */}
    {loader ?
      <>
        {/* START::OVERLAY */}
        <div className="fixed inset-0 w-full h-screen bg-white z-[9999] flex items-center justify-center">
          <img src="assets/logo.svg" />
        </div>
        {/* END::OVERLAY */}
      </>
      :
      <>
        {/* START::MAIN CONTENT */}
        {/* <main className="lg:pl-[280px] pt-[92px]"> */}
        <div className="p-4 sm:p-9 space-y-5">

          {/* START::DASHBOARD HEADER */}
          <div className="flex flex-wrap gap-5 items-center justify-between">
            <div className="md:flex w-full lg:w-[unset] items-center lg:justify-between md:space-x-9">
              <h1 className="text-[22px] text-light-gray font-semibold">KYC</h1>
            </div>
          </div>
          {/* END::DASHBOARD HEADER */}

          <div className="md:flex items-center md:space-x-10 space-y-10 md:space-y-0">
            <div className="flex items-center space-x-[26px] pb-5">
              <div className="w-[150px] h-[150px]">
                <label htmlFor="business_image" className="overflow-hidden w-full h-full border-2 flex items-center justify-center border-dashed border-black rounded-full text-[#C2C2C2]">
                  {formData.business_image_link == "" && <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v16m8-8H4" />
                  </svg>
                  }
                  {formData.business_image_link != "" && <img src={formData.business_image_link} alt={formData.business_image_link} className="w-full h-full object-center object-cover lg:w-full lg:h-full" />
                  }
                </label>
                  {/* <input type="file" id="business_image" className="hidden" onChange={(e: any) => {
                  setFormData({ ...formData, business_image: e.target.files[0], business_image_link: URL.createObjectURL(e.target.files[0]) })
                }} /> */}
              </div>
              <div>
              <h4 className="font-semibold text-light-gray text-lg">Add Business <br />Premises Photos</h4>
              <FileUpload 
                    mode="basic" 
                    // multiple
                    ref={businessImageRef}
                    name="demo[]" 
                    accept=".jpg,.jpeg,.png,.gif,.pdf"
                    maxFileSize={1000000}                    
                    onSelect={(e) => {
                      setFormData({ ...formData, business_image: e.files[0], business_image_link: URL.createObjectURL(e.files[0]) })
                      businessImageRef.current.clear()}
                  }
                    />
              </div>              
              
            </div>

            <div className="flex items-center space-x-[26px] pb-5">
              <div className="w-[150px] h-[150px]">
                <label htmlFor="profile_image" className="overflow-hidden w-full h-full border-2 flex items-center justify-center border-dashed border-black rounded-full text-[#C2C2C2]">
                  {formData.profile_image_link == "" && <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v16m8-8H4" />
                  </svg>
                  }
                  {formData.profile_image_link != "" && <img src={formData.profile_image_link} className="w-full h-full object-center object-cover lg:w-full lg:h-full" />
                  }
                </label>
                {/* <input type="file" id="profile_image" onChange={(e: any) => {
                  setFormData({ ...formData, profile_image: e.target.files[0], profile_image_link: URL.createObjectURL(e.target.files[0]) })
                }} className="hidden" /> */}
              </div>
              <div>
              <h4 className="font-semibold text-light-gray text-lg">Add Photo</h4>
              <FileUpload 
                    mode="basic" 
                    // id="profile_image"
                    // className="hidden"
                    ref={profilePhotoRef}
                    name="demo[]" 
                    accept=".jpg,.jpeg,.png,.gif,.pdf"
                    maxFileSize={1000000}
                    onSelect={(e) => {
                      setFormData({ ...formData, profile_image: e.files[0], profile_image_link: URL.createObjectURL(e.files[0]) })
                      profilePhotoRef.current.clear()}
                    }
                    />
              </div>
            </div>
          </div>

          {/* START::PAN CARD */}
          {/* <div className="space-y-1">
            <p className="input-label">Pan Card</p>
            <div className='md:flex md:items-start md:space-x-[20px] space-y-4 md:space-y-0'>
              <div className="w-full space-y-1">
                <div id="email" className={`input-wrap w-full ${formData.pan_card_error ? "border-red-500" : ""}`}>
                  <label htmlFor="pan_card" className={`block w-full text-sm text-[#808080] ${formData.pan_card_error ? "text-red-500" : ""}`}>{formData.pan_card_link}</label>
                  <img src="assets/check-green.svg" alt="" />
                </div>
                <p className="text-red-500 text-sm">{formData.pan_card_error}</p>
                <input type="file" id="pan_card" onChange={(e: any) => {

                  if (!formData.Kyc_validation){
                    setFormData({
                      ...formData,
                      pan_card: e.target.files[0],
                      pan_card_link: e.target.files?.[0]?.name,
                      pan_card_error: null,
                    });
                  }
                  else if(formData?.Kyc_validation.personal.status===0){

              
                  setFormData({
                    ...formData,
                    pan_card: e.target.files[0],
                    pan_card_link: e.target.files?.[0]?.name,
                    pan_card_error: null,
                  });
                }
                else{
              
                  toast.error("Pan already approved !!", {
                    position: "top-right",
                    theme: "colored"
                  })

                }
                

               
                }} className="text-sm hidden" />
                {getStatus(formData.profile_status)}
              </div>
              <button className={`px-16 py-[18px] text-semibold text-sm bg-secondary text-white rounded-lg border  ${(formData.profile_status === 'Verified' || formData.profile_status === 'Under Review') ? "bg-light-gray border-light-gray cursor-no-drop" : "border-secondary"}`} disabled={formData.profile_status === 'Verified' || formData.profile_status === 'Under Review'} onClick={() => handleKyc("main")}>Upload</button>
            </div>
          </div> */}
          {/* END::PAN CARD */}

          {/* START::AADHAR CARD */}
          {/* <div className="space-y-1">
        <p className="input-label">Aadhar Card</p>
        <div className='md:flex md:items-start md:space-x-[20px] space-y-4 md:space-y-0'>
          <div className="w-full space-y-1">
            <div id="email" className="input-wrap w-full">
              <label htmlFor="panCard" className="block w-full text-sm text-[#808080]">Aadhar card.pdf</label>
              <img src="assets/check-green.svg" alt="" />
            </div>
            <input type="file" id="panCard" className="text-sm hidden" />
            <small className="text-[#37B551] text-xs">Verified</small>
          </div>
          <button className='px-16 py-[18px] text-semibold text-sm bg-secondary text-white rounded-lg border border-secondary'>Upload</button>
        </div>
      </div> */}
          {/* END::AADHAR CARD */}

          {/* START::CHEQUE */}
          {/* <div className="space-y-1">
            <p className="input-label">Cheque</p>
            <div className='md:flex md:items-start md:space-x-[20px] space-y-4 md:space-y-0'>
              <div className='w-full md:flex md:space-x-5 md:space-y-0 space-y-5 !mb-4'>
                <div className={`w-full space-y-1`}>
                  <div id="email" className={`input-wrap w-full ${formData.account_number_error ? "border-red-500" : ""}`}>
                    <input type="text" className={`block w-full text-sm text-[#808080] ${formData.account_number_error ? "text-red-500" : ""}`} placeholder="Bank Account Number" value={formData.account_number} onChange={(e: any) => {
                      setFormData({ ...formData, account_number: e.target.value, account_number_error: null })
                    }} />
                    <img src="assets/eye-gray.svg" alt="" />
                  </div>
                  <p className="text-red-500 text-sm">{formData.account_number_error}</p>
                </div>
                <div className={`w-full space-y-1`}>
                  <div id="email" className={`input-wrap w-full ${formData.ifsc_error ? "border-red-500" : ""}`}>
                    <input type="text" value={formData.ifsc} onChange={(e: any) => {
                      setFormData({ ...formData, ifsc: e.target.value, ifsc_error: null })
                    }} className={`block w-full text-sm text-[#808080] ${formData.ifsc_error ? "text-red-500" : ""}`} placeholder="IFSC Code" />
                    <img src="assets/eye-gray.svg" alt="" />
                  </div>
                  <p className="text-red-500 text-sm">{formData.ifsc_error}</p>
                </div>
              </div>
              <button disabled className='hidden md:block opacity-0 px-16 py-[18px] text-semibold text-sm bg-secondary text-white rounded-lg border border-secondary'>Upload</button>
            </div>
            <div className='md:flex md:items-start md:space-x-[20px] space-y-4 md:space-y-0'>
              <div className="w-full space-y-1">
                <div id="email" className={`input-wrap w-full ${formData.cheque_image_error ? "border-red-500" : ""}`}>
                  <label htmlFor="cheque_image" className={`block w-full text-sm text-[#808080] ${formData.cheque_image_error ? "text-red-500" : ""}`}>{formData.cheque_image_link}</label>
                      <img src="assets/check-green.svg" alt="" />
                </div>
                <p className="text-red-500 text-sm">{formData.cheque_image_error}</p>
                <input type="file" id="cheque_image" onChange={(e: any) => {
                  console.log('-----------',formData)
                   if (!formData.Kyc_validation){
                    setFormData({ ...formData, cheque_image: e.target.files[0], cheque_image_link: e.target.files?.[0]?.name, cheque_image_error: null })

                  }
                  else if(formData.Kyc_validation.bank.status===0){
                  setFormData({ ...formData, cheque_image: e.target.files[0], cheque_image_link: e.target.files?.[0]?.name, cheque_image_error: null })
                  }
                  else{
              
                    toast.error("Bank details already approved !!", {
                      position: "top-right",
                      theme: "colored"
                    })
                  }
                }} className="text-sm hidden" />
                {getStatus(formData.Kyc_validation_bank)}
              </div>
              <button className={`px-16 py-[18px] text-semibold text-sm bg-secondary text-white rounded-lg border  ${(formData.cheque_status === 'Verified' || formData.cheque_status === 'Under Review') ? "bg-light-gray border-light-gray cursor-no-drop" : "border-secondary"}`} disabled={formData.cheque_status === 'Verified' || formData.cheque_status === 'Under Review'} onClick={() => handleKyc("bank")}>Upload</button>
            </div>
          </div> */}
          {/* END::CHEQUE */}

          {/* START::GST NO */}
          {/* <div className="space-y-1">
            <p className="input-label">GST</p>
            <div className='md:flex md:items-start md:space-x-[20px] space-y-4 md:space-y-0'>
              <div className="w-full space-y-1">
                <div id="gst_no" className={`input-wrap w-full ${gstData.gstNo_error ? "border-red-500" : ""}`}>
                  <input type="text" value={gstData.gst_no} onChange={(e: any) => {
                    setGstData({ ...gstData, gst_no: e.target.value, gstNo_error: null })
                  }} className={`block w-full text-sm text-[#808080] ${gstData.gstNo_error ? "text-red-500" : ""}`} placeholder="Enter GST Number" />
                  <img src="assets/eye-gray.svg" alt="" />
                </div>
                <p className="text-red-500 text-sm">{gstData.gstNo_error}</p>
              </div>
              <button className={`px-16 py-[18px] invisible text-semibold text-sm bg-secondary text-white rounded-lg border`} onClick={() => { }}>Upload</button>
            </div>
          </div> */}
          {/* END::GST */}

          {/* START::GST */}
          {/* <div className="space-y-1">
            <div className='md:flex md:items-start md:space-x-[20px] space-y-4 md:space-y-0'>
              <div className="w-full space-y-1">
                <div id="email" className={`input-wrap w-full ${formData.gst_image_error ? "border-red-500" : ""}`}>
                  <label htmlFor="gst_image" className={`block w-full text-sm text-[#808080] ${formData.gst_image_error ? "text-red-500" : ""}`}>{formData.gst_image_link}</label>
                  <img src="assets/check-green.svg" alt="" />
                </div>
                <p className="text-red-500 text-sm">{formData.gst_image_error}</p>
                <input type="file" id="gst_image" onChange={(e: any) => {
                  if(!formData.Kyc_validation){
                    setFormData({ ...formData, gst_image: e.target.files[0], gst_image_link: e.target.files?.[0]?.name, gst_image_error: null })

                  }
                  else if(formData.Kyc_validation.gst.status===0){
                   
                    setFormData({ ...formData, gst_image: e.target.files[0], gst_image_link: e.target.files?.[0]?.name, gst_image_error: null })

                  }
                  else{
              
                    toast.error("Gst already approved !!", {
                      position: "top-right",
                      theme: "colored"
                    })
                  }
                  setFormData({ ...formData, gst_image: e.target.files[0], gst_image_link: e.target.files?.[0]?.name, gst_image_error: null })
                }} className="text-sm hidden" />
                {getStatus(formData.gst_status)}
              </div>
              <button className={`px-16 py-[18px] text-semibold text-sm bg-secondary text-white rounded-lg border  ${(formData.gst_status === 'Verified' || formData.gst_status === 'Under Review') ? "bg-light-gray border-light-gray cursor-no-drop" : "border-secondary"}`} disabled={formData.gst_status === 'Verified' || formData.gst_status === 'Under Review'} onClick={() => handleGSTData()}>Upload</button>
            </div>
          </div> */}
          {/* END::GST */}
          <div className="vertical-stepper">
            {/* Stepper 1 */}
            <div className="step">
                <div className="step-header">
                    <div className="step-number">1</div>
                    <h3>Pan card </h3>
                </div>
                    {formData?.Kyc_validation_personal === 1
                    && 
                 ( <div className="card sucess-card mt-3 ">
                    <p className="p-4 mb-0"> <i className="pi pi-check-circle" style={{ color: 'green', fontSize: '1.5rem',display: 'inline-block', verticalAlign: 'middle'}}></i> Your KYC is Approved ! </p>
                  </div>)
                }
                {formData?.Kyc_validation_personal === 0 && formData?.Kyc_validation_personal_des !== "Rejected" && formData?.Kyc_validation_personal_des === ""
                    && 
                  (<div className="card sucess-card mt-3 ">
                    <p className="p-4 mb-0"><i className="pi pi-spin pi-spinner" style={{ color: 'blue', fontSize: '1.5rem',display: 'inline-block', verticalAlign: 'middle' }}></i> Your kyc is sent for Approvel...</p>
                  </div>)
                }
                {  formData?.Kyc_validation_personal_des !== "approved" && formData?.Kyc_validation_personal_des
                    && 
                  (<div className="card sucess-card mt-3 ">
                    <p className="p-4 mb-0"> <i className="pi pi-times" style={{color: 'red', fontSize: '1.5rem',display: 'inline-block', verticalAlign: 'middle'}}></i> Your kyc is Rejected due to {formData.Kyc_validation_personal_des}</p>
                  </div>)
                }
                <Panel className={` ${formData.Kyc_validation_personal == '1' ? 'hidden' : ''} `}>
                    {/* Content for Step I */}
                    <div  className={`${formData.pan_card_error ? ' border-2 border-red-500 ' : ''}`}>
                    <FileUpload 
                    // mode="basic" 
                    ref={fileUploadRef1}
                    name="demo[]" 
                    accept=".jpg,.jpeg,.png,.gif,.pdf"
                    maxFileSize={1000000}
                    onSelect={(e) => onSelect(e, setFileUpload1Size,setFileUpload1Files,'pan')}
                    onError={() => onClear(setFileUpload1Size)}
                    onClear={() => onClear(setFileUpload1Size)}
                    onRemove={(e) => onRemove(e, fileUpload1Size, setFileUpload1Size)}
                    headerTemplate={headerTemplate(fileUpload1Size)}
                    itemTemplate={itemTemplate}
                    emptyTemplate={<p className="m-0">Drag and drop files to here to upload.</p>} 
                    disabled={formData.Kyc_validation_personal === 1}
                    />
                    </div>
                    <p className="text-red-500 text-sm">{formData.pan_card_error}</p>
                </Panel>
            </div>

            {/* Stepper 2 */}
            <div className="step">
                <div className="step-header">
                    <div className="step-number">2</div>
                    <h3> Cheque</h3>
                </div>
                {formData?.Kyc_validation_bank === 1
                    && 
                  <div className="card sucess-card mt-3 ">
                    <p className="p-4 mb-0"> <i className="pi pi-check-circle" style={{ color: 'green', fontSize: '1.5rem',display: 'inline-block', verticalAlign: 'middle'}}></i>  Your KYC is Approved !  </p>
                    </div>
                }
                {formData?.Kyc_validation_bank === 0 && formData?.Kyc_validation_bank_des !== "Rejected" &&  formData?.Kyc_validation_bank_des === ""
                    && 
                  <div className="card sucess-card mt-3 ">
                    <p className="p-4 mb-0"><i className="pi pi-spin pi-spinner" style={{ color: 'blue', fontSize: '1.5rem',display: 'inline-block', verticalAlign: 'middle' }}></i> Your kyc is sent for Approvel... </p>
                    </div>
                }
                {  formData?.Kyc_validation_bank_des !== "approved" && formData?.Kyc_validation_bank_des
                    && 
                  (<div className="card sucess-card mt-3 ">{}
                    <p className="p-4 mb-0"> <i className="pi pi-times" style={{color: 'red', fontSize: '1.5rem',display: 'inline-block', verticalAlign: 'middle'}}></i> Your kyc is Rejected due to {formData.Kyc_validation_bank_des}</p>
                    </div>)
                }
                <Panel>
                    {/* Content for Step II */}
                    <div className={`p-field  `} >
                    <div className='w-full md:flex md:space-x-5 md:space-y-0 space-y-5 !mb-4'>
                <div className={`w-full space-y-1`}>
                <label htmlFor="bank" className='input-label'>Bank Account No.</label>
                <div id="email" className={`input-wrap w-full ${formData.account_number_error ? "border-red-500" : ""}`}>
                    <input type="text" className={`block w-full text-sm text-[#808080] ${formData.account_number_error ? "text-red-500" : ""}`} placeholder="Bank Account Number" value={formData.account_number} onChange={(e: any) => {
                      setFormData({ ...formData, account_number: e.target.value, account_number_error: null })
                    }} disabled={formData.Kyc_validation_bank === 1 ? true : false}/>
                  </div>
                  <p className="text-red-500 text-sm">{formData.account_number_error}</p>
                </div>
                <div className={`w-full space-y-1`}>
                <label htmlFor="bank" className='input-label'>IFSC code</label>
                  <div id="email" className={`input-wrap w-full ${formData.ifsc_error ? "border-red-500" : ""}`}>
                    <input type="text" value={formData.ifsc} onChange={(e: any) => {
                      setFormData({ ...formData, ifsc: e.target.value, ifsc_error: null })
                    }} className={`block w-full text-sm text-[#808080] ${formData.ifsc_error ? "text-red-500" : ""}`} placeholder="IFSC Code" 
                    disabled={formData.Kyc_validation_bank === 1 ? true : false}/>
                  </div>
                  <p className="text-red-500 text-sm">{formData.ifsc_error}</p>
                </div>
              </div>                    </div>
              <div  className={`${formData.cheque_image_error ? 'border-red-500 border-2' : ''} ${formData.Kyc_validation_bank == '1' ? 'hidden' : ''}`}>
              <FileUpload 
                    // mode="basic"
                    ref={fileUploadRef2}
                    name="demo[]" 
                    url="/api/upload"     
                    accept=".jpg,.jpeg,.png,.gif,.pdf"
                    maxFileSize={1000000} 
                   // onUpload={(e) => onUpload(e, setFileUpload2Size)}
                    onSelect={(e) => formData.kyc_validation_pan ? onSelect(e, setFileUpload2Size,setFileUpload2Files,'bank') : onSelect(e, setFileUpload2Size,setFileUpload2Files,'bank')}
                    onError={() => onClear(setFileUpload2Size)}
                    onClear={() => onClear(setFileUpload2Size)}
                    onRemove={(e) => onRemove(e, fileUpload2Size, setFileUpload2Size)}
                    headerTemplate={headerTemplate(fileUpload2Size)}
                    itemTemplate={itemTemplate}
                    emptyTemplate={<p className="m-0">Drag and drop files to here to upload.</p>}   
                    disabled={formData.Kyc_validation_bank === 1}
                    />
                    </div>
                    <p className="text-red-500 text-sm"> {formData.cheque_image_error}</p>
                </Panel>
            </div>

            {/* Stepper 3 */}
            <div className="step">
                <div className="step-header">
                    <div className="step-number">3</div>
                    <h3> GST</h3>
                </div>
                {formData?.Kyc_validation_gst === 1
                    && 
                  <div className="card sucess-card mt-3 ">
                    <p className="p-4 mb-0"> <i className="pi pi-check-circle" style={{ color: 'green', fontSize: '1.5rem',display: 'inline-block', verticalAlign: 'middle'}}></i>  Your KYC is Approved !  </p>
                    </div>
                }
                {formData?.Kyc_validation_gst === 0 && formData?.Kyc_validation_gst_des !== "Rejected"  && formData?.Kyc_validation_gst_des === ""
                    && 
                  <div className="card sucess-card mt-3 ">
                    <p className="p-4 mb-0"><i className="pi pi-spin pi-spinner" style={{ color: 'blue', fontSize: '1.5rem',display: 'inline-block', verticalAlign: 'middle' }}></i> Your kyc is sent for Approvel...</p>
                    </div>
                }
                {formData?.Kyc_validation_gst_des !== "approved" && formData?.Kyc_validation_gst_des
                    && 
                  (<div className="card sucess-card mt-3 ">
                    <p className="p-4 mb-0"> <i className="pi pi-times" style={{color: 'red', fontSize: '1.5rem',display: 'inline-block', verticalAlign: 'middle'}}></i> Your kyc is Rejected due to {formData.Kyc_validation_gst_des}</p>
                    </div>)
                }
                <Panel>
                    {/* Content for Step III */}
                    <div className={`p-field `}>
                    <div className='md:flex md:items-start md:space-x-[20px] space-y-4 md:space-y-0'>
                      <div className="w-full space-y-1">
                      <label htmlFor="gst" className='input-label'> GST No.</label>
                        <div id="gst_no" className={`input-wrap w-full ${formData.gstNo_error ? "border-red-500" : ""}`}>
                        <input type="text" value={formData.gst_no} onChange={(e: any) => {
                         setFormData({ ...formData, gst_no: e.target.value, gstNo_error: null })
                         }} className={`block w-full text-sm text-[#808080] ${formData.gstNo_error ? "text-red-500" : ""}`} placeholder="Enter GST Number" 
                         disabled={formData.Kyc_validation_gst === 1 ? true : false}/>
                         </div>
                         <p className="text-red-500 text-sm">{formData.gstNo_error}</p>
                        </div>
                      </div>
                    </div>
                    <div  className={`${formData.gst_image_error ? 'border-red-500 border-2' : ''} ${formData.Kyc_validation_gst == '1' ? 'hidden' : ''} `}>
                    <FileUpload 
                    // mode="basic" 
                    ref={fileUploadRef3}
                    name="demo[]" 
                    url="/api/upload"     
                    accept=".jpg,.jpeg,.png,.gif,.pdf"
                    maxFileSize={1000000} 
                  //  onUpload={(e) => onUpload(e, setFileUpload3Size)}
                    onSelect={(e) => onSelect(e, setFileUpload3Size,setFileUpload3Files,'gst')}
                    onError={() => onClear(setFileUpload3Size)}
                    onClear={() => onClear(setFileUpload3Size)}
                    onRemove={(e) => onRemove(e, fileUpload3Size, setFileUpload3Size)}
                    headerTemplate={headerTemplate(fileUpload3Size)}
                    itemTemplate={itemTemplate}
                    emptyTemplate={<p className="m-0">Drag and drop files to here to upload.</p>}   
                    disabled={formData.Kyc_validation_gst === 1}
                    />                  
                    </div>
                    <p className="text-red-500 text-sm"> {formData.gst_image_error}</p>
                </Panel>
            </div>
        </div>
          <Button
  className={`ml-10 `} onClick={() => {
    if (formData.final_kyc_status) {
      alert("Profile-already-completed");
    }
    else{handleKyc('main');}
    
    
  }}
>
  Upload
</Button>
              {/* <button className={`px-16 py-[18px] text-semibold text-sm bg-secondary text-white rounded-lg border  ${(formData.profile_status === 'Verified' || formData.profile_status === 'Under Review') ? "bg-light-gray border-light-gray cursor-no-drop" : "border-secondary"}`} disabled={formData.profile_status === 'Verified' || formData.profile_status === 'Under Review'} onClick={() => handleKyc("main")}>Upload</button> */}


        </div>
       

        {/* </main> */}
        {/* END::MAIN CONTENT */}
      </>
    }
  </div >)
}

export default Kyc
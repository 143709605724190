import { Box, Button, ClickAwayListener, Modal, TableCell, TableRow, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import TableComponent from '../../components/Tables';
import { STATE } from '../../constants';
import { ApiDelete, ApiGet, ApiPost, ApiPut } from '../../helper/API/ApiData';
import { PERMISSION_TYPE, userPermission } from '../../helper/permission';
import { isEmpty } from '../../helper/util';
import { RootState } from '../../redux/store';
import { getSlabValue } from './../../helper/util';
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin5Line } from "react-icons/ri";
import { toast } from 'react-toastify';
import { useParams } from 'react-router';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

function UserPayout() {
  

  let { id } = useParams();
  // let { useriid } = useParams();
  const flatData = {
    normal_payout_grid: "",
    advance_payout_grid: "",
    disbursal_amount: "",
    loginCodeId: ""
  }

  const [flatSlotData, setFlatSlotData] = React.useState(flatData)
  const [loader, setLoader] = useState(true);
  const [normalSlot, setNormalSlot] = React.useState({
    normal: "",
    logincodelist_id: "",
    capping:"",
    user_id:"",
    advance:""
  })
  const [userrid, setUserrid] = React.useState(id)

  const [termsCondition, setTermsCondition] = React.useState([])
  const [termsbaseCondition, setbaseTermsCondition] = React.useState([])
  const [addTermsConditionMulti, setAddTermsConditionMulti] = React.useState("")
  const [termsConditionType, setTermsConditionType] = React.useState("")
  const [editOpen, setEditOpen] = React.useState(false);
  const [addNewRow, setAddNewRow] = React.useState(false);
  const [actionOpen, setActionOpen] = React.useState<boolean[]>([]);
  const [termsOpen, setTermsOpen] = React.useState<boolean[]>([]);
  const [termsHoverOpen, setTermsHoverOpen] = React.useState<boolean[]>([]);
  const [termsAdvanceOpen, setTermsAdvanceOpen] = React.useState<boolean[]>([]);
  const [termsAdvanceHoverOpen, setTermsAdvanceHoverOpen] = React.useState<boolean[]>([]);
  const [tcMopen, setTcMOpen] = React.useState(false);
  const [state, setState] = useState(STATE.DEFAULT_STATE);
  const [payoutGridDetail, setPayoutGridDetail] = useState([]);
  const [connectorPayoutGridDetail, setConnectorPayoutGridDetail] = useState([]);
  const [singlePayoutGridDetail, setSinglePayoutGridDetail] = useState([]);
  const [permission, setPermission] = useState<any>({});
  const [selected, setSelected] = useState<any>("");
  const [editId, setEditId] = useState<any>("");
  const user = useSelector((state: RootState) => state?.authUser?.userData?.user)
  const [historyModel, setHistoryModel] = React.useState(false);
  const [historyData, setHistoryData] = React.useState([]);
  

  let columns = [
    { id: 'SRNO', label: 'SR.NO.' },
    { id: 'bank', label: 'Bank/NBFC Name' },
    { id: 'product', label: 'Product' },
    { id: 'Normal', label: 'Normal Payout Slab' },
    { id: 'Advance', label: 'Advance Payout Slab' },
  ];

  if (permission[PERMISSION_TYPE.ADD_PAYOUT_GRID]) {
    columns = [...columns, { id: 'Action', label: 'Action' }]
  }


  const handlePageData = (limit: number, page: number = 1) => {
    setState({
      ...state,
      limit,
      page
    })
  }

  const handlePage = (page: number) => {
    setState({
      ...state,
      page
    })
  }

  const getTeamsAndCondition = (logincodelistToFind: any) => {
    let temp: any = [];
    let data: any = [];
    // temp = [...temp, ...termsCondition];
    // temp = [...temp, ...data];
    // return temp.filter((value: any) => value != "")
    console.log('====================', termsbaseCondition)
    temp = [...temp, ...termsbaseCondition];
    const foundItem = temp.find((item: any) => item.logincodelist === logincodelistToFind);
    const baseItem = temp.find((item: any) => item.logincodelist === "Base");
    if (foundItem) {
      // If logincodelist is found, return the list of term_and_condition
      data = [...foundItem.jsonb_agg.map((agg: any) => agg.term_and_condition), ...baseItem.jsonb_agg.map((agg: any) => agg.term_and_condition)]
      // return foundItem.jsonb_agg.map((agg:any) => agg.term_and_condition);
      return data
    } else {
      // If logincodelist is not found, return "Base" terms and conditions
      // const baseItem = temp.find((item:any) => item.logincodelist === "Base");
      if (baseItem) {
        return baseItem.jsonb_agg.map((agg: any) => agg.term_and_condition);
      } else {
        return ["Base"];
      }
    }
  }

  const getPayoutGrid = async () => {
    await ApiGet(`loginCodeList/option-codelist`)
      .then((res: any) => {
        let temp = res.data.result.docs.map((item: any, index: number) => {
          return {
            id: item._id,
            SRNO: (((state.page - 1) * state.limit) + index + 1),
            bank: item.bank_name,
            product: item.product,
            Normal: getSlabValue(item, 'normal'),
            Advance: getSlabValue(item, 'advance'),
            termcondition: item.term_and_condition ?? [],
            selectedSlab: item.select ?? "",
            normal_payout_slab: item?.normal_payout_slab,
            advance_payout_slab: item?.advance_payout_slab,
            payout_grid: item?.payout_grid,
          }
        })
        setPayoutGridDetail(temp)

        const tempPermission = {
          [PERMISSION_TYPE.ADD_PAYOUT_GRID]: userPermission(user, PERMISSION_TYPE.ADD_PAYOUT_GRID),
          [PERMISSION_TYPE.CONNECTOR]: userPermission(user, PERMISSION_TYPE.CONNECTOR),
        }
        setPermission(tempPermission)

        setActionOpen(temp.map(() => { return false }))
        setTermsOpen(temp.map(() => { return false }))
        setTermsAdvanceOpen(temp.map(() => { return false }))
        setTermsAdvanceHoverOpen(temp.map(() => { return false }))
        setState({
          ...state,
          totalItems: res.data.result.total
        })
      }).catch((error) => {
        const tempPermission = {
          [PERMISSION_TYPE.ADD_PAYOUT_GRID]: userPermission(user, PERMISSION_TYPE.ADD_PAYOUT_GRID),
          [PERMISSION_TYPE.CONNECTOR]: userPermission(user, PERMISSION_TYPE.CONNECTOR),
        }
        setPermission(tempPermission)
      });
  }

  const getConnectorPayoutGrid = async () => {
    await ApiGet(`payoutgrid/${id}?search=none`)
      .then((res: any) => {
        let temp = res?.data?.result.map((item: any, index: number) => {
          return {
            id: item._id,
            SRNO: (((state.page - 1) * state.limit) + index + 1),
            bank: item?.bank_name,
            product: item?.product,
            Normal: item?.normal_payout,
            Advance: item?.advance_payout,
            LoginCode: item?.logincodelistid,
            Userid:item?.payoutgrid_userid,
            capping:item?.capping_payout
            
            // termcondition: item.term_and_condition ?? [],
            // selectedSlab: item.select ?? "",
            // normal_payout_slab: item?.normal_payout_slab,
            // advance_payout_slab: item?.advance_payout_slab,
            // payout_grid: item?.payout_grid,
          }
        })
        setConnectorPayoutGridDetail(temp)

        const tempPermission = {
          [PERMISSION_TYPE.ADD_PAYOUT_GRID]: userPermission(user, PERMISSION_TYPE.ADD_PAYOUT_GRID),
          [PERMISSION_TYPE.CONNECTOR]: userPermission(user, PERMISSION_TYPE.CONNECTOR),
        }
        setPermission(tempPermission)

        setActionOpen(temp.map(() => { return false }))
        setTermsOpen(temp.map(() => { return false }))
        setTermsAdvanceOpen(temp.map(() => { return false }))
        setTermsAdvanceHoverOpen(temp.map(() => { return false }))
        setState({
          ...state,
          totalItems: 10
        })
      }).catch((error) => {
        const tempPermission = {
          [PERMISSION_TYPE.ADD_PAYOUT_GRID]: userPermission(user, PERMISSION_TYPE.ADD_PAYOUT_GRID),
          [PERMISSION_TYPE.CONNECTOR]: userPermission(user, PERMISSION_TYPE.CONNECTOR),
        }
        setPermission(tempPermission)
      });
  }

  const getMainT_C = async () => {
    await ApiGet(`payoutGrid/main-term-condtion/all`)
      .then((res: any) => {
        setTermsCondition(res?.data?.termAndCondtion?.length == 0 ? [""] : res?.data?.termAndCondtion)
      })
  }
  const getALLT_C = async () => {
    await ApiGet(`payoutGrid/base/terms`)
      .then((res: any) => {
        setbaseTermsCondition(res?.data.result)
      })
  }
  useEffect(() => {
    Promise.all([
      getPayoutGrid(),
      getConnectorPayoutGrid(),
      getMainT_C(),
      getALLT_C()
    ]).then(() => {
      setTimeout(() => { setLoader(false) }, 250)
    }).catch((error) => {
      setLoader(false)
    })
  }, [state.page, state.limit])

  const getPayoutGridByLogicCode = async (code: string) => {
    await ApiGet(`payoutGrid?codeListId=${code}`)
      .then((res: any) => {
        let temp = res.data.result.map((item: any, index: number) => {
          return {
            Amount: item.disbursal_amount,
            Normal: item.normal_payout_grid,
            Advance: item.advance_payout_grid,
          }
        })
        setSinglePayoutGridDetail(temp)
      })
  }

  // useEffect(() => {
  //   if (flatSlotData.loginCodeId != "") getPayoutGridByLogicCode(flatSlotData.loginCodeId)
  // }, [flatSlotData.loginCodeId])

  // popup handle
  const handleChange = (e: any) => {
    setFlatSlotData({ ...flatSlotData, [e.target.name]: e.target.value });
  };

  const addnormal = (id: any) => {
    
      ApiPut(`payoutgrid/update/`, { ...normalSlot, user_id: userrid })
        .then((res: any) => {
          setEditOpen(false);
        })
    
  }


  const Addterms = (type: string) => {
    if (type === 'main') {
      ApiPost(`payoutGrid/main-term-condtion${flatSlotData.loginCodeId}`, {
        term_and_condition: addTermsConditionMulti
      })
        .then((res: any) => {
          getPayoutGrid();
          setAddTermsConditionMulti("");
          getMainT_C();
        })
    } else {
      const body = {
        codeListId: flatSlotData.loginCodeId,
        term_and_condition: addTermsConditionMulti
      }
      ApiPost("logincodelist/term-condition", body)
        .then((res: any) => {
          setTcMOpen(false);
          getPayoutGrid();
        })
    }
  }

  const handleEdit = (id: any) => {
    const term: any = termsCondition.find((t: any) => t._id == id);
    if (term && term.term_and_condition) {
      setAddTermsConditionMulti(term.term_and_condition);
      setEditId(id);
    }
  }

  const onEdit = (id: any) => {
    ApiPut(`payoutGrid/main-term-condtion/${id}`, {
      term_and_condition: addTermsConditionMulti
    })
      .then((res: any) => {
        getPayoutGrid();
        setEditId("");
        setAddTermsConditionMulti("");
        getMainT_C();
      })
  }

  const handleDelete = (id: any) => {
    ApiDelete(`payoutGrid/main-term-condtion/${id}`)
      .then((res: any) => {
        getPayoutGrid();
        getMainT_C();
      })
  }

  const handleSubmit = () => {
    if (addTermsConditionMulti) {
      if (editId) {
        onEdit(editId);
      }
      else {
        Addterms("main");
      }
    }
    else {
      toast.error("Please enter term and condition to proceed!", {
        position: "top-right",
        theme: "colored"
      })
    }
  }

  const handleOpenHistoryModel = (id: any,login:any) => {
    setHistoryModel(true);
    try {
      ApiGet(`payoutGrid/userviewhistory/${id}/${login}`)
        .then((res: any) => {
          if (res?.data?.data) {
            setHistoryData(res.data.data);
          }
        })
    } catch (ex) {
      // toast.error("something went wrong", {
      //   position: "top-right",
      //   theme: "colored"
      // })
    }
    
  }

  return (<div className="overflow-x-hidden">

    {/* <Sidebar />
    <Header /> */}
    {loader ?
      <>
        {/* START::OVERLAY */}
        <div className="fixed inset-0 w-full h-screen bg-white z-[9999] flex items-center justify-center">
          <img src="../assets/logo.svg" />
        </div>
        {/* END::OVERLAY */}
      </>
      :
      <>
        {/* START::MAIN CONTENT */}
        {/* <main className="lg:pl-[280px] pt-[92px]"> */}
        <div className="p-4 sm:p-9 space-y-5  min-h-[calc(100vh-70px)]">

          {/* START::DASHBOARD HEADER */}
          <div className="flex flex-wrap gap-5 items-center justify-between">
            <div className="md:flex w-full lg:w-[unset] items-center lg:justify-between md:space-x-9">
              <h1 className="text-[22px] text-light-gray font-semibold">User Payout</h1>
            </div>
            {/* END::EXPORT */}
          </div>


          {/* MODEL-----Start---------------- */}

          {/* Start::Terms and Condition Modal */}
          <div className={`tw-modal ${tcMopen ? 'flex' : 'hidden'}`}>
            <div className="tw-modal-content">
              <div className="tw-modal-title">
                <div>Add Terms and Condition</div>
                <button onClick={() => setTcMOpen(false)}>
                  <img src="../assets/close-outline.svg" alt="" />
                </button>
              </div>

              <div className='tw-modal-body py-6'>

                <div className='d-flex'>
                  <ol className='list-decimal ml-5 text-xs text-[#808080] leading-[26px] space-y-4'>
                    {
                      termsCondition.map((term: any, index) => {
                        return (
                          <li className='flex items-center justify-between gap-4'>{index}. {(term as any)?.term_and_condition}
                            <div className='flex'>
                              <FiEdit className='mr-3 cursor-pointer' onClick={() => handleEdit(term._id)} />
                              <RiDeleteBin5Line className='cursor-pointer' onClick={() => handleDelete(term._id)} />
                            </div>
                          </li>)
                      })
                    }
                  </ol>
                </div>

                <ol className='list-decimal ml-4 text-xs text-[#808080] leading-[26px] space-y-4'>

                  <li className='flex items-center gap-4'>
                    <input
                      className='w-full py-4 px-[22px] border text-sm rounded-lg border-[#DEDEDE]'
                      type="text"
                      name="termscondition"
                      placeholder='Add Terms and Condition'
                      value={addTermsConditionMulti}
                      onChange={(e: any) => {
                        let temp = e.target.value;
                        setAddTermsConditionMulti(temp)
                      }}
                    />
                    {/* <button className="flex items-center space-x-1 text-sm text-red-500 ml-auto my-1" onClick={() => {
                        let temp = [...addTermsConditionMulti];
                        let filterData = temp.filter((x, i) => i != index)
                        setAddTermsConditionMulti(filterData)
                      }}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                          <path strokeLinecap="round" strokeLinejoin="round" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </button> */}
                  </li>

                </ol>
              </div>

              {/* <div className="py-3 px-6">
                <button className="ml-auto flex items-center space-x-1 text-sm"
                  onClick={() => Addterms('main')}
                >
                  <img src="../assets/plus-green.svg" alt="" />
                  <span>Add New Row</span>
                </button>
              </div> */}

              <div className='tw-modal-footer'>
                <button className='tw-modal-footer-button bg-secondary text-white' onClick={() => handleSubmit()}>
                  {editId ? "Edit" : "Add"}
                </button>
                <button className='tw-modal-footer-button bg bg-secondary bg-opacity-10 text-secondary' onClick={() => setTcMOpen(false)}>Cancel</button>
              </div>
            </div>
          </div>
          {/* End::Terms and Condition Modal */}

          {/* Start::Edit Payout Grid Modal */}
          <div className={`tw-modal ${editOpen ? 'flex' : 'hidden'}`}>
            <div className="tw-modal-content">
              <div className="tw-modal-title">
                <div>Edit Payout Grid</div>
                <button onClick={() => setEditOpen(false)}>
                  <img src="../assets/close-outline.svg" alt="" />
                </button>
              </div>

              <div className='tw-modal-body py-5'>
                <div className='col-span-2 space-y-6'>
                  
                  
                    <div className='space-y-2'>
                      <div className='w-full flex gap-5'>
                        <div className='w-full space-y-2'>
                          <label htmlFor="normal_payout_slab" className="input-label">Normal Payout</label>
                          <input
                            className='w-full py-4 px-[22px] border text-sm rounded-lg border-[#DEDEDE]'
                            type="text"
                            name="normal_payout_slab"
                            placeholder={normalSlot.normal}
                            value={normalSlot.normal}
                            onChange={(e: any) => setNormalSlot({ ...normalSlot, normal: e.target.value })}
                          />
                        </div>
                        <div className='w-full space-y-2'>
                          <label htmlFor="advance_payout_slab" className="input-label">Capping</label>
                          <input
                            className='w-full py-4 px-[22px] border text-sm rounded-lg border-[#DEDEDE]'
                            type="text"
                            name="advance_payout_slab"
                            placeholder='2.45'
                            value={normalSlot.capping}
                            onChange={(e: any) => setNormalSlot({ ...normalSlot, capping: e.target.value })}
                          />
                        </div>
                      </div>
                    </div>
                

                </div>
                

                
              </div>

              <div className='tw-modal-footer'>
                <button className='tw-modal-footer-button bg-secondary text-white' onClick={addnormal}>Save</button>
                <button className="tw-modal-footer-button bg-secondary bg-opacity-10 text-secondary" onClick={() => setEditOpen(false)}>Cancel</button>
              </div>
            </div>
          </div>
          {/* End::Edit Payout Grid Modal */}

          {/* Start::View history */}
          <div className={`tw-modal ${historyModel ? 'flex' : 'hidden'}`}>
            <div className="tw-modal-content">
              <div className="tw-modal-title">
                <div>History</div>
                <button onClick={() => setHistoryModel(false)}>
                  <img src="../assets/close-outline.svg" alt="" />
                </button>
              </div>

              <div className='tw-modal-body py-5'>
              <table className='w-full'>
                    <thead className='text-sm text-light-gray bg-gray-200'>
                      <tr>
                        <th className='py-3 px-5'>Normal Payout</th>
                        <th className='py-3 px-5'>Advance Payout</th>
                        <th className='py-3 px-5'>Capping Payout</th>
                        <th className='py-3 px-5'>Start Date</th>
                        <th className='py-3 px-5'>End Date</th>
                      </tr>
                    </thead>
                    <tbody className='bg-gray-100 text-sm text-light-gray'>
                      {historyData?.map((details: any) => (
                        <tr className='border-b'>
                          <td className='py-3 px-5 text-center'>{details.normal_payout}</td>
                          <td className='py-3 px-5 text-center'>{details.advance_payout}</td>
                          <td className='py-3 px-5 text-center'>{details.capping_payout}</td>
                          <td className='py-3 px-5 text-center'>{details.start_date}</td>
                          <td className='py-3 px-5 text-center'>{details.end_date}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
              </div>

            </div>
          </div>
          {/* End::View history */}

          {/* MODEL-----End---------------- */}


          {/* END::DASHBOARD HEADER */}
          <TableComponent
            rows={connectorPayoutGridDetail}
            columns={columns}
            handlePageData={handlePageData}
            handlePage={handlePage}
            state={state}
            renderColumn={(column: any) => {
              return (
                <TableCell
                  align="center"
                  className="!bg-gray-200"
                  key={column.id}
                >
                  {column.label}
                </TableCell>
              )
            }}
            renderRow={(row: any, index: number) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  <TableCell align="center" component="th" scope="row">
                    {row.SRNO}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {row.bank}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {row.product}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    <span className="relative">
                      <span className='flex items-center justify-center space-x-2'><span>{parseFloat(row.Normal).toFixed(2)}</span> <img src="../assets/green_alert.svg" alt=""
                        onClick={() => {
                          let tempClose: boolean[] = [...termsOpen];
                          tempClose = tempClose.map(() => { return false })
                          setTermsOpen(tempClose)
                          let temp: boolean[] = [...termsHoverOpen];
                          if (!temp[index]) {
                            temp = temp.map(() => { return false })
                          }
                          temp[index] = !temp[index]
                          setTermsHoverOpen(temp)
                        }}
                        onMouseEnter={() => {
                          let temp: boolean[] = [...termsOpen];
                          if (!temp[index]) {
                            temp = temp.map(() => { return false })
                          }
                          temp[index] = true
                          if (termsHoverOpen.every((open) => open == false)) {
                            setTermsOpen(temp)
                          }
                        }} onMouseLeave={() => {
                          let temp: boolean[] = [...termsOpen];
                          if (!temp[index]) {
                            temp = temp.map(() => { return false })
                          }
                          temp[index] = false
                          if (termsHoverOpen.every((open) => open == false)) {
                            setTermsOpen(temp)
                          }
                        }} /></span>
                      {(termsOpen[index] || termsHoverOpen[index]) && (
                        <ClickAwayListener onClickAway={() => {
                          let temp: boolean[] = [...termsHoverOpen];
                          temp = temp.map(() => { return false })
                          // setTermsAdvanceHoverOpen(temp)
                        }}><div className="absolute top-[calc(100%+10px)] w-[300px] right-full shadow-lg z-[777] text-[#808080]">
                            <div className='flex justify-between bg-primary py-4 px-5'>
                              <p className="text-white text-sm">Terms</p>
                            </div>
                            <div className="py-4 px-5 bg-white">
                              <ol className="list-decimal ml-5 text-left space-y-4 text-xs">
                                {getTeamsAndCondition(row.LoginCode).length == 0 ? <li>No Term & Condition</li> : getTeamsAndCondition(row.LoginCode).map((item: any) => (
                                  <li>{item}</li>
                                ))}
                              </ol>
                              {/* {(!isEmpty(row?.payout_grid) && row?.selectedSlab == "variable_slab") && (
                                <>
                                  <h6 className="font-semibold py-4 text-light-gray text-left">Variable Slab</h6>
                                  <table className='w-full text-xs'>
                                    <thead className='text-light-gray bg-gray-200'>
                                      <tr>
                                        <th className='py-3 px-5 max-w-[100px]'>Amount</th>
                                        <th className='py-3 px-5 max-w-[100px]'>Normal</th>
                                        <th className='py-3 px-5 max-w-[100px]'>Advance</th>
                                      </tr>
                                    </thead>
                                    <tbody className='bg-gray-100 text-light-gray'>
                                      {row?.payout_grid.map((data: any) => (
                                        <tr className='border-b'>
                                          <td className='py-3 px-5 max-w-[100px] text-center'>
                                            {data?.disbursal_amount}
                                          </td>
                                          <td className='py-3 px-5 max-w-[100px] text-center'>
                                            {data?.normal_payout_grid}
                                          </td>
                                          <td className='py-3 px-5 max-w-[100px] text-center'>
                                            {data?.advance_payout_grid}
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </>
                              )} */}
                            </div>
                          </div>
                        </ClickAwayListener>)
                      }
                    </span>
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    <span className="relative">
                      <span className='flex items-center justify-center space-x-2'><span>{parseFloat(row.Advance).toFixed(2)}</span> <img src="../assets/plus-green.svg" className="w-3" alt=""
                        onClick={() => {
                          let tempClose: boolean[] = [...termsAdvanceOpen];
                          tempClose = tempClose.map(() => { return false })
                          setTermsAdvanceOpen(tempClose)
                          let temp: boolean[] = [...termsAdvanceHoverOpen];
                          if (!temp[index]) {
                            temp = temp.map(() => { return false })
                          }
                          temp[index] = !temp[index]
                          setTermsAdvanceHoverOpen(temp)
                        }}
                        onMouseEnter={() => {
                          let temp: boolean[] = [...termsAdvanceOpen];
                          if (!temp[index]) {
                            temp = temp.map(() => { return false })
                          }
                          temp[index] = true
                          if (termsAdvanceHoverOpen.every((open) => open == false)) {
                            setTermsAdvanceOpen(temp)
                          }
                        }} onMouseLeave={() => {
                          let temp: boolean[] = [...termsAdvanceOpen];
                          if (!temp[index]) {
                            temp = temp.map(() => { return false })
                          }
                          temp[index] = false
                          if (termsAdvanceHoverOpen.every((open) => open == false)) {
                            setTermsAdvanceOpen(temp)
                          }
                        }} /></span>

                      {(termsAdvanceOpen[index] || termsAdvanceHoverOpen[index]) && (
                        <ClickAwayListener onClickAway={() => {
                          let temp: boolean[] = [...termsAdvanceHoverOpen];
                          temp = temp.map(() => { return false })
                          // setTermsAdvanceHoverOpen(temp)
                        }}>
                          <div className="absolute top-[calc(100%+10px)] w-[300px] right-full shadow-lg z-[777] text-[#808080]">
                            <div className='flex justify-between bg-primary py-4 px-5'>
                              <p className="text-white text-sm">Terms</p>
                            </div>
                            <div className="py-4 px-5 bg-white">
                              <ol className="list-decimal ml-5 text-left space-y-4 text-xs">
                                {getTeamsAndCondition(row.LoginCode).map((item: any) => (
                                  <li>{item}</li>
                                ))}
                              </ol>
                              {(!isEmpty(row?.payout_grid) && row?.selectedSlab == "variable_slab") && (
                                <>
                                  <h6 className="font-semibold py-4 text-light-gray text-left">Variable Slab</h6>
                                  <table className='w-full text-xs'>
                                    <thead className='text-light-gray bg-gray-200'>
                                      <tr>
                                        <th className='py-3 px-5 max-w-[100px]'>Amount</th>
                                        <th className='py-3 px-5 max-w-[100px]'>Normal</th>
                                        <th className='py-3 px-5 max-w-[100px]'>Advance</th>
                                      </tr>
                                    </thead>
                                    <tbody className='bg-gray-100 text-light-gray'>
                                      {row?.payout_grid.map((data: any) => (
                                        <tr className='border-b'>
                                          <td className='py-3 px-5 max-w-[100px] text-center'>
                                            {data?.disbursal_amount}
                                          </td>
                                          <td className='py-3 px-5 max-w-[100px] text-center'>
                                            {data?.normal_payout_grid}
                                          </td>
                                          <td className='py-3 px-5 max-w-[100px] text-center'>
                                            {data?.advance_payout_grid}
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </>
                              )}
                            </div>
                          </div>
                        </ClickAwayListener>)
                      }
                    </span>
                  </TableCell>
                  {permission[PERMISSION_TYPE.ADD_PAYOUT_GRID] &&
                    <TableCell align="center" component="th" scope="row">
                      <button className='relative'>
                        <img className='mx-auto' onClick={() => {
                          let temp: boolean[] = [...actionOpen];
                          if (!temp[index]) {
                            temp = temp.map(() => { return false })
                          }
                          temp[index] = !temp[index]
                          setActionOpen(temp)
                        }} src="../assets/menu-cirlce.svg" alt="" />
                        {actionOpen[index] &&

                          <ClickAwayListener onClickAway={() => {
                            let temp: boolean[] = [...actionOpen];
                            temp = temp.map(() => { return false })
                            setActionOpen(temp)
                          }}>
                            <ul className='absolute bg-white w-[120px] top-full text-light-gray right-0 z-[11] border shadow-2xl'>
                              <li>
                                <a href="" onClick={(e) => {
                                  e.preventDefault();
                                  setSelected(row?.selectedSlab)
                                  setEditOpen(true);
                                  setNormalSlot({
                                    normal: row.Normal,
                                    capping: row.capping,
                                    user_id:row.Userid,
                                    logincodelist_id:row.LoginCode,
                                    advance:row.Advance
                                  })
                                  setFlatSlotData({ ...flatSlotData, loginCodeId: row.id });
                                }} className='px-4 border-b py-[10px] text-sm flex items-center space-x-2'>
                                  <img src="../assets/edit-pencil.svg" alt="" />
                                  <span>Edit</span>
                                </a>
                              </li>
                              
                              <li>
                                <a href="" onClick={(e) => {
                                  e.preventDefault();
                                  handleOpenHistoryModel(userrid,row.LoginCode);
                                }} className='px-4 border-b py-[10px] text-sm flex items-center space-x-2'>
                                  <img src="../assets/plus-gray.svg" alt="" />
                                  <span>View History</span>
                                </a>
                              </li>
                            </ul>


                          </ClickAwayListener>
                        }
                      </button>
                    </TableCell>
                  }
                </TableRow>
              )
            }}
          />
        </div>
        {/* </main> */}
        {/* END::MAIN CONTENT */}
      </>
    }
  </div>)
}

export default UserPayout
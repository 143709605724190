import {
  Checkbox,
  TableCell,
  TableRow,
  Tooltip,
  TextField,
} from "@mui/material";
import React, { useRef, useState } from "react";
import TableComponent from "../../components/Tables";
import { useEffect } from "react";
import DatePicker from "react-datepicker";
import { ApiGet, ApiPost, ApiPut } from "../../helper/API/ApiData";
import { STATE } from "../../constants";
import axios from "axios";
import _ from "lodash";
import { isEmpty, numberFormat } from "../../helper/util";
import { getSlabValue } from "./../../helper/util";
import STORAGEKEY from "../../config/APP/app.config";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Logout_Auth } from "../../redux/reducers/authReducer";
import { PERMISSION_TYPE, userPermission } from "../../helper/permission";
import { RootState } from "../../redux/store";
import { toast } from "react-toastify";
import CsvDownload from "react-json-to-csv";
import { FilterMatchMode } from "primereact/api";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { TieredMenu } from "primereact/tieredmenu";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { Padding } from "@mui/icons-material";
import { ConfirmDialog } from "primereact/confirmdialog";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { RadioButton } from "primereact/radiobutton";
import moment from 'moment'
import Select from 'react-select';
import LoginCodeList from "../login/LoginCodeList";

const DisbursementData = () => {
  const disbursementData = {
    borrower_name: "",
    lan_no: "",
    // pan_no: "",
    borrower_contact_person_name: "",
    loan_amount: "",
    borrower_pincode: "",
    logincodelist_id: "",
    financer_name: "",
    // product: "",
    disbursement_date: new Date(),
    borrower_contact_person_number: "",
    dsa_name_error:null,
    borrower_name_error: null,
    lan_no_error: null,
    loan_amount_error: null,
    pincode_error: null,
    financer_name_error: null,
    product_error: null,
    // pan_no_error: null,
    contact_person_name_error: null,
    month_of_disbursement_error: null,
    mobile_error: null,
    connector_id: null,
    user_id: '',
    is_connector: false,
    Userpayout: 0,
    connector_rate: null,
    connector_error: null,
    adjustment_amount: 0,
    reason: '',
    reason_error:null,
    state_error: null,
    city_error: null,
    state: "",
    city: "",
  };
  const data={
    // month:'',
    // year: '',
            file_sequence_num:'',
            // pymt_prod_type_code: '',
            // pymt_mode: '',
            // debit_acct_no: '',
            // beneficiary_name: '',
            // beneficiary_account_no: '',
            // bene_ifsc_code: '',
            amount: '',
            // debit_narration: '',
            // credit_narration: '' ,
            // mobile_number: '',
            // email_id: '',
            // remark: '',
            pymt_date: new Date(),
            // addl_info1: '',
            // status: '',
            // current_step: '',
            // file_name: '',
            acct_debit_date: new Date(),
            customer_ref_no: '',
            utr_no: '',
            // month_error:null,
            // year_error:null,
            file_sequence_num_error:null,
            // pymt_prod_type_code_error:null,
            // pymt_mode_error:null,
            // debit_acct_no_error:null,
            // beneficiary_name_error:null,
            // beneficiary_account_no_error:null,
            // bene_ifsc_code_error:null,
            amount_error:null,
            // debit_narration_error:null,
            // credit_narration_error:null ,
            // mobile_number_error:null,
            // email_id_error:null,
            // remark_error:null,
            pymt_date_error:null,
            // addl_info1_error:null,
            // status_error:null,
            // current_step_error:null,
            // file_name_error:null,
            acct_debit_date_error:null,
            customer_ref_no_error:null,
            utr_no_error:null

  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [addOpen, setAddOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);

  const [loader, setLoader] = useState(true);
  const [isconnector, setConnector] = useState(false);
  const [connectorList, setConnectorList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [activeTab, setActiveTab] = useState("initiated");
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [selectedId, setSelectedId] = useState<string>("");

  const [formData, setFormData] = useState<any>(disbursementData);
  const [paymentForm,setPaymentForm]=useState<any>(data);
  const [disbursementDetail, setDisbursementDetail] = useState([]);
  const [state, setState] = useState(STATE.DEFAULT_STATE);
  const [l1l2Detail, setL1L2Detail] = useState([]);
  const [stateLevel, setStateLevel] = useState(STATE.DEFAULT_STATE);
  const [misMatchDetail, setMisMatchDetail] = useState([]);
  const [stateMisMatch, setStateMisMatch] = useState(STATE.DEFAULT_STATE);
  const [bankData, setBankData] = useState<any>({});
  const [monthData, setMonthData] = useState<string[]>([
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]);
  const [permission, setPermission] = useState<any>({});
  const userData = useSelector(
    (state: RootState) => state?.authUser?.userData?.user
  );
  const [selectedFile, setSelectedFile] = useState<File | string>();
  const [getAllData, setGetAllData] = useState<any>([]);
  const [getAccNo, setgetAccNo] = useState<any>();
  const [open, setOpen] = useState(false);
  const [item, setItem] = useState<any>({
    gross_payout: 0,
    product: "",
    bankname: "",
    payoutpercentage: 0,
  });
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [advanceRequests, setAdvanceRequests] = useState(null);
  const [advanceStatuses, setAdvanceStatuses] = useState(null);
  const [financers, setFinancers] = useState(null);
  const [dsaName, setDsaNames] = useState(null);
  const [paymentStatuses, setPaymentStatuses] = useState(null);
  const [products, setProducts] = useState(null);
  const [disableField, setDisableField] = useState(false);
  const [disableEdit, setDisableEdit] = useState(false);
  const [paginator, setPaginator] = useState(true);
  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 0,
    pagination:'',
    search: "",
    filters: {
      Financer: { value: null, matchMode: FilterMatchMode.IN },
      DsaName: { value: null, matchMode: FilterMatchMode.IN },
      Product: { value: null, matchMode: FilterMatchMode.IN },
      dismonth: { value: null, matchMode: FilterMatchMode.IN },
      Paymentstatus: { value: null, matchMode: FilterMatchMode.IN },
      Advancerequest: { value: null, matchMode: FilterMatchMode.IN },
      Advancestatus: { value: null, matchMode: FilterMatchMode.IN },
    },
  });
  const [approvePayment,setApprovePayment]=useState(false)
  let date=new Date().toDateString();
  const [newdate,setDate]=useState(moment(date).format('DD-MM-YY'))
  useEffect(() => {
    if (!userData.profile_completed && userData.role === "user") {
      toast.error("Please validate your KYC before adding disbursement data.", {
        position: "top-right",
        theme: "colored",
      });
      navigate(`/`);
    }
    const tempPermission = {
      [PERMISSION_TYPE.ADMIN]: userPermission(userData, PERMISSION_TYPE.ADMIN),
      [PERMISSION_TYPE.USER]: userPermission(userData, PERMISSION_TYPE.USER),
      [PERMISSION_TYPE.STAFF]: userPermission(userData, PERMISSION_TYPE.STAFF),
      [PERMISSION_TYPE.CONNECTOR]: userPermission(userData, PERMISSION_TYPE.CONNECTOR),
      [PERMISSION_TYPE.STAFF_OPS]: userPermission(userData, PERMISSION_TYPE.STAFF_OPS),
    };
    setPermission(tempPermission);
  }, [userData]);

  const getOptionsData = () => {
    setLoader(true);
    ApiGet(`options/disbursement`).then((res: any) => {
      const advanceRequests = convertToObjectArray(res.data.advance_request);
      const advanceStatuses = convertToObjectArray(res.data.advance_status);
      const financers = convertToObjectArray(res.data.financers);
      const paymentStatuses = convertToObjectArray(res.data.payment_status);
      const products = convertToObjectArray(res.data.products);
      const dsaName = convertToObjectArray(res.data.dsa_name);
      setAdvanceRequests(advanceRequests);
      setAdvanceStatuses(advanceStatuses);
      setFinancers(financers);
      setDsaNames(dsaName);
      setPaymentStatuses(paymentStatuses);
      setProducts(products);
    });
  };

  const convertToObjectArray = (obj) => {
    return Object.keys(obj).map((key) => ({
      label: obj[key]?.toString(),
      value: key,
    }));
  };

  const advanceRequestFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={advanceRequests}
        onChange={(e) => options.filterApplyCallback(e.value)}
        optionLabel="label"
        placeholder="Select"
        className="p-column-filter"
        maxSelectedLabels={1}
        style={{ minWidth: "14rem" }}
        filter
        filterPlaceholder="Search"
      />
    );
  };

  const advanceStatusesFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={advanceStatuses}
        onChange={(e) => options.filterApplyCallback(e.value)}
        optionLabel="label"
        placeholder="Select"
        className="p-column-filter"
        maxSelectedLabels={1}
        style={{ minWidth: "14rem" }}
        filter
        filterPlaceholder="Search"
      />
    );
  };

  const financersFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={financers}
        onChange={(e) => options.filterApplyCallback(e.value)}
        optionLabel="label"
        placeholder="Select"
        className="p-column-filter"
        maxSelectedLabels={1}
        style={{ minWidth: "14rem" }}
        filter
        filterPlaceholder="Search"
      />
    );
  };
  const dsaNameFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={dsaName}
        onChange={(e) => options.filterApplyCallback(e.value)}
        optionLabel="label"
        placeholder="Select"
        className="p-column-filter"
        maxSelectedLabels={1}
        style={{ minWidth: "14rem" }}
        filter
        filterPlaceholder="Search"
      />
    );
  };

  const paymentStatusesFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={paymentStatuses}
        onChange={(e) => options.filterApplyCallback(e.value)}
        optionLabel="label"
        placeholder="Select"
        className="p-column-filter"
        maxSelectedLabels={1}
        style={{ minWidth: "14rem" }}
        filter
        filterPlaceholder="Search"
      />
    );
  };

  const productsFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={products}
        onChange={(e) => options.filterApplyCallback(e.value)}
        optionLabel="label"
        placeholder="Select"
        className="p-column-filter"
        maxSelectedLabels={1}
        style={{ minWidth: "14rem" }}
        filter
        filterPlaceholder="Search"
      />
    );
  };
  const months = [
    { label: "January", value: 1 },
    { label: "February", value: 2 },
    { label: "March", value: 3 },
    { label: "April", value: 4 },
    { label: "May", value: 5 },
    { label: "June", value: 6 },
    { label: "July", value: 7 },
    { label: "August", value: 8 },
    { label: "September", value: 9 },
    { label: "October", value: 10 },
    { label: "November", value: 11 },
    { label: "December", value: 12 },
  ];
  const monthMap: Record<string, string> = {};
    months.forEach((month) => {
        monthMap[month.value] = month.label;
    });

    // Generate an array of years from 2020 to 2030
    const years = Array.from({ length: 11 }, (_, index) => {
        const yearValue = 2020 + index;
        return { value: yearValue, label: yearValue.toString() };
    });

    const handleMonthChange = (selectedOption: any) => {
        setPaymentForm({
            ...paymentForm,
            month: selectedOption ? selectedOption.value : '',
            month_error: null,
        });
    };

    const handleYearChange = (selectedOption: any) => {
      setPaymentForm({
            ...paymentForm,
            year: selectedOption ? selectedOption.value : '',
            year_error: null,
        });
    };
  const DismonthFilterTemplate = (options) => {
    
    const years=[];
    let currentYear;
              if(selectedMonth <= 6){
                 currentYear = new Date().getFullYear();
              }
              else{
                 currentYear = new Date().getFullYear()+1;
              }  
              for (let i = currentYear; i >= 2018; i--) {
                years.push({ label: i.toString(), value: i });
              }  

    const onApplyFilter = () => {
      options.filterApplyCallback({ month: selectedMonth, year: selectedYear });
    };

    const onClearFilter = () => {
      setSelectedMonth(null);
      setSelectedYear(null);
      options.filterApplyCallback({ month: null, year: null });
    };

    const isBothSelected = selectedMonth && selectedYear;
    return (
      <div style={{ minWidth: "14rem" }}>
        <div className="mb-2">
          <Dropdown
            optionLabel="label"
            optionValue="value"
            value={selectedMonth}
            options={months}
            onChange={(e) =>{ 
              
              setSelectedMonth(e.value)}}
            placeholder="Select Month"
            className="p-column-filter"
            filter
          />
        </div>
        <div className="mb-2">
          <Dropdown
            optionLabel="label"
            optionValue="value"
            value={selectedYear}
            options={years}
            onChange={(e) => setSelectedYear(e.value)}
            placeholder="Select Year"
            className="p-column-filter"
            filter
          />
        </div>
        <div className="p-column-filter-buttonbar" style={{ padding: "0" }}>
          <Button
            label="Clear"
            onClick={onClearFilter}
            className="p-button p-component p-button-outlined p-button-sm"
          />
          <Button
            label="Apply"
            onClick={onApplyFilter}
            className="p-button p-component p-button-sm"
            disabled={!isBothSelected}
          />
        </div>
      </div>
    );
  };

  const columns = [
    { id: "SRNO", label: "SR.NO." },
    permission[PERMISSION_TYPE.ADMIN] || permission[PERMISSION_TYPE.STAFF_OPS] || permission[PERMISSION_TYPE.STAFF]
      ? { id: 'dsa_name', label: 'DSA Name' } : null,
    { id: "borrower_name", label: "Borrower Name" },
    { id: "borrower_contact_person_name", label: "Contact Person" },
    { id: "loan_amount", label: "Loan Amount" },
    { id: "bank_name", label: "Financer" },
    { id: "product", label: "Product" },
    { id: "disbursement_date", label: "Dis. Month" },
    { id: "connector_name", label: "Connector Name" },
    { id:`${permission[PERMISSION_TYPE.CONNECTOR] ? "connector_rate" : "rate"}`, label: "Payout in %" },
    permission[PERMISSION_TYPE.CONNECTOR] ?
     null :{ id: "connector_rate", label: "Connector Payout %" },
    permission[PERMISSION_TYPE.CONNECTOR] ?
     null :{ id: "adjustment_amount", label: "Adjustment Amount" },
    { id: `${permission[PERMISSION_TYPE.CONNECTOR] ? "connector_gross_payout" :"gross_payout"}`, label: "Gross Payout" },
    { id:`${permission[PERMISSION_TYPE.CONNECTOR] ? "connector_tds_amount" :  "tds_amount"}`, label: "Tds Amount" },
    { id: `${permission[PERMISSION_TYPE.CONNECTOR] ? "connector_gst_amount" :"gst_amount"}`, label: "GST Amount" },
    { id:`${permission[PERMISSION_TYPE.CONNECTOR] ? "connector_net_amount_payable" :  "net_amount_payable"}`, label: "Net Payout" },
    { id: "status", label: "Payment Status" },
    { id: "advance_request", label: "Advance Request" },
    { id: "advance_status", label: "Advance Status" },
  ];

  const ConvertDate=(date)=>{
    let ndate=new Date(date);
    
    return ndate.toISOString() ;
  }
  const getData = () => {
    setLoader(true);
     let  modifiedLazyState ;
     if(paginator) {
      modifiedLazyState={  ...lazyState,
        limit: lazyState.rows,
        pagination: true,
      }}
      else{
        modifiedLazyState={  ...lazyState,
          limit: lazyState.rows,
          pagination: false,
        }
      }
    delete modifiedLazyState.rows;
    delete modifiedLazyState["multiSortMeta"];
    delete modifiedLazyState["sortField"];
    delete modifiedLazyState["sortOrder"];

    // Function to replace empty arrays in the "value" field with null
    const replaceEmptyValueArrays = (obj) => {
      for (const key in obj) {
        if (
          key === "value" &&
          Array.isArray(obj[key]) &&
          obj[key].length === 0
        ) {
          obj[key] = null;
        } else if (typeof obj[key] === "object") {
          replaceEmptyValueArrays(obj[key]);
        }
      }
      return obj;
    };
    // Call the function to replace empty arrays in the "value" field with null
    const finalModifiedLazyState = replaceEmptyValueArrays(modifiedLazyState);
    finalModifiedLazyState.page++;
    ApiPost(`disbursement/get-disbursement`, finalModifiedLazyState)
      .then((res: any) => {
        setTotalRecords(Number(res.data.count.count));
          let finalData = res.data.result.map((item: any, index: number) => {
            return {
              SRNO: (lazyState.first) + index + 1,
              id: item._id,
              DsaName: item.dsa_name ?? "-",
              BorrowerName: item.borrower_name ?? "-",
              Contact_Person_Name:
                item.borrower_contact_person_name == ""
                  ? "-"
                  : item.borrower_contact_person_name,
              Loanamount: item.loan_amount ?? "-",
              Financer: item.bank_name ?? "-",
              Product: item?.product ?? "-",
              dismonth:ConvertDate(item?.disbursement_date) ?? "-",
              Connectorname: item?.connector_name ?? "-",
              Grosspayout: item?.gross_payout ?? "0",
              Advancegross: item?.advance_gross,
              Tdspayout: item?.tds_amount ?? "0",
              Gstpayout: item?.gst_amount ?? "0",
              Userpayout: item?.rate ?? "0",
              Netpayout: item?.net_amount_payable ?? "0",
              Advancerequest: item?.advance_request ?? "-",
              Advancestatus: item?.advance_status ?? "-",
              Paymentstatus: item?.status,
              city: item?.city,
              state: item?.state,
              borrower_pincode: item?.borrower_pincode,
              borrower_contact_person_number:
                item?.borrower_contact_person_number,
              logincodelist_id: item?.logincodelist_id,
              connector_id: item?.connector_id,
              connector_rate: item?.connector_rate,
              is_connector: item?.is_connector,
              lan_no: item?.lan_no,
              adjustment_amount: item?.adjustment_amount,
              // disbursement_date:item?.disbursement_date,
              reason: item?.reason,
              connector_gross_payout:item.connector_gross_payout,
              connector_gst_amount:item.connector_gst_amount,
              connector_net_amount_payable:item.connector_net_amount_payable,
              connector_tds_amount:item.connector_tds_amount,
              dsa_id:item.dsa_id
            };
          });
          setDisbursementDetail(finalData);
          setState({
            ...state,
            totalItems: res.data.count.count,
          });
          if(!paginator)
            {
          const data=res.data.result;
          const doc = new jsPDF("landscape", "mm", "a2");
    
          let width = doc.internal.pageSize.width;
          let height = doc.internal.pageSize.height;
      
          const img = new Image();
          img.src = "assets/logo.png";
          img.onload = function () {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.globalAlpha = 0.2;

           ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            const watermarkBase64 = canvas.toDataURL("image/png");

            const maxWidth = width * 0.5;
            const maxHeight = height * 0.5; 
            let newWidth = img.width;
            let newHeight = img.height;

            if (newWidth > maxWidth || newHeight > maxHeight) {
              const widthRatio = maxWidth / newWidth;
              const heightRatio = maxHeight / newHeight;
              const scale = Math.min(widthRatio, heightRatio);
      
              newWidth = newWidth * scale;
              newHeight = newHeight * scale;
          }
      
          // Calculate the center position
          let xPos = (width - newWidth) / 2;
          let yPos = (height - newHeight) / 2;
            let column=columns.filter((col)=>{
               if(col != null)
                return col;
            })
            // doc.addImage(img, 'PNG', width / 2 - 100, height / 2 - 100, 200, 200, undefined, 'SLOW');
            autoTable(doc, {
              head: [column.map((col) =>        
                     col.label
                  )],
              body: data.map((product, index) =>
                column.map((col) => {                  
                  if ( col.id === "SRNO" ) {
                    return index + 1;
                  } else if(col != null) {
                    return product[col.id];
                  }
                })
              ),
              styles: {
                fontSize: 11,
                cellPadding: 3,
                textColor: [0, 0, 0],
              },
              headStyles: {
                fillColor: [52, 152, 219],
                textColor: [255, 255, 255],
              },
              margin: { top: 15 },
              theme: "grid",
              didDrawPage: function (data) {
                doc.addImage(
                  watermarkBase64,
                  "PNG",
                  xPos, yPos, newWidth, newHeight
                );
              },
            });
            doc.save(`Disbursement_${newdate}.pdf`);
            
          };
          setPaginator(true);
        }
        setLoader(false);
      
  })
      .catch((error) => {
        setDisbursementDetail([]);
      });
  };
  const dt = useRef(null);

  const onPage = (event) => {
    let filter = { ...lazyState };
    filter = event;
    filter["search"] = lazyState.search;
    setlazyState(filter);
  };

  const onFilter = (event) => {
    event["first"] = 0;
    event["page"] = 0;
    const dismonthValue = event.filters?.dismonth?.value;

    if (
      dismonthValue &&
      dismonthValue.month === null &&
      dismonthValue.year === null
    ) {
      event.filters.dismonth.value = null;
    }

    setlazyState(event);
  };
  const targetRef = useRef();

  const exportPdf = () => {
    setPaginator(false);
    getData();   
  };
  const renderHeader = () => {
    return (
      <div className="flex justify-end justify-space-between">
        <div>
          <Button
            type="button"
            icon="pi pi-file-pdf"
            rounded
            onClick={exportPdf}
            data-pr-tooltip="PDF"
          />
        </div>
        <div>
          <span>
            <InputText
              className="mr-2 ml-3"
              value={searchValue}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              placeholder="Keyword Search"
            />
            <Button icon="pi pi-search" onClick={handleSearchClick} />
          </span>
        </div>
      </div>
    );
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onGlobalFilterChange(searchValue);
    }
  };

  const handleSearchClick = () => {
    onGlobalFilterChange(searchValue);
  };

  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const onGlobalFilterChange = (e) => {
    const value = e;
    let filter = { ...lazyState };

    filter["search"] = value;

    setlazyState(filter);
  };

  const header = renderHeader();
  const menuRef = useRef(null);
  const selectedRowData = useRef(null);
  const [deleteConfirmVisible, setDeleteConfirmVisible] =
    useState<boolean>(false);
  const accept = () => {
    handleDelete(selectedRowData.current);
  };

  const reject = () => {
    setDeleteConfirmVisible(false);
  };

  const handleMenuItemClick = (itemLabel) => {
    if (itemLabel === "Edit") {
      handleEditClick(selectedRowData.current);
      if (selectedRowData.current.Paymentstatus === "Billing Received") {
        setDisableField(true);
      }
      if (selectedRowData.current.Paymentstatus === "Completed")
        setDisableEdit(true);
    }
    if (itemLabel === "Advance Approval") {
      setSelectedId(selectedRowData.current.id);
      setItem({
        ...item,
        product: selectedRowData?.current.Product,
        gross_payout: selectedRowData?.current.Advancegross,
        bankname: selectedRowData?.current.Financer,
        payoutpercentage: selectedRowData?.current.Userpayout,
      });
      setOpen(true);
    }
    if (itemLabel === "Delete") {
      if (
        selectedRowData.current.Paymentstatus === "Payment Pending" ||
        selectedRowData.current.Paymentstatus === "Mismatch Case"
      ) {
        setDeleteConfirmVisible(true);
      } else {
        toast.error("Disbursement cannot be deleted", {
          position: "top-right",
          theme: "colored",
        });
      }
    }
    if (itemLabel === "Add Utr Details") {
     setApprovePayment(true)
    }
  };

  const handleEllipsisClick = (event, rowData) => {
    event.stopPropagation(); // Prevent event from bubbling up
    selectedRowData.current = rowData;
    if (menuRef.current) {
      menuRef.current.toggle(event);
    }
  };
  const actionBodyTemplate = (rowData) => {
    return (
      <div>
        <img
          src="../assets/menu-cirlce.svg"
          alt="menu-icon"
          style={{ cursor: "pointer" }}
          onClick={(e) => handleEllipsisClick(e, rowData)}
        />
        <TieredMenu model={generateMenuItems()} popup ref={menuRef} />
      </div>
    );
  };

  const generateMenuItems = () => {
    const menuItems = [];
    // if (permission[PERMISSION_TYPE.ADMIN]) {
    menuItems.push({
      label: "Edit",
      command: () => handleMenuItemClick("Edit"),
    });
    // }

    menuItems.push({
      label: "Advance Approval",
      command: () => handleMenuItemClick("Advance Approval"),
    });
    if (permission[PERMISSION_TYPE.ADMIN] || permission[PERMISSION_TYPE.STAFF_OPS]) {
      menuItems.push({
        label: "Delete",
        command: () => handleMenuItemClick("Delete"),
      });
    }
    if (permission[PERMISSION_TYPE.ADMIN] ) {

    menuItems.push({
      label: "Add Utr Details",
      command: () => handleMenuItemClick("Add Utr Details"),
    });}

    return menuItems;
  };

  const handleDelete = (data: any) => {
    try {
      ApiPut(`disbursement/delete-disbursement?id=${data.id}`).then(
        (res: any) => {
          getData();
          toast.success(res.data, {
            position: "top-right",
            theme: "colored",
          });
        }
      );
    } catch (error) {
      // toast.error(error.error, {
      //   position: "top-right",
      //   theme: "colored"
      // })
    }
    getData();
  };

  const getConnectorUser = (code: any) => {
    ApiGet(`connector/getConnector/code/${code}`)
      .then((res: any) => {
        const temp: any = [
          {
            id: res.data.result._id,
            // label: res.data.result.email,
            // label: `${res.data.first_name} ${res.data.middle_name || ''} ${res.data.last_name} - ${res.data.email}`,
          },
        ];
        setConnectorList(temp);
      })
      .catch((error) => {
        setConnectorList([]);
      });
  };

  const getConnector = (id) => {
    ApiGet(`connector/option-connector?id=${id}`)
      .then((res: any) => {
        let temp = res.data.result.map((item: any, index: number) => {
          return {
            id: item._id,
            label: `${item.first_name} ${item.middle_name || ''} ${item.last_name} - ${item.email}`,
          };
        });
        setConnectorList(temp);
      })
      .catch((error) => {
        setConnectorList([]);
      });
  };

  const getUser = () => {
    ApiGet(`user/option-users`)
      .then((res: any) => {
        setUserList(res.data.result);
      })
      .catch((error) => {
        setUserList([]);
      });
  };

  const getLoginCodeList = async () => {
    await ApiGet(`loginCodeList/option-codelist`)
      .then((res: any) => {
        let temp = res.data.result.map((item: any, index: number) => {
          return {
            bank: item.bank_name,
            product: item.product,
            id: item._id,
          };
        });
        const bankData = _.groupBy(temp, function (b) {
          return b.bank;
        });
        setBankData(bankData);
      })
      .catch((error) => {
        setBankData({});
      });
  };

  const handlePage = (page: number) => {
    if (activeTab == "initiated") {
      setState({
        ...state,
        page,
      });
    }
    if (activeTab == "l1l2") {
      setStateLevel({
        ...stateLevel,
        page,
      });
    }
    if (activeTab == "mismatch") {
      setStateMisMatch({
        ...stateMisMatch,
        page,
      });
    }
  };

  const handlePageData = (limit: number, page: number = 1) => {
    if (activeTab == "initiated") {
      setState({
        ...state,
        limit,
        page,
      });
    }
    if (activeTab == "l1l2") {
      setStateLevel({
        ...stateLevel,
        limit,
        page,
      });
    }
    if (activeTab == "mismatch") {
      setStateMisMatch({
        ...stateMisMatch,
        limit,
        page,
      });
    }
  };

  useEffect(() => {
    Promise.all([
      getData(),
      getLoginCodeList(),
      getUser(),
      getOptionsData(),
      
    ])
      .then(() => {
        setTimeout(() => {
          setLoader(false);
        }, 250);
      })
      .catch((error) => {
        setLoader(false);
      });
  }, [lazyState,paginator]);
  

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const addDisbursement = () => {
    let updatedForm = { ...formData };
    for (let key in updatedForm) {
      if (typeof updatedForm[key] == "string") {
        updatedForm[key] = updatedForm[key].trim();
      }
    }
    let mobilePattern = /^\+?[0-9]{10,}$/;

    if (formData.user_id == "") {
      updatedForm = {
        ...updatedForm,
        dsa_name_error: "Please select DSA name",
      };
    }
    if ((!permission[PERMISSION_TYPE.STAFF]) || (!permission[PERMISSION_TYPE.ADMIN]) || (!permission[PERMISSION_TYPE.STAFF_OPS])) {
      updatedForm = {
        ...updatedForm,
        dsa_name_error: null,
      };
    }
    if (formData.borrower_name == "") {
      updatedForm = {
        ...updatedForm,
        borrower_name_error: "Please enter a borrower name",
      };
    }

    if (formData.loan_amount == "") {
      updatedForm = {
        ...updatedForm,
        loan_amount_error: "Please enter a loan amount",
      };
    }
    // if (formData.borrower_pincode == "") {
    //   updatedForm = { ...updatedForm, pincode_error: "Please enter a pincode" };
    // }
    if (formData.borrower_pincode == "") {
      updatedForm = {
        ...updatedForm,
        pincode_error: "Please enter a Pincode",
      };
    }
    let pincodePatten = /^(\d{6})$/;
    if (
      formData.borrower_pincode != "" &&
      !pincodePatten.test(formData.borrower_pincode)
    ) {
      updatedForm = {
        ...updatedForm,
        pincode_error: "Please enter a valid pincode",
      };
    }
    if (formData.financer_name == "") {
      updatedForm = {
        ...updatedForm,
        financer_name_error: "Please enter a financer name",
      };
    }
        if((formData.adjustment_amount == 0 || formData.adjustment_amount == "") && formData.reason == "" ){
      updatedForm = { ...updatedForm, reason_error: null };
    }
    if((formData.adjustment_amount != '' || formData.adjustment_amount != 0) && formData.reason == ''){
      updatedForm = { ...updatedForm, reason_error: "Please enter a reason" };
    }
    if (formData.financer_name  && formData.logincodelist_id == "") {
      updatedForm = { ...updatedForm, product_error: "Please select Product" };
    }
    if (formData.financer_name == "" && formData.logincodelist_id == "") {
      updatedForm = { ...updatedForm, product_error:null };
    }
    if (formData.disbursement_date == null) {
      updatedForm = {
        ...updatedForm,
        month_of_disbursement_error: "Please enter a Month",
      };
    }
    if (formData.connector_id == null && isconnector) {
      updatedForm = {
        ...updatedForm,
        connector_error: "Please enter a connector",
      };
    }
    // if (formData.borrower_contact_person_number == "") {
    //   updatedForm = { ...updatedForm, mobile_error: "Please enter a mobile no." };
    // }
    if (
      formData.borrower_contact_person_number != "" &&
      !mobilePattern.test(formData.borrower_contact_person_number)
    ) {
      updatedForm = {
        ...updatedForm,
        mobile_error: "Please enter a valid mobile no.",
      };
    }

    setFormData(updatedForm);
    updatedForm = {
      ...updatedForm,
      adjustment_amount:
        updatedForm.adjustment_amount === ""
          ? "0.00"
          : updatedForm.adjustment_amount,
    };
    if (
      updatedForm.dsa_name_error ==null &&
      updatedForm.borrower_name_error == null &&
      updatedForm.loan_amount_error == null &&
      updatedForm.pincode_error == null &&
      updatedForm.financer_name_error == null &&
      updatedForm.product_error == null &&
      updatedForm.month_of_disbursement_error == null &&
      updatedForm.connector_error == null &&
      updatedForm.mobile_error == null &&
      updatedForm.reason_error==null
    ) {
      const {
        borrower_name_error,
        lan_no_error,
        loan_amount_error,
        pincode_error,
        financer_name_error,
        product_error,
        month_of_disbursement_error,
        connector_error,
        contact_person_name_error,
        mobile_error,
        reason_error,
        ...payload
      } = updatedForm;
      let finalData = payload;
      if (updatedForm.connector_id == "") {
        let { connector, ...payload } = finalData;
        finalData = payload;
      }

      ApiPost("/disbursement/add-disbursement", finalData)
        .then((res: any) => {
          setFormData(disbursementData);
          getData();
          setAddOpen(false);
          setConnector(false);
          setConnectorList([]);
          if (res?.status == 200) {
            toast.success("Data added successfully!!", {
              position: "top-right",
              theme: "colored",
            });
          }
        })
        .catch((error) => {});
    }
  };

  const addPaymentData = () => {

    let updatedForm = { ...paymentForm };
    for (let key in updatedForm) {
      if (typeof updatedForm[key] == "string") {
        updatedForm[key] = updatedForm[key].trim();
      }
    }
    // let mobilePattern = /^\+?[0-9]{10,}$/;
    // let emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    // if (paymentForm.month == "") {
    //   updatedForm = {
    //     ...updatedForm,
    //     month_error: "Please select Month",
    //   };
    // }
    // if (paymentForm.year == "") {
    //   updatedForm = {
    //     ...updatedForm,
    //     year_error: "Please select Year",
    //   };
    // }
   
    if (paymentForm.file_sequence_num == "") {
      updatedForm = {
        ...updatedForm,
        file_sequence_num_error: "Please enter file sequrence no.",
      };
    }

    // if (paymentForm.pymt_prod_type_code == "") {
    //   updatedForm = {
    //     ...updatedForm,
    //     pymt_prod_type_code_error: "Please enter a Payment prod type code",
    //   };
    // }
    // if (paymentForm.pymt_mode == "") {
    //   updatedForm = { ...updatedForm, pymt_mode_error: "Please enter a Payment mode" };
    // }
    // if (paymentForm.debit_acct_no == "") {
    //   updatedForm = {
    //     ...updatedForm,
    //     debit_acct_no_error: "Please enter a Debit Acc. No.",
    //   };
    // }
    // if (paymentForm.beneficiary_name == "") {
    //   updatedForm = {
    //     ...updatedForm,
    //     beneficiary_name_error: "Please enter a beneficiary name",
    //   };
    // }
    // if (paymentForm.beneficiary_account_no == "") {
    //   updatedForm = {
    //     ...updatedForm,
    //     beneficiary_account_no_error: "Please beneficiary account no.",
    //   };
    // }

    // if (paymentForm.bene_ifsc_code == "") {
    //   updatedForm = {
    //     ...updatedForm,
    //     bene_ifsc_code_error: "Please beneficiary account no.",
    //   };
    // }
    if (paymentForm.amount == "") {
      updatedForm = { ...updatedForm, amount_error: "Please enter an amount" };
    }
    // if (paymentForm.debit_narration == "") {
    //   updatedForm = {
    //     ...updatedForm,
    //     debit_narration_error: "Please enter a debit narration",
    //   };
    // }
    // if (paymentForm.credit_narration == "") {
    //   updatedForm = {
    //     ...updatedForm,
    //     credit_narration_error: "Please enter a debit narration",
    //   };
    // }
    
    // if (paymentForm.email_id == "") {
    //   updatedForm = { ...updatedForm, email_id_error: "Please enter an email" };
    // }

    // if (paymentForm.email_id != "" && !emailPattern.test(paymentForm.email_id)) {
    //   updatedForm = { ...updatedForm, email_id_error: "Please enter an email" };
    // }
    // if (paymentForm.remark == "") {
    //   updatedForm = {
    //     ...updatedForm,
    //     remark_error: "Please enter a remark",
    //   };
    // }
    if (paymentForm.pymt_date == "") {
      updatedForm = {
        ...updatedForm,
        pymt_date_error: "Please enter a payment date",
      };
    }
    // if (paymentForm.addl_info1 == "") {
    //   updatedForm = { ...updatedForm, addl_info1_error: "Please enter an Add info." };
    // }
    // if (paymentForm.status == "") {
    //   updatedForm = {
    //     ...updatedForm,
    //     status_error: "Please enter a status",
    //   };
    // }
    // if (paymentForm.current_step == "") {
    //   updatedForm = {
    //     ...updatedForm,
    //     current_step_error: "Please enter a current step",
    //   };
    // }
    // if (paymentForm.file_name == "") {
    //   updatedForm = {
    //     ...updatedForm,
    //     file_name_error: "Please enter a file name",
    //   };
    // }
    if (paymentForm.acct_debit_date == "") {
      updatedForm = { ...updatedForm, acct_debit_date_error: "Please enter an account debit date." };
    }
    if (paymentForm.customer_ref_no == "") {
      updatedForm = {
        ...updatedForm,
        customer_ref_no_error: "Please enter a customer reference no.",
      };
    }
    if (paymentForm.utr_no == "") {
      updatedForm = {
        ...updatedForm,
        utr_no_error: "Please enter a utr no.",
      };
    }
    // if (paymentForm.mobile_number == "") {
    //   updatedForm = {
    //     ...updatedForm,
    //     mobile_number_error: "Please enter a mobile no.",
    //   };
    // }
    // if (
    //   paymentForm.mobile_number != "" &&
    //   !mobilePattern.test(paymentForm.mobile_number)
    // ) {
    //   updatedForm = {
    //     ...updatedForm,
    //     mobile_error: "Please enter a valid mobile no.",
    //   };
    // }

    setPaymentForm(updatedForm);
   
    if (
      // updatedForm.month_error == null &&
      // updatedForm.year_error == null &&
      updatedForm.file_sequence_num_error == null &&
      // updatedForm.pymt_prod_type_code_error == null &&
      // updatedForm.pymt_mode_error == null &&
      // updatedForm.debit_acct_no_error == null &&
      // updatedForm.beneficiary_name_error == null &&
      // updatedForm.beneficiary_account_no_error == null &&
      // updatedForm.bene_ifsc_code_error == null &&
      updatedForm.amount_error == null &&
      // updatedForm.debit_narration_error == null &&
      // updatedForm.credit_narration_error == null  &&
      // updatedForm.mobile_number_error == null &&
      // updatedForm.email_id_error == null &&
      // updatedForm.remark_error == null &&
      updatedForm.pymt_date_error == null &&
      // updatedForm.addl_info1_error == null &&
      // updatedForm.status_error == null &&
      // updatedForm.current_step_error == null &&
      // updatedForm.file_name_error == null &&
      updatedForm.acct_debit_date_error == null &&
      updatedForm.customer_ref_no_error == null &&
      updatedForm.utr_no_error == null
    ) {
      const data={
       
            file_sequence_num: updatedForm.file_sequence_num,            
            amount:updatedForm.amount,            
            pymt_date:moment(new Date(updatedForm.pymt_date)).format('YYYY-MM-DD'),            
            acct_debit_date:moment(new Date(updatedForm.acct_debit_date)).format('YYYY-MM-DD'),
            customer_ref_no:updatedForm.customer_ref_no,
            utr_no:updatedForm.utr_no,
            dsa_id:selectedRowData.current.dsa_id,
            case_id:selectedRowData.current.id
      }
      
      ApiPost("disbursement/add-orenda-disbursement",data)
        .then((res: any) => {
          
          getData();
         
          if (res?.status == 200) {
            toast.success('Payment Completed', {
              position: "top-right",
              theme: "colored",
            });
          }
        })
        .catch((error) => {});
    }
  };

  const handleNameInput = (e) => {
    e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, "");
  };
  const handleBorrowerNameInput = (e) => {
    e.target.value = e.target.value.replace(/[^A-Za-z\s.]/g, '');
  };
  const handleNumericInput = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
  };
  const handleAdjustmentAmountInput = (e) => {
    e.target.value = e.target.value.replace(/[^0-9+\-\.]/g, "");
  };
  const editDisbursement = () => {
    let updatedForm = { ...formData };
    for (let key in updatedForm) {
      if (typeof updatedForm[key] == "string") {
        updatedForm[key] = updatedForm[key].trim();
      }
    }
    let namePattern = /^[A-Za-z\s]+$/;
    let mobilePattern = /^\+?[0-9]{10,}$/;
    if (formData.user_id == "") {
      updatedForm = {
        ...updatedForm,
        dsa_name_error: "Please select DSA name",
      };
    }
    if ((!permission[PERMISSION_TYPE.STAFF]) || (!permission[PERMISSION_TYPE.ADMIN]) || (!permission[PERMISSION_TYPE.STAFF_OPS])) {
      updatedForm = {
        ...updatedForm,
        dsa_name_error: null,
      };
    }
    if (formData.borrower_name == "") {
      updatedForm = {
        ...updatedForm,
        borrower_name_error: "Please enter a borrower name",
      };
    }
       if (formData.loan_amount == "") {
      updatedForm = {
        ...updatedForm,
        loan_amount_error: "Please enter a loan amount",
      };
    }
    // if (formData.borrower_pincode == "") {
    //   updatedForm = { ...updatedForm, pincode_error: "Please enter a pincode" };
    // }
    if (formData.borrower_pincode == "") {
      updatedForm = {
        ...updatedForm,
        pincode_error: "Please enter a Pincode",
      };
    }
    let pincodePattern = /^(\d{6})$/;
    if (
      formData.borrower_pincode != "" &&
      !pincodePattern.test(formData.borrower_pincode)
    ) {
      updatedForm = {
        ...updatedForm,
        pincode_error: "Please enter a valid pincode",
      };
    }
    if((formData.adjustment_amount === "0.00" || formData.adjustment_amount == "") && formData.reason == "" ){
      updatedForm = { ...updatedForm, reason_error: null };
    }
    if((formData.adjustment_amount != ''  && formData.adjustment_amount != '0.00')&& formData.reason == "" ){
      updatedForm = { ...updatedForm, reason_error:  "Please enter a reason"};
    }

    if (formData.financer_name == "") {
      updatedForm = {
        ...updatedForm,
        financer_name_error: "Please enter a financer name",
      };
    }
    if (formData.financer_name == "" && formData.logincodelist_id == "") {
      updatedForm = { ...updatedForm, product_error:null };
    }
    console.log(formData.financer_name)
    if (formData.financer_name != '' && formData.logincodelist_id == "") {
      updatedForm = { ...updatedForm, product_error: "Please select Product" };
    }
    if (formData.disbursement_date == null) {
      updatedForm = {
        ...updatedForm,
        month_of_disbursement_error: "Please enter a Month",
      };
    }
    if (formData.connector_id == null && isconnector) {
      updatedForm = {
        ...updatedForm,
        connector_error: "Please enter a connector",
      };
    }
    // if (formData.borrower_contact_person_number == "") {
    //   updatedForm = { ...updatedForm, mobile_error: "Please enter a mobile no." };
    // }
    if (
      formData.borrower_contact_person_number != "" &&
      !mobilePattern.test(formData.borrower_contact_person_number)
    ) {
      updatedForm = {
        ...updatedForm,
        mobile_error: "Please enter a valid mobile no.",
      };
    }
    setFormData(updatedForm);
    updatedForm = {
      ...updatedForm,
      adjustment_amount:
        updatedForm.adjustment_amount === ""
          ? "0.00"
          : updatedForm.adjustment_amount,
    };
    if (
      updatedForm.borrower_name_error == null &&
      updatedForm.loan_amount_error == null &&
      updatedForm.pincode_error == null &&
      updatedForm.financer_name_error == null &&
      updatedForm.product_error == null &&
      updatedForm.month_of_disbursement_error == null &&
      updatedForm.connector_error == null &&
      updatedForm.mobile_error == null &&
      updatedForm.reason_error == null 
    ) {
      const {
        borrower_name_error,
        lan_no_error,
        loan_amount_error,
        pincode_error,
        financer_name_error,
        product_error,
        month_of_disbursement_error,
        connector_error,
        contact_person_name_error,
        mobile_error,
        reason_error,
        ...payload
      } = updatedForm;
      let finalData = payload;
      if (updatedForm.connector_id == "") {
        let { connector, ...payload } = updatedForm;
        finalData = payload;
      }
      ApiPut(`disbursement/edit-disbursement`, finalData)
        .then((res: any) => {
          setFormData(disbursementData);
          getData();
          setEditOpen(false);
          if (res?.status == 200) {
            toast.success("Data updated successfully!!", {
              position: "top-right",
              theme: "colored",
            });
          }
        })
        .catch((error) => {});
    }
    
  };

  const disbursementcsv = async () => {
    let formData = new FormData();

    if (selectedFile) {
      formData.append("filedata", selectedFile);
    }

    await ApiPost(`disbursement/add-utr-csv`, formData)
      .then((res: any) => {
        if (res?.data?.message == "upload successfully") {
          toast.success("upload Successfully.", {
            position: "top-right",
            theme: "colored",
          });
          getData();
          // getConnector();
          getLoginCodeList();
          setDisableField(false);
          setDisableEdit(false);
        }

        setSelectedFile("");
      })
      .catch((error: any) => {
        // toast.error(error?.error, {
        //   position: "top-right",
        //   theme: "colored"
        // })
      });
  };

  useEffect(() => {
    if (selectedFile) {
      disbursementcsv();
    }
  }, [selectedFile]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = getAllData.map((row: any) => row._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleEditClick = (row: any) => {
    setFormData({
      ...formData,
      id: row.id,
      financer_name: row.Financer,
      logincodelist_id: row.logincodelist_id,
      borrower_name: row.BorrowerName,
      borrower_contact_person_name: row.Contact_Person_Name,
      loan_amount: row.Loanamount,
      borrower_pincode: row.borrower_pincode,
      city: row.city,
      state: row.state,
      borrower_contact_person_number: row.borrower_contact_person_number,
      lan_no: row.lan_no,
      connector_id: row.connector_id,
      is_connector: row.is_connector,
      connector_rate: row.connector_rate,
      disbursement_date: new Date(row.dismonth),
      adjustment_amount: row.adjustment_amount,
      reason: row.reason,
      advanceRequests: row.Advancerequest,
      status:row.Paymentstatus
    });
    setEditOpen(true);
    getConnector(row.dsa_id)
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  // const columns = ["SR.NO.", "Name", "City", "State", "Contact No.", "Total Billing", "TotalLoan Amount"];
  
  const columnsLevel = [
    { id: "SRNO", label: "SR.NO." },
    { id: "borrower_name", label: "Borrower Name" },
    { id: "user", label: "User" },
    { id: "month_of_disbursement", label: "Month Of Disbursement" },
    { id: "l1_dsa", label: "L1 DSA" },
    { id: "l2_dsa", label: "L2 DSA" },
    { id: "product", label: "Product" },
    { id: "loan_amount", label: "Loan Amount" },
    { id: "l1_up_expense", label: "L1 Up Expense" },
    { id: "l2_up_expense", label: "L2 Up Expense" },
  ];

  const columnsMisMatch = [
    { id: "SRNO", label: "SR.NO." },
    { id: "bank_name", label: "Bank Name" },
    { id: "customer_name", label: "Customer Name" },
    { id: "lan_Number", label: "Lan Number" },
    { id: "loan_amount", label: "Loan Amount" },
    { id: "month_and_year", label: "Month And Year" },
    { id: "payout_in_percetage", label: "Payout In Percetage" },
    { id: "product", label: "product" },
  ];

  const actionYes = async () => {
    await ApiPut(`disbursement/edit-disbursement?id=${selectedId}`, {
      advance_request: true,
      is_visible_icon: true,
      cheker_type: "maker",
    })
      .then((res: any) => {
        setSelectedId("");
        getData();
        setOpen(false);
        if(res){
          toast.success("Request sent successfully", {
          position: "top-right",
          theme: "colored"
        })
        }       
      })
      .catch((error: any) => {
        // toast.error("Something went wrong!", {
        //   position: "top-right",
        //   theme: "colored"
        // })
      });
  };

  const getPaymentMode = (row: any) => {
    if (row?.user?.kyc?.ifsc.startsWith("ICICI")) {
      return "FT";
    }
    if (row?.loan_amount >= 200000) {
      return "RTGS";
    }
    return "NEFT";
  };

  const getaccountnumber = async () => {
    await ApiGet(`basicDetails/list`)
      .then((res: any) => {
        setgetAccNo(res.data.result.account_number);
      })
      .catch((error) => {});
  };

  const getExportData = (data: any) => {
    let finalData: any = [];
    let temp = data?.map((row: any) => {
      if (selected.includes(row._id)) {
        finalData.push({
          PYMT_PROD_TYPE_CODE: "PAB_VENDOR",
          PYMT_MODE: getPaymentMode(row),
          DEBIT_ACC_NO: getAccNo,
          BNF_NAME: row?.user?.name,
          BENE_ACC_NO: row?.user?.kyc?.account_number,
          BENE_IFSC: row?.user?.kyc?.ifsc,
          AMOUNT: row?.net_amount_payable,
          MOBILE_NUM: row?.user?.mobile,
          EMAIL_ID: row?.user?.email,
          PYMT_DATE: moment().format("DD/MM/YYYY").toString(),
        });
      }
      return row;
    });
    return finalData;
  };
  const customStyles = (hasError) => ({
    control: (base, state) => ({
      ...base,
      borderColor: hasError ? 'red' : base.borderColor,
      '&:hover': {
        borderColor: hasError ? 'red' : base.borderColor,
      },
    }),
  });
  const postget = async (e: any) => {
    const value = e.target.value;
    setFormData({
      ...formData,
      borrower_pincode: e.target.value,
      pincode_error: null,
      state_error: null,
      city_error: null,
    });
    if (value.length >= 6) {
      const options = {
        method: "GET",
        url: `https://india-pincode-with-latitude-and-longitude.p.rapidapi.com/api/v1/pincode/${value}`,
        headers: {
          "X-RapidAPI-Key":
            "b2572becadmshbaf647a375178edp179823jsneae8fa2a8334",
          "X-RapidAPI-Host":
            "india-pincode-with-latitude-and-longitude.p.rapidapi.com",
        },
      };
      await axios.request(options).then((res: any) => {
        setFormData({
          ...formData,
          state: res?.data[0].state,
          city: res?.data[0].district,
          borrower_pincode: value,
        });
      });
    }
  };

  return (
    <div className="overflow-x-hidden">
      {/* <Sidebar />
<Header /> */}
      {loader ? (
        <>
          {/* START::OVERLAY */}
          <div className="fixed inset-0 w-full h-screen bg-white z-[9999] flex items-center justify-center">
            <img src="assets/logo.svg" />
          </div>
          {/* END::OVERLAY */}
        </>
      ) : (
        <>
          {/* <main className="lg:pl-[280px] pt-[92px]"> */}
          <div className="p-4 sm:p-9 space-y-5">
            {/* START::DASHBOARD HEADER */}
            <div className="flex flex-wrap gap-5 items-center justify-between">
              <div className="md:flex w-full lg:w-[unset] items-center lg:justify-between md:space-x-9 space-y-5 md:space-y-0">
                <h1 className="text-[22px] text-light-gray font-semibold">
                  Disbursement Data
                </h1>
                {/* 
                    if(tab active) 
                        Add class "bg-white rounded-[18px] text-primary"
                    else 
                        Remove class "bg-white rounded-[18px] text-primary"
                */}

                {/* for-user */}
                {permission[PERMISSION_TYPE.STAFF] && (
                  <ul className="inline-flex items-center p-[2px] text-white bg-primary rounded-[18px] whitespace-nowrap overflow-x-auto">
                    <li>
                      <button
                        className={`py-2 px-7 text-xs font-medium`}
                        onClick={(e) => {
                          e.preventDefault();
                          setAddOpen(true);
                        }}
                      >
                        Add Disbursement Data
                      </button>
                    </li>
                  </ul>
                )}

                {(permission[PERMISSION_TYPE.ADMIN] || permission[PERMISSION_TYPE.STAFF_OPS]) && (
                  <ul className="inline-flex items-center p-[2px] text-white bg-primary rounded-[18px] whitespace-nowrap overflow-x-auto">
                    <li>
                      <button
                        className={`py-2 px-7 text-xs font-medium`}
                        onClick={(e) => {
                          e.preventDefault();
                          setAddOpen(true);
                        }}
                      >
                        Add Disbursement Data
                      </button>
                    </li>
                  </ul>
                )}
                {permission[PERMISSION_TYPE.USER] && (
                  <ul className="inline-flex items-center p-[2px] text-white bg-primary rounded-[18px] whitespace-nowrap overflow-x-auto">
                    <li>
                      <button
                        className={`py-2 px-7 text-xs font-medium`}
                        onClick={(e) => {
                          e.preventDefault();
                          setFormData(disbursementData);
                          setAddOpen(true);
                        }}
                      >
                        Add Disbursement Data
                      </button>
                    </li>
                  </ul>
                )}
              </div>

              {/* START::EXPORT */}
              <ul className="flex space-x-[18px]">
                {(permission[PERMISSION_TYPE.ADMIN] || permission[PERMISSION_TYPE.STAFF_OPS]) &&
                  activeTab == "initiated" && (
                    <li>
                      {/* <input
                    id="fileChoosen"
                    type="file"
                    className="hidden"
                    onChange={(e: any) => {
                      if (!e.target.files || e.target.files.length === 0) {
                        window.location.reload();
                        return;
                      }
                      setSelectedFile(e.target.files[0]);
                    }}
                    onClick={(event: any) => {
                      event.target.value = null
                    }}
                  />
                  <label htmlFor="fileChoosen" className="flex items-center max-w-[200px] space-x-[6px] py-2 px-6 rounded-[18px] border border-light-gray text-xs font-semibolds">
                    Upload UTR File
                  </label> */}
                    </li>
                  )}
                {activeTab == "initiated" && (
                  <li>
                    {/* <CsvDownload
                    data={getExportData(getAllData)}
                    filename="report.csv"
                    className="flex items-center space-x-[6px] py-2 px-6 rounded-[18px] border border-light-gray text-xs font-semibold"
                  >
                    Export
                  </CsvDownload> */}
                    {/* <button className="flex items-center space-x-[6px] py-2 px-6 rounded-[18px] border border-light-gray text-xs font-semibold" onClick={exportPDF}>
                  <img src="assets/calendar.svg" alt="" />
                  <span>Export</span>
                </button> */}
                  </li>
                )}
                {/* <li>
                <button className="flex items-center space-x-[6px] py-2 px-6 rounded-[18px] border border-light-gray text-xs font-semibold">
                  <img src="assets/pdf.svg" alt="" />
                  <span>PDF</span>
                </button>
              </li>
              <li>
                <button className="flex items-center space-x-[6px] py-2 px-6 rounded-[18px] border border-light-gray text-xs font-semibold">
                  <img src="assets/excel.svg" alt="" />
                  <span>EXCEL</span>
                </button>
              </li> */}
              </ul>
              {/* END::EXPORT */}
            </div>

            {/* END::DASHBOARD HEADER */}
            {/*
          <TableComponent
            rows={activeTab == "initiated" ? disbursementDetail : (activeTab == "l1l2" ? l1l2Detail : misMatchDetail)}
            columns={activeTab == "initiated" ? columns : (activeTab == "l1l2" ? columnsLevel : columnsMisMatch)}
            handlePageData={handlePageData}
            handlePage={handlePage}
            state={activeTab == "initiated" ? state : (activeTab == "l1l2" ? stateLevel : stateMisMatch)}
            renderColumn={(column: any, index: number) => {
              if (index == 0 && activeTab == "initiated") {
                return (
                  <TableCell padding="checkbox" align="center"
                    className="!bg-gray-200"
                    key={column.id}
                    style={{ background: "gray" }}  >
                    <Checkbox
                      color="primary"
                      className="!bg-gray-200"
                      indeterminate={selected.length > 0 && selected.length < disbursementDetail.length}
                      checked={disbursementDetail.length > 0 && selected.length === disbursementDetail.length}
                      onChange={handleSelectAllClick}
                    />
                  </TableCell>
                )
              }
              return (
                <TableCell align="center"
                  className="!bg-gray-200"
                  key={column.id}
                  style={{ background: "gray" }}
                >
                  {column.label}
                </TableCell>
              )
            }}
            renderRow={(row: any) => {
              if (activeTab == "initiated") {
                const isItemSelected = isSelected(row.id);
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        onClick={(event) => handleClick(event, row.id)}
                        role="checkbox"
                        checked={isItemSelected}
                      />
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.SRNO}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.BorrowerName}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.Contact_Person_Name}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {numberFormat(row.Loanamount)}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.Financer}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row?.Product}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {moment(row.dismonth).format("MMMM, YYYY")}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.Connectorname}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {parseFloat(row.Grosspayout).toFixed(2)}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {parseFloat(row.Tdspayout).toFixed(2)}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {parseFloat(row.Gstpayout).toFixed(2)}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {parseFloat(row.Netpayout).toFixed(2)}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.Paymentstatus == "Payment Pending" && <span className="text-sm text-[#DD2025] font-medium">Payment Pending</span>}
                      {row.Paymentstatus == "Billing Received" && <span className="text-sm text-[#FF9800] font-medium">Billing Received</span>}
                      {row.Paymentstatus == "Completed" && <span className="text-sm text-[#37B551] font-medium">Payment completed</span>}
                      {row.Paymentstatus == "Mismatch Case" && <span className="text-sm text-[#37B551] font-medium">Mismatch Case</span>}


                    </TableCell>

                    <TableCell align="center" component="th" scope="row">
                      {row.Advancerequest ? "true" : "false"}
                    </TableCell>


                    <TableCell align="center" component="th" scope="row">
                      {row.Advancestatus}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      <ul className="flex space-x-2 justify-center">
                        <li>
                          <Tooltip title="Action">
                            <button
                              onClick={(e) => {
                                if (row.Paymentstatus === "Payment Pending" || row.Paymentstatus === "Mismatch Case") {
                                  // Display toast notification
                                  if (row.Advancestatus == 'approved') {
                                    toast.error("Advance already approved!!!");

                                  }
                                  else {
                                    setSelectedId(row.id)
                                    setItem({ ...item, product: row?.Product, gross_payout: row?.Advancegross, bankname: row?.Financer, payoutpercentage: row?.rate })
                                    setOpen(true)
                                  }

                                } else {
                                  // Call handleEditClick for other cases
                                  toast.error("Cannot Perform Action!!!");
                                }
                              }}

                            ><img src="assets/money-gray.svg" alt="" /></button>
                          </Tooltip>
                        </li>
                      </ul>
                    </TableCell>

                    <TableCell align="center">
                      <button
                        style={{ color: 'blue', cursor: 'pointer', border: 'none', background: 'none' }}
                        onClick={() => {
                          if (row.Paymentstatus === "Payment Pending" || row.Paymentstatus === "Mismatch Case") {
                            if (row.Advancestatus == 'approved') {
                              toast.error("Cannot edit once advance  approved!!!");

                            }
                            else {
                              handleEditClick(row);
                            }
                            // Display toast notification

                          } else {
                            // Call handleEditClick for other cases
                            toast.error("Cannot Edit!!!");
                          }
                        }}
                      >
                        Edit
                      </button>
                    </TableCell>
                  </TableRow>
                )
              }
              if (activeTab == "l1l2") {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    <TableCell align="center" component="th" scope="row">
                      {row?.SRNO}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row?.borrower_name}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row?.user.name}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.month_of_disbursement ? moment(row.month_of_disbursement).format("MMMM-YYYY") : "-"}

                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row?.level_1?.user?.name ? row?.level_1?.user?.name : "-"}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row?.level_2?.user?.name ? row?.level_2?.user?.name : "-"}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row?.product?.product}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {numberFormat(row.loan_amount)}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row?.l1_up_expense ? row?.l1_up_expense : "-"}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row?.l2_up_expense ? row?.l2_up_expense : "-"}
                    </TableCell>
                  </TableRow>
                )
              }
              if (activeTab == "mismatch") {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    <TableCell align="center" component="th" scope="row">
                      {row.SRNO}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.bank_name}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.customer_name}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.lan_Number}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {numberFormat(row.loan_amount)}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.month_and_year}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.payout_in_percetage}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.product}
                    </TableCell>
                  </TableRow>
                )
              }
            }}
          />
          */}

            <DataTable
              ref={dt}
              value={disbursementDetail}
              lazy
              dataKey="id"
              showGridlines
              paginator={paginator}
              first={lazyState.first}
              filters={lazyState.filters}
              onFilter={onFilter}
              rows={lazyState.rows}
              rowsPerPageOptions={[10, 20, 30, 40, 50]}
              totalRecords={totalRecords}
              onPage={onPage}
              loading={loader}
              size={"small"}
              scrollable
              scrollHeight="600px"
              className="custom-datatable"
              header={header}
              emptyMessage="No records found."
              
            >
              <Column field="SRNO" header="SR.NO." />
              {permission[PERMISSION_TYPE.ADMIN] || permission[PERMISSION_TYPE.STAFF_OPS] ||
              permission[PERMISSION_TYPE.STAFF] ? (
                <Column
                  field="DsaName"
                  header="DSA Name"
                  showFilterMatchModes={false}
                  filterMenuStyle={{ minWidth: "14rem" }}
                  filter
                  filterElement={dsaNameFilterTemplate}
                />
              ) : null}
              <Column field="BorrowerName" header="Borrower Name" />
              <Column field="Contact_Person_Name" header="Contact Person" />
              <Column field="Loanamount" header="Loan Amount" />
              <Column
                field="Financer"
                header="Financer"
                showFilterMatchModes={false}
                filterMenuStyle={{ minWidth: "14rem" }}
                filter
                filterElement={financersFilterTemplate}
              />
              <Column
                field="Product"
                header="Product"
                showFilterMatchModes={false}
                filterMenuStyle={{ minWidth: "14rem" }}
                filter
                filterElement={productsFilterTemplate}
              />
              <Column
                field="dismonth"
                header="Disbursement Month"
                showFilterMatchModes={false}
                showApplyButton={false}
                showClearButton={false}
                filterMenuStyle={{ minWidth: "14rem" }}
                filter
                filterElement={DismonthFilterTemplate}
              />
              <Column field="Connectorname" header="Connector Name" />
              <Column field={permission[PERMISSION_TYPE.CONNECTOR] ? 'connector_rate': "Userpayout"} header="Payout in %" />
              {!permission[PERMISSION_TYPE.CONNECTOR] &&
              <Column field="connector_rate" header="Connector Payout %" />}
              {!permission[PERMISSION_TYPE.CONNECTOR] &&
              <Column field="adjustment_amount" header="Adjustment Amount" />}
              <Column field={permission[PERMISSION_TYPE.CONNECTOR] ? 'connector_gross_payout': "Grosspayout"} header="Gross Payout" />
              <Column  field={permission[PERMISSION_TYPE.CONNECTOR] ? 'connector_tds_amount': "Tdspayout"} header="TDS Payout" />
              <Column  field={permission[PERMISSION_TYPE.CONNECTOR] ? 'connector_gst_amount': "Gstpayout"} header="GST Payout" />
              <Column  field={permission[PERMISSION_TYPE.CONNECTOR] ? 'connector_net_amount_payable': "Netpayout"} header="Net Payout" />
              <Column
                field="Paymentstatus"
                header="Payment Status"
                showFilterMatchModes={false}
                filterMenuStyle={{ minWidth: "14rem" }}
                filter
                filterElement={paymentStatusesFilterTemplate}
              />
              <Column
                field="Advancerequest"
                header="Advance Request"
                showFilterMatchModes={false}
                filterMenuStyle={{ minWidth: "14rem" }}
                filter
                filterElement={advanceRequestFilterTemplate}
              />
              <Column
                field="Advancestatus"
                header="Advance Status"
                showFilterMatchModes={false}
                filterMenuStyle={{ minWidth: "14rem" }}
                filter
                filterElement={advanceStatusesFilterTemplate}
              />
              {/* {permission[PERMISSION_TYPE.ADMIN] ? ( */}
              {!permission[PERMISSION_TYPE.CONNECTOR] &&<Column
                field="Action"
                header="Action"
                body={actionBodyTemplate}
              />}
              {/* ) : null} */}
            </DataTable>
          </div>
          {/* </main> */}

          {/* Start::Add Disbursement Data Modal */}
          <div
            className={`tw-modal ${addOpen ? "flex" : "hidden"} !items-start`}
          >
            <div className="tw-modal-content">
              <div className="tw-modal-title">
                <div>Add Disbursement Data</div>
                <button
                  onClick={() => {
                    setAddOpen(false);
                    setConnector(false);
                  }}
                >
                  <img src="assets/close-outline.svg" alt="" />
                </button>
              </div>

              <div className="tw-modal-body">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-[22px]">
                  {permission[PERMISSION_TYPE.STAFF] && (
                    <div className="space-y-1">
                      <label htmlFor="connector" className={`input-label ${
                        formData.dsa_name_error ? "text-red-500" : ""
                      }`}>
                        Select DSA*
                      </label>
                      <div id="connector" className={`input-wrap ${formData.dsa_name_error ?  'border-red-500' : ''}`}>
                        <img src="assets/connector.svg" alt="" />
                        <select
                          name="connector"
                          value={formData.user_id}
                          onChange={(e) => {
                            getConnector(e.target.value)
                            setFormData({
                              ...formData,
                              user_id: e.target.value,
                              dsa_name_error:null
                              });
                            // getConnectorUser(userList[0].referral_code)
                          }}
                          className={`w-full border text-sm text-[#808080]`}
                        >
                          <option value="">Select</option>
                          {userList.map((y: any) => (
                            <option value={y._id}>
                              {y.first_name} {y.last_name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <p className="text-red-500 text-sm">
                      {formData.dsa_name_error}
                    </p>
                    </div>
                  )}
                 {permission[PERMISSION_TYPE.ADMIN] && (
                    <div className="space-y-1">
                      <label htmlFor="connector" className={`input-label ${
                        formData.dsa_name_error ? "text-red-500" : ""
                      }`}>
                        Select DSA*
                      </label>
                      <div id="connector" className={`input-wrap ${formData.dsa_name_error ?  'border-red-500' : ''}`}>
                        <img src="assets/connector.svg" alt="" />
                        <select
                          name="connector"
                          value={formData.user_id}
                          onChange={(e) => {
                            getConnector(e.target.value)
                            setFormData({
                              ...formData,
                              user_id: e.target.value,
                              dsa_name_error:null
                              });
                            // getConnectorUser(userList[0].referral_code)
                          }}
                          className={`w-full border text-sm text-[#808080]`}
                        >
                          <option value="">Select</option>
                          {userList.map((y: any) => (
                            <option value={y._id}>
                              {y.first_name} {y.last_name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <p className="text-red-500 text-sm">
                      {formData.dsa_name_error}
                    </p>
                    </div>
                  )}
                  {permission[PERMISSION_TYPE.STAFF_OPS] && (
                    <div className="space-y-1">
                      <label htmlFor="connector" className={`input-label ${
                        formData.dsa_name_error ? "text-red-500" : ""
                      }`}>
                        Select DSA*
                      </label>
                      <div id="connector" className={`input-wrap ${formData.dsa_name_error ?  'border-red-500' : ''}`}>
                        <img src="assets/connector.svg" alt="" />
                        <select
                          name="connector"
                          value={formData.user_id}
                          onChange={(e) => {
                            getConnector(e.target.value)
                            setFormData({
                              ...formData,
                              user_id: e.target.value,
                              dsa_name_error:null
                              });
                            // getConnectorUser(userList[0].referral_code)
                          }}
                          className={`w-full border text-sm text-[#808080]`}
                        >
                          <option value="">Select</option>
                          {userList.map((y: any) => (
                            <option value={y._id}>
                              {y.first_name} {y.last_name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <p className="text-red-500 text-sm">
                      {formData.dsa_name_error}
                    </p>
                    </div>
                  )}
                  <div className="space-y-1">
                    <label
                      htmlFor="borrower_name"
                      className={`input-label ${
                        formData.borrower_name_error ? "text-red-500" : ""
                      }`}
                    >
                      Name of Borrower*
                    </label>
                    <div
                      id="borrower_name"
                      className={`input-wrap ${
                        formData.borrower_name_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/user.svg" alt="" />
                      <input
                        type="text"
                        name="borrower_name"
                        value={formData.borrower_name}
                        onInput={handleBorrowerNameInput}
                        onChange={(e: any) => {
                          setFormData({
                            ...formData,
                            borrower_name: e.target.value,
                            borrower_name_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Name of Borrower"
                      />
                    </div>
                    <p className="text-red-500 text-sm">
                      {formData.borrower_name_error}
                    </p>
                  </div>
                  <div className="space-y-1">
                    <label htmlFor="lan_no" className="input-label">
                      LAN No.
                    </label>
                    <div id="lan_no" className="input-wrap">
                      <img src="assets/firm.svg" alt="" />
                      <input
                        type="text"
                        name="lan_no"
                        value={formData.lan_no}
                        onChange={(e: any) => {
                          setFormData({ ...formData, lan_no: e.target.value });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter LAN No."
                      />
                    </div>
                  </div>
                  <div className="space-y-1">
                    <label
                      htmlFor="contact_person_name"
                      className="input-label"
                    >
                      Contact Person Name
                    </label>
                    <div id="contact_person_name" className="input-wrap ">
                      <img src="assets/firm.svg" alt="" />
                      <input
                        type="text"
                        name="contact_person_name"
                        onInput={handleNameInput}
                        value={formData.borrower_contact_person_name}
                        onChange={(e: any) => {
                          setFormData({
                            ...formData,
                            borrower_contact_person_name: e.target.value,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Contact Person Name"
                      />
                    </div>
                  </div>
                  {permission[PERMISSION_TYPE.STAFF] && (
                    <div className="space-y-1">
                      <label
                        htmlFor="adjustment_amount"
                        className="input-label"
                      >
                        Adjustment Amount
                      </label>
                      <div id="adjustment_amount" className="input-wrap ">
                        <img src="assets/firm.svg" alt="" />
                        <input
                          type="text"
                          name="adjustment_amount"
                          onInput={handleAdjustmentAmountInput}
                          value={formData.adjustment_amount}
                          onChange={(e: any) => {
                            setFormData({
                              ...formData,
                              adjustment_amount: e.target.value,
                            });
                          }}
                          className="w-full text-sm"
                          placeholder="Enter Adjusment Amount"
                        />
                      </div>
                    </div>
                  )}
                  {(permission[PERMISSION_TYPE.ADMIN] || permission[PERMISSION_TYPE.STAFF_OPS]) && (
                    <div className="space-y-1">
                      <label
                        htmlFor="adjustment_amount"
                        className="input-label "
                      >
                        Adjustment Amount
                      </label>
                      <div id="adjustment_amount" className="input-wrap ">
                        <img src="assets/firm.svg" alt="" />
                        <input
                          type="text"
                          name="contaadjustment_amountct_person_name"
                          onInput={handleAdjustmentAmountInput}
                          value={formData.adjustment_amount}
                          onChange={(e: any) => {
                            setFormData({
                              ...formData,
                              adjustment_amount: e.target.value,
                            });
                          }}
                          className="w-full text-sm"
                          placeholder="Enter Adjusment Amount"
                        />
                      </div>
                    </div>
                  )}
                  {permission[PERMISSION_TYPE.STAFF] && (
                    <div className="space-y-1">
                      <label htmlFor="reason" className={`input-label ${
                        formData.reason_error ? "text-red-500" : ""
                      }`}>
                        Reason
                      </label>
                      <div id="reason" className={`input-wrap ${
                        formData.reason_error ? "border-red-500" : ""
                      }`}>
                        <img src="assets/firm.svg" alt="" />
                        <input
                          type="text"
                          name="reason"
                          value={formData.reason}
                          onChange={(e: any) => {
                            setFormData({
                              ...formData,
                              reason: e.target.value,
                              reason_error: null,
                            });
                          }}
                          className="w-full text-sm"
                          placeholder=" Enter Reason"
                        />
                      </div>
                      <p className="text-red-500 text-sm">
                        {formData.reason_error}
                      </p>
                    </div>
                  )}
                  {(permission[PERMISSION_TYPE.ADMIN] || permission[PERMISSION_TYPE.STAFF_OPS]) && (
                    <div className="space-y-1">
                      <label htmlFor="reason" className={`input-label ${
                        formData.reason_error ? "text-red-500" : ""
                      }`}>
                        Reason
                      </label>
                      <div id="reason" className={`input-wrap ${
                        formData.reason_error ? "border-red-500" : ""
                      }`}>
                        <img src="assets/firm.svg" alt="" />
                        <input
                          type="text"
                          name="reason"
                          value={formData.reason}
                          onChange={(e: any) => {
                            setFormData({
                              ...formData,
                              reason: e.target.value,
                              reason_error: null,
                            });
                          }}
                          className="w-full text-sm"
                          placeholder="Enter Reason"
                        />
                      </div>
                      <p className="text-red-500 text-sm">
                        {formData.reason_error}
                      </p>
                    </div>
                  )}
                  {/* <div className="space-y-1">
                  <label htmlFor="lan_no" className={`input-label ${formData.pan_no_error ? "text-red-500" : ""}`}>Pan No.</label>
                  <div id="pan_no" className={`input-wrap ${formData.pan_no_error ? "border-red-500" : ""}`}>
                    <img src="assets/firm.svg" alt="" />
                    <input type="text" name="lan_no" value={formData.pan_no} onChange={(e: any) => {
                      setFormData({ ...formData, pan_no: e.target.value, pan_no_error: null });
                    }} className="w-full text-sm" placeholder="Enter LAN No." />
                  </div>
                  <p className="text-red-500 text-sm">{formData.pan_no_error}</p>
                </div> */}
                  <div className="space-y-1">
                    <label
                      htmlFor="loan_amount"
                      className={`input-label ${
                        formData.loan_amount_error ? "text-red-500" : ""
                      }`}
                    >
                      Loan Amount*
                    </label>
                    <div
                      id="loan_amount"
                      className={`input-wrap ${
                        formData.loan_amount_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/email.svg" alt="" />
                      <input
                        type="text"
                        name="loan_amount"
                        onInput={handleNumericInput}
                        value={formData.loan_amount}
                        onChange={(e: any) => {
                          setFormData({
                            ...formData,
                            loan_amount: e.target.value,
                            loan_amount_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Loan Amount"
                      />
                    </div>
                    <p className="text-red-500 text-sm">
                      {formData.loan_amount_error}
                    </p>
                  </div>
                  <div className="space-y-1">
                    <label
                      htmlFor="borrower_pincode"
                      className={`input-label ${
                        formData.pincode_error ? "text-red-500" : ""
                      }`}
                    >
                      Borrower’s City Pincode*
                    </label>
                    <div
                      id="borrower_pincode"
                      className={`input-wrap ${
                        formData.pincode_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/location.svg" alt="" />
                      <input
                        type="text"
                        name="borrower_pincode"
                        onInput={handleNumericInput}
                        maxLength={6}
                        value={formData.borrower_pincode}
                        onChange={(e: any) => {
                          postget(e);
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Borrower’s City Pincode"
                      />
                    </div>
                    <p className="text-red-500 text-sm">
                      {formData.pincode_error}
                    </p>
                  </div>

                  <div className="space-y-1">
                    <label
                      htmlFor="financer_name"
                      className={`input-label ${
                        formData.financer_name_error ? "text-red-500" : ""
                      }`}
                    >
                      Financer Name*
                    </label>
                    <div
                      id="financer_name"
                      className={`input-wrap ${
                        formData.financer_name_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/bank.svg" alt="" />
                      <select
                        name="financer_name"
                        value={formData.financer_name}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            financer_name: e.target.value,
                            financer_name_error: null,
                            logincodelist_id: "",
                          })
                        }
                        className="w-full text-sm text-[#808080]"
                      >
                        <option value="" selected>
                          Select Bank/NBFC Name
                        </option>
                        {Object.keys(bankData)?.map((bank: any, id: any) => (
                          <option value={bank}>{bank}</option>
                        ))}
                      </select>
                    </div>
                    <p className="text-red-500 text-sm">
                      {formData.financer_name_error}
                    </p>
                  </div>
                  <div className="space-y-1">
                    <label htmlFor="city" className="input-label">
                      City
                    </label>
                    <div id="city" className="input-wrap">
                      <img src="assets/location.svg" alt="" />
                      <input
                        type="text"
                        name="city"
                        value={formData.city}
                        onChange={(e: any) => {
                          setFormData({ ...formData, city: e.target.value });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter City"
                        disabled
                      />
                      {/* }} className="w-full text-sm" placeholder="Enter City" disabled /> */}
                    </div>
                  </div>
                  <div className="space-y-1">
                    <label htmlFor="state" className="input-label">
                      State
                    </label>
                    <div id="state" className="input-wrap">
                      <img src="assets/location.svg" alt="" />
                      <input
                        type="text"
                        name="state"
                        value={formData.state}
                        onChange={(e: any) => {
                          setFormData({ ...formData, state: e.target.value });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter State"
                        disabled
                      />
                      {/* }} className="w-full text-sm" placeholder="Enter State" disabled /> */}
                    </div>
                  </div>
                  <div className="space-y-1">
                    <label
                      htmlFor="product"
                      className={`input-label ${
                        formData.product_error ? "text-red-500" : ""
                      }`}
                    >
                      Product*
                    </label>
                    <div
                      id="product"
                      className={`input-wrap ${
                        formData.product_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/product.svg" alt="" />
                      <select
                        name="product"
                        value={formData.logincodelist_id}
                        onChange={(e) =>{
                          setFormData({
                            ...formData,
                            logincodelist_id: e.target.value,
                            product_error: null,
                          })
                        }
                        }
                        className="w-full text-sm text-[#808080]"
                      >
                        <option value="" selected>
                          Select Product
                        </option>
                        {bankData?.[formData.financer_name]?.map(
                          (item: any) => (
                            <option value={item.id}>{item.product}</option>
                          )
                        )}
                      </select>
                    </div>
                    <p className="text-red-500 text-sm">
                      {formData.product_error}
                    </p>
                  </div>
                  <div className="space-y-1">
                    <label
                      htmlFor="month_of_disbursement"
                      className={`input-label ${
                        formData.month_of_disbursement_error
                          ? "text-red-500"
                          : ""
                      }`}
                    >
                      Month of Disbursement*
                    </label>
                    <div
                      id="month_of_disbursement"
                      className={`input-wrap ${
                        formData.month_of_disbursement_error
                          ? "border-red-500"
                          : ""
                      }`}
                    >
                      <img src="assets/date.svg" alt="" />
                      <DatePicker
                        selected={formData.disbursement_date}
                        onChange={(date: any) =>
                          setFormData({
                            ...formData,
                            disbursement_date: date,
                            month_of_disbursement_error: null,
                          })
                        }
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        showFullMonthYearPicker
                      />
                    </div>
                    <p className="text-red-500 text-sm">
                      {formData.month_of_disbursement_error}
                    </p>
                  </div>
                  <div className="space-y-1">
                    <br className="hidden sm:block" />
                    <label className="input-label">
                      Whether case sourced by Connector?
                    </label>
                    <div className="flex space-x-4">
                      <div className="flex items-center space-x-1">
                      <RadioButton id="yesConnector" name="pizza" value="yes" onChange={() => {
                            setConnector(true);
                            if(permission[PERMISSION_TYPE.USER]){
                              getConnector(userData._id)
                            }
                            setFormData({
                              ...formData,
                              is_connector: true,
                              connector_error: null,
                            });} }checked={formData.is_connector === true} />
                        {/* <input
                          type="radio"
                          id="yesConnector"
                          value="yes"
                          onChange={() => {
                            setFormData({
                              ...formData,
                              is_connector: true,
                              connector_error: null,
                            });
                            setConnector(true);
                          }}
                          name="yesConnector"
                        /> */}
                        <label htmlFor="yesConnector">Yes</label>
                      </div>
                      <div className="flex items-center space-x-1">
                      <RadioButton inputId="ingredient1" name="pizza" value="no" onChange={() => {
                            setConnector(false);
                            setFormData({
                              ...formData,
                              is_connector: false,
                              connector_error: null,
                            });
                          }} checked={formData.is_connector === false} />
                        {/* <input
                          type="radio"
                          id="noConnector"
                          value="no"
                          name="yesConnector"
                          onChange={() => {
                            setConnector(false);
                            setFormData({
                              ...formData,
                              is_connector: false,
                              connector_error: null,
                            });
                          }}
                          defaultChecked
                        /> */}
                        <label htmlFor="noConnector">No</label>
                      </div>
                    </div>
                  </div>
                  <div className="space-y-1">
                    <label
                      htmlFor="mobile"
                      className={`input-label ${
                        formData.mobile_error ? "text-red-500" : ""
                      }`}
                    >
                      Mobile No.
                    </label>
                    <div
                      id="mobile"
                      className={`input-wrap ${
                        formData.mobile_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/phone.svg" alt="" />
                      <input
                        type="text"
                        name="mobile"
                        maxLength={13}
                        value={formData.borrower_contact_person_number}
                        onChange={(e: any) => {
                          setFormData({
                            ...formData,
                            borrower_contact_person_number: e.target.value,
                            mobile_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Mobile No."
                      />
                    </div>
                    {/* <p className="text-red-500 text-sm">{formData.mobile_error}</p> */}
                  </div>
                  <div className={`space-y-1 ${formData.is_connector ? "" : "hidden"}`}>
                    <label
                      htmlFor="connector"
                      className={`input-label ${
                        formData.connector_error ? "text-red-500" : ""
                      }`}
                    >
                      Connector
                    </label>
                    <div
                      id="connector"
                      className={`input-wrap ${
                        formData.connector_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/connector.svg" alt="" />
                      <select
                        name="connector"
                        value={formData.connector_id}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            connector_id: e.target.value,
                            connector_error: null,
                          })
                        }
                        disabled={!isconnector}
                        className="w-full text-sm text-[#808080]"
                      >
                        <option value="">Select</option>
                        {connectorList.map((y: any) => (
                          <option value={y.id}>{y.label}</option>
                        ))}
                      </select>
                    </div>
                    <p className="text-red-500 text-sm">
                      {formData.connector_error}
                    </p>
                  </div>
                  <div className={`space-y-1 ${formData.is_connector ? "" : "hidden"}`}>
                    <label
                      htmlFor="connector"
                      className={`input-label ${
                        formData.connector_error ? "text-red-500" : ""
                      }`}
                    >
                      Connector Rate
                    </label>
                    <div
                      id="connector"
                      className={`input-wrap ${
                        formData.connector_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/connector.svg" alt="" />

                      <input
                        type="text"
                        name="state"
                        value={formData.connector_rate}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            connector_rate: e.target.value,
                            connector_error: null,
                          })
                        }
                        disabled={!isconnector}
                        className="w-full text-sm text-[#808080]"
                        placeholder="Enter Connector Rate"
                      />
                    </div>
                    <p className="text-red-500 text-sm">
                      {formData.connector_error}
                    </p>
                  </div>
                </div>
              </div>

              <div className="tw-modal-footer">
                <button
                  className="tw-modal-footer-button bg-secondary text-white"
                  onClick={addDisbursement}
                >
                  Submit
                </button>
                <button
                  className="tw-modal-footer-button bg-secondary bg-opacity-10 text-secondary"
                  onClick={() => {
                    setAddOpen(false);
                    setFormData(disbursementData);
                    setConnector(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>

          <div className={`tw-modal ${open ? "flex" : "hidden"} !items-center`}>
            <div className="tw-modal-content !max-w-[500px]">
              <div className="tw-modal-title py-6 justify-end">
                <button onClick={() => setOpen(false)}>
                  <img src="assets/close-outline.svg" alt="" />
                </button>
              </div>
              <div className="tw-modal-body leading-[24px] py-8">
                <span className="font-medium text-black">Please Note : </span>
                Advance payout grid for {item.bankname} & {item.product} is{" "}
                {item.payoutpercentage - 0.25}% .Your Gross Payout will be Rs.{" "}
                {item.gross_payout}. You cannot opt for NORMAL GRID after
                requesting advance. Please confirm?
              </div>

              <div className="tw-modal-footer">
                <button
                  className="tw-modal-footer-button bg-secondary bg-opacity-10 text-secondary"
                  onClick={() => setOpen(false)}
                >
                  No
                </button>
                <button
                  className="tw-modal-footer-button bg-secondary text-white"
                  onClick={(e) => {
                    actionYes();
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
          {/* End::Add Disbursement Data Modal */}
          <div
            className={`tw-modal ${editOpen ? "flex" : "hidden"} !items-start`}
          >
            <div className="tw-modal-content">
              <div className="tw-modal-title">
                <div>Edit Disbursement Data</div>
                <button
                  onClick={() => {
                    setEditOpen(false);
                    setDisableField(false);
                    setDisableEdit(false);
                    setFormData(disbursementData);
                    setConnector(false);
                  }}
                >
                  <img src="assets/close-outline.svg" alt="" />
                </button>
              </div>

              <div className="tw-modal-body">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-[22px]">
                  <div className="space-y-1">
                    <label
                      htmlFor="borrower_name"
                      className={`input-label ${
                        formData.borrower_name_error ? "text-red-500" : ""
                      }`}
                    >
                      Name of Borrower*
                    </label>
                    <div
                      id="borrower_name"
                      className={`input-wrap ${
                        formData.borrower_name_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/user.svg" alt="" />
                      <input
                        type="text"
                        name="borrower_name"
                        value={formData.borrower_name}
                        onInput={handleBorrowerNameInput}
                        onChange={(e: any) => {
                          setFormData({
                            ...formData,
                            borrower_name: e.target.value,
                            borrower_name_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Name of Borrower"
                        disabled={disableEdit}
                      />
                    </div>
                    <p className="text-red-500 text-sm">
                      {formData.borrower_name_error}
                    </p>
                  </div>
                  <div className="space-y-1">
                    <label
                      htmlFor="lan_no"
                      className={`input-label ${
                        formData.lan_no_error ? "text-red-500" : ""
                      }`}
                    >
                      LAN No.
                    </label>
                    <div
                      id="lan_no"
                      className={`input-wrap ${
                        formData.lan_no_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/firm.svg" alt="" />
                      <input
                        type="text"
                        name="lan_no"
                        value={formData.lan_no}
                        onChange={(e: any) => {
                          setFormData({
                            ...formData,
                            lan_no: e.target.value,
                            lan_no_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter LAN No."
                        disabled={disableEdit}
                      />
                    </div>
                    <p className="text-red-500 text-sm">
                      {formData.lan_no_error}
                    </p>
                  </div>
                  <div className="space-y-1">
                    <label
                      htmlFor="contact_person_name"
                      className={`input-label ${
                        formData.contact_person_name_error ? "text-red-500" : ""
                      }`}
                    >
                      Contact Person Name
                    </label>
                    <div
                      id="contact_person_name"
                      className={`input-wrap ${
                        formData.contact_person_name_error
                          ? "border-red-500"
                          : ""
                      }`}
                    >
                      <img src="assets/firm.svg" alt="" />
                      <input
                        type="text"
                        name="contact_person_name"
                        onInput={handleNameInput}
                        value={formData.borrower_contact_person_name}
                        onChange={(e: any) => {
                          setFormData({
                            ...formData,
                            borrower_contact_person_name: e.target.value,
                            contact_person_name_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Contact Person Name"
                        disabled={disableEdit}
                      />
                    </div>
                    <p className="text-red-500 text-sm">
                      {formData.contact_person_name_error}
                    </p>
                  </div>

                  <div className="space-y-1">
                    <label
                      htmlFor="loan_amount"
                      className={`input-label ${
                        formData.loan_amount_error ? "text-red-500" : ""
                      }`}
                    >
                      Loan Amount*
                    </label>
                    <div
                      id="loan_amount"
                      className={`input-wrap ${
                        formData.loan_amount_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/email.svg" alt="" />
                      <input
                        type="text"
                        name="loan_amount"
                        value={formData.loan_amount}
                        onInput={handleNumericInput}
                        onChange={(e: any) => {
                          setFormData({
                            ...formData,
                            loan_amount: e.target.value,
                            loan_amount_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Loan Amount"
                        disabled={disableField || disableEdit}
                      />
                    </div>
                    <p className="text-red-500 text-sm">
                      {formData.loan_amount_error}
                    </p>
                  </div>

                  {permission[PERMISSION_TYPE.STAFF] && (
                    <div className="space-y-1">
                      <label
                        htmlFor="adjustment_amount"
                        className="input-label"
                      >
                        Adjustment Amount
                      </label>
                      <div id="adjustment_amount" className="input-wrap">
                        <img src="assets/firm.svg" alt="" />
                        <input
                          type="text"
                          name="adjustment_amount"
                          onInput={handleAdjustmentAmountInput}
                          value={formData.adjustment_amount}
                          onChange={(e: any) => {
                            setFormData({
                              ...formData,
                              adjustment_amount: e.target.value,
                            });
                          }}
                          className="w-full text-sm"
                          placeholder="Adjusment Rate"
                          disabled={disableEdit}
                        />
                      </div>
                    </div>
                  )}
                  {(permission[PERMISSION_TYPE.ADMIN] || permission[PERMISSION_TYPE.STAFF_OPS]) && (
                    <div className="space-y-1">
                      <label
                        htmlFor="adjustment_amount"
                        className="input-label"
                      >
                        Adjustment Amount
                      </label>
                      <div id="adjustment_amount" className="input-wrap">
                        <img src="assets/firm.svg" alt="" />
                        <input
                          type="text"
                          name="adjustment_amount"
                          onInput={handleAdjustmentAmountInput}
                          value={formData.adjustment_amount}
                          onChange={(e: any) => {
                            setFormData({
                              ...formData,
                              adjustment_amount: e.target.value,
                            });
                          }}
                          className="w-full text-sm"
                          placeholder="Adjusment Rate"
                          disabled={disableEdit}
                        />
                      </div>
                    </div>
                  )}
                  {permission[PERMISSION_TYPE.STAFF] && (
                    <div className="space-y-1">
                      <label htmlFor="reason" className={`input-label ${
                        formData.reason_error ? "text-red-500" : ""
                      }`}>
                        Reason
                      </label>
                      <div id="reason" className={`input-wrap ${
                        formData.reason_error ? "border-red-500" : ""
                      }`}>
                        <img src="assets/firm.svg" alt="" />
                        <input
                          type="text"
                          name="reason"
                          value={formData.reason}
                          onChange={(e: any) => {
                            setFormData({
                              ...formData,
                              reason: e.target.value,
                              reason_error:null
                            });
                          }}
                          className="w-full text-sm"
                          placeholder="Reason"
                          disabled={disableEdit}
                        />
                      </div>
                      <p className="text-red-500 text-sm">
                      {formData.reason_error}
                    </p>
                    </div>
                  )}
                  {(permission[PERMISSION_TYPE.ADMIN] || permission[PERMISSION_TYPE.STAFF_OPS]) && (
                    <div className="space-y-1">
                      <label htmlFor="reason" className={`input-label ${
                        formData.reason_error ? "text-red-500" : ""
                      }`}>
                        Reason
                      </label>
                      <div id="reason" className={`input-wrap ${
                        formData.reason_error ? "border-red-500" : ""
                      }`}>
                        <img src="assets/firm.svg" alt="" />
                        <input
                          type="text"
                          name="reason"
                          value={formData.reason}
                          onChange={(e: any) => {
                            setFormData({
                              ...formData,
                              reason: e.target.value,
                              reason_error:null
                            });
                          }}
                          className="w-full text-sm"
                          placeholder="Reason"
                          disabled={disableEdit}
                        />
                      </div>
                      <p className="text-red-500 text-sm">
                      {formData.reason_error}
                    </p>
                    </div>
                  )}
                  <div className="space-y-1">
                    <label
                      htmlFor="borrower_pincode"
                      className={`input-label ${
                        formData.pincode_error ? "text-red-500" : ""
                      }`}
                    >
                      Borrower’s City Pincode*
                    </label>
                    <div
                      id="borrower_pincode"
                      className={`input-wrap ${
                        formData.pincode_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/location.svg" alt="" />
                      <input
                        type="text"
                        name="borrower_pincode"
                        onInput={handleNumericInput}
                        maxLength={6}
                        value={formData.borrower_pincode}
                        onChange={(e: any) => {
                          postget(e);
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Borrower’s City Pincode"
                        disabled={disableEdit}
                      />
                    </div>
                    <p className="text-red-500 text-sm">
                      {formData.pincode_error}
                    </p>
                  </div>

                  <div className="space-y-1">
                    <label
                      htmlFor="financer_name"
                      className={`input-label ${
                        formData.financer_name_error ? "text-red-500" : ""
                      }`}
                    >
                      Financer Name*
                    </label>
                    <div
                      id="financer_name"
                      className={`input-wrap ${
                        formData.financer_name_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/bank.svg" alt="" />
                      <select
                        name="financer_name"
                        value={formData.financer_name}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            financer_name: e.target.value,
                            financer_name_error: null,
                            logincodelist_id: "",
                          })
                        }
                        className="w-full text-sm text-[#808080]"
                        disabled={disableField || disableEdit}
                      >
                        <option value="" selected>
                          Select Bank/NBFC Name
                        </option>
                        {Object.keys(bankData)?.map((bank: any, id: any) => (
                          <option value={bank}>{bank}</option>
                        ))}
                      </select>
                    </div>
                    <p className="text-red-500 text-sm">
                      {formData.financer_name_error}
                    </p>
                  </div>
                  <div className="space-y-1">
                    <label htmlFor="pincode" className="input-label">
                      City
                    </label>
                    <div id="pincode" className="input-wrap">
                      <img src="assets/location.svg" alt="" />
                      <input
                        type="text"
                        name="pincode"
                        value={formData.city}
                        onChange={(e: any) => {
                          setFormData({ ...formData, city: e.target.value });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter City"
                        disabled
                      />
                    </div>
                  </div>
                  <div className="space-y-1">
                    <label htmlFor="pincode" className="input-label">
                      State
                    </label>
                    <div id="pincode" className="input-wrap">
                      <img src="assets/location.svg" alt="" />
                      <input
                        type="text"
                        name="pincode"
                        value={formData.state}
                        onChange={(e: any) => {
                          setFormData({ ...formData, state: e.target.value });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter State"
                        disabled
                      />
                    </div>
                  </div>
                  <div className="space-y-1">
                    <label
                      htmlFor="product"
                      className={`input-label ${
                        formData.product_error ? "text-red-500" : ""
                      }`}
                    >
                      Product*
                    </label>
                    <div
                      id="product"
                      className={`input-wrap ${
                        formData.product_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/product.svg" alt="" />
                      <select
                        name="product"
                        value={formData.logincodelist_id}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            logincodelist_id: e.target.value,
                            product_error: null,
                          })
                        }
                        className="w-full text-sm text-[#808080]"
                        disabled={disableField || disableEdit}
                      >
                        <option value="" selected>
                          Select Product
                        </option>
                        {bankData?.[formData.financer_name]?.map(
                          (item: any) => (
                            <option value={item.id}>{item.product}</option>
                          )
                        )}
                      </select>
                    </div>
                    <p className="text-red-500 text-sm">
                      {formData.product_error}
                    </p>
                  </div>

                  <div className="space-y-1">
                    <label
                      htmlFor="month_of_disbursement"
                      className={`input-label ${
                        formData.month_of_disbursement_error
                          ? "text-red-500"
                          : ""
                      }`}
                    >
                      Month of Disbursement*
                    </label>
                    <div
                      id="month_of_disbursement"
                      className={`input-wrap ${
                        formData.month_of_disbursement_error
                          ? "border-red-500"
                          : ""
                      }`}
                    >
                      <img src="assets/date.svg" alt="" />
                      <DatePicker
                        selected={formData.disbursement_date}
                        onChange={(date: any) =>{
                          setFormData({
                            ...formData,
                            disbursement_date: date,
                            month_of_disbursement_error: null,
                          })}
                        }
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        showFullMonthYearPicker
                        disabled={disableField || disableEdit}
                      />
                    </div>
                    <p className="text-red-500 text-sm">
                      {formData.month_of_disbursement_error}
                    </p>
                  </div>
                  <div className="space-y-1">
                    <br className="hidden sm:block" />
                    <label className="input-label">
                      Whether case sourced by Connector?
                    </label>
                    <div className="flex space-x-4">
                      <div className="flex items-center space-x-1">
                        {/* <input
                          type="radio"
                          id="yesConnector"
                          value="yes"
                          onChange={() => {
                            setFormData({
                              ...formData,
                              is_connector: true,
                              connector_error: null,
                            });
                            setConnector(true);
                          }}
                          name="yesConnector"
                          disabled={disableEdit}
                        /> */}
                        <RadioButton id="yesConnector" value="yes" onChange={() => {
                            setConnector(true);
                            setFormData({
                              ...formData,
                              is_connector: true,
                              connector_error: null,
                            });} }checked={formData.is_connector === true}  disabled={disableEdit}/>
                        <label htmlFor="yesConnector">Yes</label>
                      </div>
                      <div className="flex items-center space-x-1">
                      <RadioButton inputId="ingredient1"  value="no" onChange={() => {
                            setConnector(false);
                            

                            {(formData.status === 'Payment Pending' && formData.advanceRequests === false) || formData.status === 'Mismatch Case' 
                              ? setFormData({
                                ...formData,
                                is_connector: false,
                                connector_error: null,                              
                                connector_rate: null,
                              connector_id:null,
                              })
                              :
                              setFormData({
                                ...formData,
                                is_connector: false,
                                connector_error: null,
                              });
                            }
                            
                          }} checked={formData.is_connector === false} disabled={disableEdit} />
                        {/* <input
                          type="radio"
                          id="noConnector"
                          value="no"
                          name="yesConnector"
                          onChange={() => {
                            setConnector(false);
                            setFormData({
                              ...formData,
                              is_connector: false,
                              connector_error: null,
                            });
                          }}
                          disabled={disableEdit}
                        /> */}
                        <label htmlFor="noConnector">No</label>
                      </div>
                    </div>
                  </div>
                  <div className="space-y-1">
                    <label
                      htmlFor="mobile"
                      className={`input-label ${
                        formData.mobile_error ? "text-red-500" : ""
                      }`}
                    >
                      Mobile No.
                    </label>
                    <div
                      id="mobile"
                      className={`input-wrap ${
                        formData.mobile_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/phone.svg" alt="" />
                      <input
                        type="text"
                        name="mobile"
                        maxLength={13}
                        disabled={disableEdit}
                        value={formData.borrower_contact_person_number}
                        onChange={(e: any) => {
                          setFormData({
                            ...formData,
                            borrower_contact_person_number: e.target.value,
                            mobile_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Mobile No."
                      />
                    </div>
                    {/* <p className="text-red-500 text-sm">{formData.mobile_error}</p> */}
                  </div>
                  <div className={`space-y-1 ${formData.is_connector ? "" : "hidden"}`}>
                    <label
                      htmlFor="connector"
                      className={`input-label ${
                        formData.connector_error ? "text-red-500" : ""
                      }`}
                    >
                      Connector
                    </label>
                    <div
                      id="connector"
                      className={`input-wrap ${
                        formData.connector_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/connector.svg" alt="" />
                      <select
                        name="connector"
                        value={formData.connector_id}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            connector_id: e.target.value,
                            connector_error: null,
                          })
                        }
                        disabled={ disableEdit }
                        className="w-full text-sm text-[#808080]"
                      >
                        <option value="">Select</option>
                        {connectorList.map((y: any) => (
                          <option value={y.id}>{y.label}</option>
                        ))}
                      </select>
                    </div>
                    <p className="text-red-500 text-sm">
                      {formData.connector_error}
                    </p>
                  </div>
                  <div className={`space-y-1 ${formData.is_connector ? "" : "hidden"}`}>
                    <label
                      htmlFor="connector"
                      className={`input-label ${
                        formData.connector_error ? "text-red-500" : ""
                      }`}
                    >
                      Connector Rate
                    </label>
                    <div
                      id="connector"
                      className={`input-wrap ${
                        formData.connector_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/connector.svg" alt="" />
                      <input
                        type="text"
                        name="state"
                        value={formData.connector_rate}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            connector_rate: e.target.value,
                            connector_error: null,
                          })
                        }
                        // max={formData.rate}
                        disabled={disableEdit }
                        className="w-full text-sm text-[#808080]"
                      />
                    </div>
                    <p className="text-red-500 text-sm">
                      {formData.connector_error}
                    </p>
                  </div>
                </div>
              </div>

              <div className="tw-modal-footer">
                <button
                  className="tw-modal-footer-button bg-secondary text-white"
                  onClick={editDisbursement}
                  disabled={disableEdit}
                >
                  Submit
                </button>
                <button
                  className="tw-modal-footer-button bg-secondary bg-opacity-10 text-secondary"
                  onClick={() => {
                    setEditOpen(false);
                    setDisableField(false);
                    setDisableEdit(false);
                    setFormData(disbursementData);
                    setConnector(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
          <div
            className={`tw-modal ${approvePayment ? "flex" : "hidden"} !items-start`}
          >
            <div className="tw-modal-content">
              <div className="tw-modal-title">
                <div>Add Payment Data</div>
                <button
                  onClick={() => {
                    setApprovePayment(false);
                    setPaymentForm(data)
                  }}
                >
                  <img src="assets/close-outline.svg" alt="" />
                </button>
              </div>

              <div className="tw-modal-body">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-[22px]">
                {/* <div className="space-y-1">
                                        <label htmlFor="selectedMonth" className={`input-label ${paymentForm.month_error ? "text-red-500" : ""}`}>Month*</label>
                                        <div id="selectedMonth" className={`${paymentForm.month_error ? "border-red-500" : ""}`}>
                                            <Select
                                                key={paymentForm.month}
                                                options={months}
                                                value={months.find((month) => month.value === paymentForm.month)}
                                                onChange={handleMonthChange}
                                                placeholder="Select Month"
                                                styles={customStyles(paymentForm.month_error)}
                                            />
                                        </div>
                                        <p className="text-red-500 text-sm">{paymentForm.month_error}</p>
                                    </div>
                                    <div className="space-y-1">
                                        <label htmlFor="selectedYear" className={`input-label ${paymentForm.year_error ? "text-red-500" : ""}`}>Year*</label>
                                        <div id="selectedYear" className={`${paymentForm.year_error ? "border-red-500" : ""}`}>
                                            <Select
                                                key={paymentForm.year}
                                                options={years}
                                                value={years.find((year) => year.value === paymentForm.year)}
                                                onChange={handleYearChange}
                                                placeholder="Select Year"
                                                styles={customStyles(paymentForm.year_error)}
                                            />
                                        </div>
                                        <p className="text-red-500 text-sm">{paymentForm.year_error}</p>
                                    </div> */}
                    <div className="space-y-1">
                      <label htmlFor="file_sequence" className={`input-label ${
                        paymentForm.file_sequence_num_error ? "text-red-500" : ""
                      }`}>
                        File Sequence No.*
                      </label>
                      <div
                      id="file_sequence"
                      className={`input-wrap ${
                        paymentForm.file_sequence_num_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/user.svg" alt="" />
                      <input
                        type="text"
                        name="file_sequence"
                        value={paymentForm.file_sequence_num}
                        onChange={(e: any) => {
                          setPaymentForm({
                            ...paymentForm,
                            file_sequence_num: e.target.value,
                            file_sequence_num_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter File sequence no."
                      />
                    </div>
                      <p className="text-red-500 text-sm">
                      {paymentForm.file_sequence_num_error}
                    </p>
                    </div>
                    {/* <div className="space-y-1">
                      <label htmlFor="pymt_prod_type_code" className={`input-label ${
                        paymentForm.pymt_prod_type_code_error ? "text-red-500" : ""
                      }`}>
                        Payment Prod Type code*
                      </label>
                      <div
                      id="pymt_prod_type_code"
                      className={`input-wrap ${
                        paymentForm.pymt_prod_type_code_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/user.svg" alt="" />
                      <input
                        type="text"
                        name="pymt_prod_type_code"
                        value={paymentForm.pymt_prod_type_code}
                        onChange={(e: any) => {
                          setPaymentForm({
                            ...paymentForm,
                            pymt_prod_type_code: e.target.value,
                            pymt_prod_type_code_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Payment Prod type code"
                      />
                    </div>
                      <p className="text-red-500 text-sm">
                      {paymentForm.pymt_prod_type_code_error}
                    </p>
                    </div> */}
                    {/* <div className="space-y-1">
                      <label htmlFor="pymt_mode" className={`input-label ${
                        paymentForm.pymt_mode_error ? "text-red-500" : ""
                      }`}>
                        Payment mode*
                      </label>
                      <div
                      id="pymt_mode"
                      className={`input-wrap ${
                        paymentForm.pymt_mode_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/user.svg" alt="" />
                      <input
                        type="text"
                        name="pymt_mode"
                        value={paymentForm.pymt_mode}
                        onChange={(e: any) => {
                          setPaymentForm({
                            ...paymentForm,
                            pymt_mode: e.target.value,
                            pymt_mode_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Payment mode"
                      />
                    </div>
                      <p className="text-red-500 text-sm">
                      {paymentForm.pymt_mode_error}
                    </p>
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="debit_acct_no" className={`input-label ${
                        paymentForm.debit_acct_no_error ? "text-red-500" : ""
                      }`}>
                        Debit Account No.*
                      </label>
                      <div
                      id="debit_acct_no"
                      className={`input-wrap ${
                        paymentForm.debit_acct_no_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/user.svg" alt="" />
                      <input
                        type="text"
                        name="debit_acct_no"
                        value={paymentForm.debit_acct_no_error}
                        onChange={(e: any) => {
                          setPaymentForm({
                            ...paymentForm,
                            debit_acct_no: e.target.value,
                            debit_acct_no_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Debit Account no"
                      />
                    </div>
                      <p className="text-red-500 text-sm">
                      {paymentForm.debit_acct_no_error}
                    </p>
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="beneficiary_name" className={`input-label ${
                        paymentForm.beneficiary_name_error ? "text-red-500" : ""
                      }`}>
                        Beneficiary Name*
                      </label>
                      <div
                      id="beneficiary_name"
                      className={`input-wrap ${
                        paymentForm.beneficiary_name_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/user.svg" alt="" />
                      <input
                        type="text"
                        name="beneficiary_name"
                        value={paymentForm.beneficiary_name}
                        onChange={(e: any) => {
                          setPaymentForm({
                            ...paymentForm,
                            beneficiary_name: e.target.value,
                            beneficiary_name_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Beneficiary Name"
                      />
                    </div>
                      <p className="text-red-500 text-sm">
                      {paymentForm.beneficiary_name_error}
                    </p>
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="beneficiary_account_no" className={`input-label ${
                        paymentForm.beneficiary_account_no_error ? "text-red-500" : ""
                      }`}>
                        Beneficiary Account No.*
                      </label>
                      <div
                      id="beneficiary_account_no"
                      className={`input-wrap ${
                        paymentForm.beneficiary_account_no_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/user.svg" alt="" />
                      <input
                        type="text"
                        name="beneficiary_account_no"
                        value={paymentForm.beneficiary_account_no}
                        onChange={(e: any) => {
                          setPaymentForm({
                            ...paymentForm,
                            beneficiary_account_no: e.target.value,
                            beneficiary_account_no_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Account No."
                      />
                    </div>
                      <p className="text-red-500 text-sm">
                      {paymentForm.beneficiary_account_no_error}
                    </p>
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="bene_ifsc_code" className={`input-label ${
                        paymentForm.bene_ifsc_code_error ? "text-red-500" : ""
                      }`}>
                        Beneficiary IFSC Code*
                      </label>
                      <div
                      id="bene_ifsc_code"
                      className={`input-wrap ${
                        paymentForm.bene_ifsc_code_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/user.svg" alt="" />
                      <input
                        type="text"
                        name="bene_ifsc_code"
                        value={paymentForm.bene_ifsc_code}
                        onChange={(e: any) => {
                          setPaymentForm({
                            ...paymentForm,
                            bene_ifsc_code: e.target.value,
                            bene_ifsc_code_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter IFSC Code"
                      />
                    </div>
                      <p className="text-red-500 text-sm">
                      {paymentForm.bene_ifsc_code_error}
                    </p>
                    </div> */}
                    <div className="space-y-1">
                      <label htmlFor="amount" className={`input-label ${
                        paymentForm.amount_error ? "text-red-500" : ""
                      }`}>
                        Amount*
                      </label>
                      <div
                      id="amount"
                      className={`input-wrap ${
                        paymentForm.amount_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/user.svg" alt="" />
                      <input
                        type="text"
                        name="amount"
                        value={paymentForm.amount}
                        onChange={(e: any) => {
                          setPaymentForm({
                            ...paymentForm,
                            amount: e.target.value,
                            amount_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Amount"
                      />
                    </div>
                      <p className="text-red-500 text-sm">
                      {paymentForm.amount_error}
                    </p>
                    </div>
                    {/* <div className="space-y-1">
                      <label htmlFor="debit_narration" className={`input-label ${
                        paymentForm.debit_narration_error ? "text-red-500" : ""
                      }`}>
                        Debit Narration*
                      </label>
                      <div
                      id="debit_narration"
                      className={`input-wrap ${
                        paymentForm.debit_narration_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/user.svg" alt="" />
                      <input
                        type="text"
                        name="borrower_name"
                        value={paymentForm.debit_narration}
                        onChange={(e: any) => {
                          setPaymentForm({
                            ...paymentForm,
                            debit_narration: e.target.value,
                            debit_narration_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Debit Narration"
                      />
                    </div>
                      <p className="text-red-500 text-sm">
                      {paymentForm.debit_narration_error}
                    </p>
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="credit_narration" className={`input-label ${
                        paymentForm.credit_narration_error ? "text-red-500" : ""
                      }`}>
                        Credit Narration*
                      </label>
                      <div
                      id="credit_narration"
                      className={`input-wrap ${
                        paymentForm.credit_narration_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/user.svg" alt="" />
                      <input
                        type="text"
                        name="credit_narration"
                        value={paymentForm.credit_narration}
                        onChange={(e: any) => {
                          setPaymentForm({
                            ...paymentForm,
                            credit_narration: e.target.value,
                            credit_narration_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Credit Narration"
                      />
                    </div>
                      <p className="text-red-500 text-sm">
                      {paymentForm.credit_narration_error}
                    </p>
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="mobile_number" className={`input-label ${
                        paymentForm.mobile_number_error ? "text-red-500" : ""
                      }`}>
                        Mobile No.
                      </label>
                      <div
                      id="mobile_number"
                      className={`input-wrap ${
                        paymentForm.mobile_number_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/user.svg" alt="" />
                      <input
                        type="text"
                        name="mobile_number"
                        value={paymentForm.mobile_number}
                        onChange={(e: any) => {
                          setPaymentForm({
                            ...paymentForm,
                            mobile_number: e.target.value,
                            mobile_number_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Mobile No."
                      />
                    </div>
                      <p className="text-red-500 text-sm">
                      {paymentForm.mobile_number_error}
                    </p>
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="email_id" className={`input-label ${
                        paymentForm.email_id_error ? "text-red-500" : ""
                      }`}>
                      Email*
                      </label>
                      <div
                      id="email_id"
                      className={`input-wrap ${
                        paymentForm.email_id_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/user.svg" alt="" />
                      <input
                        type="text"
                        name="email_id"
                        value={paymentForm.email_id}
                        onChange={(e: any) => {
                          setPaymentForm({
                            ...paymentForm,
                            email_id: e.target.value,
                            email_id_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="johndue@xyz.com"
                      />
                    </div>
                      <p className="text-red-500 text-sm">
                      {paymentForm.email_id_error}
                    </p>
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="remark" className={`input-label ${
                        paymentForm.remark_error ? "text-red-500" : ""
                      }`}>
                        Remark*
                      </label>
                      <div
                      id="remark"
                      className={`input-wrap ${
                        paymentForm.remark_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/user.svg" alt="" />
                      <input
                        type="text"
                        name="remark"
                        value={paymentForm.remark}
                        onChange={(e: any) => {
                          setPaymentForm({
                            ...paymentForm,
                            remark: e.target.value,
                            remark_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Remark"
                      />
                    </div>
                      <p className="text-red-500 text-sm">
                      {paymentForm.remark_error}
                    </p>
                    </div> */}
                    <div className="space-y-1">
                      <label htmlFor="pymt_date" className={`input-label ${
                        paymentForm.pymt_date_error ? "text-red-500" : ""
                      }`}>
                        Payment Date*
                      </label>
                      <div
                      id="pymt_date"
                      className={`input-wrap ${
                        paymentForm.pymt_date_error ? "border-red-500" : ""
                      }`}
                    ><img src="assets/date.svg" alt="" />
                    <DatePicker
                      selected={paymentForm.pymt_date}
                      onChange={(e: any) => {
                        setPaymentForm({
                          ...paymentForm,
                          pymt_date: e,
                          pymt_date_error: null,
                        });
                      }}
                      dateFormat="dd/MM/yyyy"                
                      
                    />                      
                    </div>
                      <p className="text-red-500 text-sm">
                      {paymentForm.pymt_date_error}
                    </p>
                    </div>
                    {/* <div className="space-y-1">
                      <label htmlFor="addl_info1" className={`input-label ${
                        paymentForm.addl_info1_error ? "text-red-500" : ""
                      }`}>
                        Add info*
                      </label>
                      <div
                      id="addl_info1"
                      className={`input-wrap ${
                        paymentForm.addl_info1_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/user.svg" alt="" />
                      <input
                        type="text"
                        name="addl_info1"
                        value={paymentForm.addl_info1}
                        onChange={(e: any) => {
                          setPaymentForm({
                            ...paymentForm,
                            addl_info1: e.target.value,
                            addl_info1_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Add info"
                      />
                    </div>
                      <p className="text-red-500 text-sm">
                      {paymentForm.addl_info1_error}
                    </p>
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="status" className={`input-label ${
                        paymentForm.status_error ? "text-red-500" : ""
                      }`}>
                        Payment Status*
                      </label>
                      <div
                      id="status"
                      className={`input-wrap ${
                        paymentForm.status_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/user.svg" alt="" />
                      <input
                        type="text"
                        name="status"
                        value={paymentForm.status}
                        onChange={(e: any) => {
                          setPaymentForm({
                            ...paymentForm,
                            status: e.target.value,
                            status_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Payment Status"
                      />
                    </div>
                      <p className="text-red-500 text-sm">
                      {paymentForm.status_error}
                    </p>
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="current_step" className={`input-label ${
                        paymentForm.current_step_error ? "text-red-500" : ""
                      }`}>
                        Current Step*
                      </label>
                      <div
                      id="current_step"
                      className={`input-wrap ${
                        paymentForm.current_step_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/user.svg" alt="" />
                      <input
                        type="text"
                        name="current_step"
                        value={paymentForm.current_step}
                        onChange={(e: any) => {
                          setPaymentForm({
                            ...paymentForm,
                            current_step: e.target.value,
                            current_step_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Current step"
                      />
                    </div>
                      <p className="text-red-500 text-sm">
                      {paymentForm.current_step_error}
                    </p>
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="file_name" className={`input-label ${
                        paymentForm.file_name_error ? "text-red-500" : ""
                      }`}>
                        File Name*
                      </label>
                      <div
                      id="file_name"
                      className={`input-wrap ${
                        paymentForm.file_name_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/user.svg" alt="" />
                      <input
                        type="text"
                        name="file_name"
                        value={paymentForm.file_name}
                        onChange={(e: any) => {
                          setPaymentForm({
                            ...paymentForm,
                            file_name: e.target.value,
                            file_name_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter File Name"
                      />
                    </div>
                      <p className="text-red-500 text-sm">
                      {paymentForm.file_name_error}
                    </p>
                    </div> */}
                    <div className="space-y-1">
                      <label htmlFor="acct_debit_date" className={`input-label ${
                        paymentForm.acct_debit_date_error ? "text-red-500" : ""
                      }`}>
                        Account Debit Date*
                      </label>
                      <div
                      id="acct_debit_date"
                      className={`input-wrap ${
                        paymentForm.acct_debit_date_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/date.svg" alt="" />
                      <DatePicker
                        selected={paymentForm.acct_debit_date}
                        onChange={(e: any) => {
                          setPaymentForm({
                            ...paymentForm,
                            acct_debit_date: e,
                            acct_debit_date_error: null,
                          });
                        }}
                        dateFormat="dd/MM/yyyy"
                        
                      />
                    </div>
                      <p className="text-red-500 text-sm">
                      {paymentForm.acct_debit_date_error}
                    </p>
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="customer_ref_no" className={`input-label ${
                        paymentForm.customer_ref_no_error ? "text-red-500" : ""
                      }`}>
                        Customer Reference No.*
                      </label>
                      <div
                      id="customer_ref_no"
                      className={`input-wrap ${
                        paymentForm.customer_ref_no_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/user.svg" alt="" />
                      <input
                        type="text"
                        name="customer_ref_no"
                        value={paymentForm.customer_ref_no}
                        onChange={(e: any) => {
                          setPaymentForm({
                            ...paymentForm,
                            customer_ref_no: e.target.value,
                            customer_ref_no_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Customer Ref. No."
                      />
                    </div>
                      <p className="text-red-500 text-sm">
                      {paymentForm.remark_error}
                    </p>
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="utr_no" className={`input-label ${
                        paymentForm.utr_no_error ? "text-red-500" : ""
                      }`}>
                        UTR No.*
                      </label>
                      <div
                      id="utr_no"
                      className={`input-wrap ${
                        paymentForm.utr_no_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/user.svg" alt="" />
                      <input
                        type="text"
                        name="utr_no"
                        value={paymentForm.utr_no}
                        onChange={(e: any) => {
                          setPaymentForm({
                            ...paymentForm,
                            utr_no: e.target.value,
                            utr_no_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter UTR No."
                      />
                    </div>
                      <p className="text-red-500 text-sm">
                      {paymentForm.utr_no_error}
                    </p>
                    </div>
                </div>
              </div>

              <div className="tw-modal-footer">
                <button
                  className="tw-modal-footer-button bg-secondary text-white"
                  onClick={()=>{
                    addPaymentData();
                      setApprovePayment(false);
                      setPaymentForm(data)
                    
                  }}
                >
                  Submit
                </button>
                <button
                  className="tw-modal-footer-button bg-secondary bg-opacity-10 text-secondary"
                  onClick={() => {
                    setApprovePayment(false);
                    setPaymentForm(data)
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
          <ConfirmDialog
            // group="declarative"
            visible={deleteConfirmVisible}
            onHide={() => setDeleteConfirmVisible(false)}
            message="Are you sure you want to delete?"
            header="Confirmation"
            icon="pi pi-exclamation-triangle"
            accept={accept}
            reject={reject}
            style={{ width: "50vw" }}
            breakpoints={{ "1100px": "75vw", "960px": "100vw" }}
          />
        </>
      )}
    </div>
  );
};

export default DisbursementData;

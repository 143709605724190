import { ApiGet, ApiPost } from "../../helper/API/ApiData";
import TableComponent from "../../components/Tables";
import { TableCell, TableRow } from "@mui/material";
import { useParams } from "react-router";
import { useEffect, useState } from "react";
import { STATE } from "../../constants";
import { useNavigate } from "react-router";

const ReverseMisDataModal = () => {
  const [loader, setLoader] = useState(true);
  let { id } = useParams();
  const [viewData, setViewData] = useState<any>([]);
  const [viewDataState, setViewDataState] = useState(STATE.DEFAULT_STATE);
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };
  let viewDataColumns = [
    { id: "file_sequence_num", label: "File Sequence Number" },
    { id: "pymt_prod_type_code", label: "Product Type Code" },
    { id: "pymt_mode", label: "Payment Mode" },
    { id: "debit_acct_no", label: "Debit Account Number" },
    { id: "beneficiary_name", label: "Beneficiary Name" },
    { id: "beneficiary_account_no", label: "Beneficiary Account Number" },
    { id: "bene_ifsc_code", label: "Beneficiary IFSC Code" },
    { id: "amount", label: "Amount" },
    { id: "debit_narration", label: "Debit Narration" },
    { id: "credit_narration", label: "Credit Narration" },
    { id: "mobile_number", label: "Mobile Number" },
    { id: "email_id", label: "Email Id" },
    { id: "remark", label: "Remark" },
    { id: "pymt_date", label: "Payment Date" },
    { id: "reference_no", label: "Reference Number" },
    { id: "addl_info1", label: "Additional Info1" },
    { id: "addl_info2", label: "Additional Info2" },
    { id: "addl_info3", label: "Additional Info3" },
    { id: "addl_info4", label: "Additional Info4" },
    { id: "addl_info5", label: "Additional Info5" },
    { id: "status", label: "Status" },
    { id: "current_step", label: "Step" },
    { id: "file_name", label: "File Name" },
    { id: "rejected_by", label: "Rejected By" },
    { id: "rejection_reason", label: "Rejection Reason" },
    { id: "acct_debit_date", label: "Account Debit Date" },
    { id: "customer_ref_no", label: "Customer Reference Number" },
    { id: "utr_no", label: "UTR Number" },
  ];

  useEffect(() => {
    Promise.all([setViewModalData()])
      .then(() => {
        setTimeout(() => {
          setLoader(false);
        }, 250);
      })
      .catch((error) => {
        setLoader(false);
      });
  }, []);
  const setViewModalData = () => {
    ApiGet(`bankdisbursement/get_orendadisbursement/${id}`).then((res: any) => {
      if (
        res?.data?.orendadisbusement_data &&
        res?.data?.orendadisbusement_data?.length > 0
      ) {
        setViewData(res?.data?.orendadisbusement_data);
      }
    });
  };

  return (
    <div className="overflow-x-hidden p-6">
      {loader ? (
        <>
          {/* START::OVERLAY */}
          <div className="fixed inset-0 w-full h-screen bg-white z-[9999] flex items-center justify-center">
            <img src="assets/logo.svg" />
          </div>
          {/* END::OVERLAY */}
        </>
      ) : (
        <>
          <div className="flex flex-wrap gap-5 items-center justify-between mb-5">
            <div className="md:flex w-full lg:w-[unset] items-center lg:justify-between md:space-x-9 space-y-5 md:space-y-0">
            <button  className="mt-2" onClick={goBack}> <i className="pi pi-arrow-circle-left" style={{ fontSize: '2.0rem' }}></i> </button>
              <h1 className="text-[22px] text-light-gray font-semibold">
                Reverse MIS Detail
              </h1>
            </div>
            {/* Start:: view Modal */}

            <TableComponent
              rows={viewData}
              columns={viewDataColumns}
              state={viewDataState}
              renderColumn={(column: any) => {
                return (
                  <TableCell
                    align="center"
                    className="!bg-gray-200"
                    key={column.id}
                  >
                    {column.label}
                  </TableCell>
                );
              }}
              renderRow={(row: any, index: number) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                    <TableCell align="center" component="th" scope="row">
                      {row.file_sequence_num}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.pymt_prod_type_code}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.pymt_mode}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.debit_acct_no}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.beneficiary_name}
                    </TableCell>

                    <TableCell align="center" component="th" scope="row">
                      {row.beneficiary_account_no}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.bene_ifsc_code}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.amount}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.debit_narration}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.credit_narration}
                    </TableCell>

                    <TableCell align="center" component="th" scope="row">
                      {row.mobile_number}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.email_id}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.remark}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.pymt_date}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.reference_no}
                    </TableCell>

                    <TableCell align="center" component="th" scope="row">
                      {row.addl_info1}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.addl_info2}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.addl_info3}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.addl_info4}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.addl_info5}
                    </TableCell>

                    <TableCell align="center" component="th" scope="row">
                      {row.status}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.current_step}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.file_name}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.rejected_by}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.rejection_reason}
                    </TableCell>

                    <TableCell align="center" component="th" scope="row">
                      {row.acct_debit_date}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.customer_ref_no}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.utr_no}
                    </TableCell>
                  </TableRow>
                );
              }}
            />

            {/* End:: view Modal */}
          </div>
        </>
      )}
    </div>
  );
};

export default ReverseMisDataModal;

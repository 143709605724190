import { createSlice } from '@reduxjs/toolkit'

const initialState: any = {}

export const authSlice = createSlice({
	name: 'userData',
	initialState,
	reducers: {
		Login_User: (state, { payload }: any) => {
			state.userData = payload
		},
		Logout_Auth: (state, { payload }: any) => {
			state.userData = {}
		}
	}
})

// Action creators are generated for each case reducer function
export const { Login_User, Logout_Auth } = authSlice.actions

export default authSlice.reducer
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { ApiGet, ApiPost, ApiPut } from '../../helper/API/ApiData'
import { getImageUrl, isEmpty } from '../../helper/util'

const UserBasicDetails = () => {

    const main_data = {
        postal_code: "",
        email: "",
        mobile: "",
    }

    const [formData, setFormData] = useState<any>({ main_data });
    const [loader, setLoader] = useState(true);

    const getBasicDetails = async () => {
        await ApiGet(`userReffered/setting`)
            .then((res: any) => {
                let temp = {
                    postal_code: res?.data?.user?.postal_code,
                    email: res?.data?.user?.email,
                    mobile: res?.data?.user?.mobile,
                }
                setFormData(temp)
            })
    }

    const handlebasicdetail = async () => {
        await ApiPut("userReffered/setting", formData)
            .then((res: any) => {
                getBasicDetails()
            })
            .catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
        Promise.all([
            getBasicDetails()
        ]).then(() => {
            setTimeout(() => { setLoader(false) }, 250)
        }).catch((error) => {
            setLoader(false)
        })
    }, []);

    return (<div className="overflow-x-hidden">
        {/* 
    <Sidebar />
    <Header /> */}
        {loader ?
            <>
                <div className="fixed inset-0 w-full h-screen bg-white z-[9999] flex items-center justify-center">
                    <img src="assets/logo.svg" />
                </div>
            </>
            :
            <>
                {/* START::MAIN CONTENT */}
                {/* <main className="lg:pl-[280px] pt-[92px]"> */}
                <div className="p-4 sm:p-9 space-y-5">

                    {/* START::DASHBOARD HEADER */}
                    <div className="flex flex-wrap gap-5 items-center justify-between">
                        <div className="md:flex w-full lg:w-[unset] items-center lg:justify-between md:space-x-9">
                            <h1 className="text-[22px] text-light-gray font-semibold">Basic Details</h1>
                        </div>
                    </div>
                    {/* END::DASHBOARD HEADER */}

                    {/* START::CHEQUE */}
                    <div className="space-y-4">
                        <div className='grid md:grid-cols-2 gap-4'>
                            <div className="w-full space-y-2">
                                <label htmlFor="" className="text-sm font-medium">E-mail</label>
                                <div id="email" className="input-wrap w-full">
                                    <input type="text" className="block w-full text-sm text-[#808080]" placeholder="Email" value={formData.email} onChange={(e: any) => {
                                        setFormData({ ...formData, email: e.target.value })
                                    }} />
                                </div>
                            </div>
                            <div className="w-full space-y-2">
                                <label htmlFor="" className="text-sm font-medium">Pincode</label>
                                <div id="pincode" className="input-wrap w-full">
                                    <input type="text" value={formData.postal_code} onChange={(e: any) => {
                                        setFormData({ ...formData, postal_code: e.target.value })
                                    }} className="block w-full text-sm text-[#808080]" placeholder="postal code" />
                                </div>
                            </div>
                            <div className="w-full space-y-2">
                                <label htmlFor="" className="text-sm font-medium">Mobile</label>
                                <div id="mobile" className="input-wrap w-full">
                                    <input type="text" value={formData.mobile} maxLength={13} onChange={(e: any) => {
                                        setFormData({ ...formData, mobile: e.target.value })
                                    }} className="block w-full text-sm text-[#808080]" placeholder="mobile" />
                                </div>
                            </div>
                        </div>
                        <button className="px-16 py-[18px] text-semibold text-sm bg-secondary text-white rounded-lg border" onClick={() => handlebasicdetail()}>Save</button>
                    </div>
                    {/* END::CHEQUE */}

                </div>
                {/* </main> */}
                {/* END::MAIN CONTENT */}
            </>
        }
    </div >)
}

export default UserBasicDetails
import React from 'react';
import Routes from './pages';
import axios, { AxiosError } from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Set up Axios global configuration
axios.defaults.baseURL = 'https://www.orendafinserv.com/';

// Request interceptor for handling errors globally
axios.interceptors.response.use(
  (response) => {
    // Return response if request was successful (status code 200)
    return response;
  },
  (error: AxiosError<any>) => {
    // Handle errors here
    if (error.response && error.response.status !== 200) {
      // Display toaster only if response code is not 200

      if(error.response.data.error){
        toast.error(error.response.data.error, {
          position: "top-right",
          theme: "colored"
        })
      }
      else{
        toast.error('Something went wrong. Please try again later.',{
          position: "top-right",
          theme: "colored"
        });

      }
    }
    return Promise.reject(error); // Rethrow the error for further handling if needed
  }
);

function App() {
  return (
    <>
      <Routes />
      <ToastContainer />
    </>
  );
}

export default App;

import { TableCell, TableRow } from '@mui/material';
import { useState, useEffect } from 'react';
import TableComponent from '../../components/Tables';
import { STATE } from '../../constants';
import { ApiGet } from '../../helper/API/ApiData'
import { numberFormat } from '../../helper/util';
// import Header from './components/header';
// import Sidebar from './components/sidebar'

function UserReffered() {
  const [activeTab, setActiveTab] = useState("level 1");
  const [userData, setUserData] = useState([]);
  const [state, setState] = useState(STATE.DEFAULT_STATE);
  const [loader, setLoader] = useState(true);

  const getData = async (activeTab: string) => {
    await ApiGet(`userReffered/level?level_type=${activeTab}`)
      .then((res: any) => {
        let temp = res.data.result.map((item: any, index: number) => {
          return {
            d: (((state.page - 1) * state.limit) + index + 1),
            Name: item?.name,
            City: item?.city,
            State: item?.state,
            ContactNo: item?.mobile,
            User: item?.user,
            TotalBilling: item?.totalBilling,
            TotalLoanAmount: item?.totalLoanAmount
          }
        })
        setUserData(temp)
        setState({
          ...state,
          totalItems: res.data.total
        })
      })
      .catch((error) => {
        setUserData([])
      })
  }
  useEffect(() => {
    Promise.all([
      getData(activeTab)
    ]).then(() => {
      setTimeout(() => { setLoader(false) }, 250)
    }).catch((error) => {
      setLoader(false)
    })
  }, [activeTab, state.page, state.limit]);

  const handlePageData = (limit: number, page: number = 1) => {
    setState({
      ...state,
      limit,
      page
    })
  }

  const handlePage = (page: number) => {
    setState({
      ...state,
      page
    })
  }

  // const columns = ["SR.NO.", "Name", "City", "State", "Contact No.", "Total Billing", "TotalLoan Amount"];
  const columns_level_1 = [
    { id: 'SRNO', label: 'SR.NO.' },
    { id: 'Name', label: 'Name' },
    { id: 'City', label: 'City' },
    { id: 'State', label: 'State' },
    { id: 'ContactNo', label: 'Contact No.' },
    { id: 'TotalBilling', label: 'Total Billing' },
    { id: 'TotalLoanAmount', label: 'TotalLoan Amount' },
  ];

  const columns_level_2 = [
    { id: 'SRNO', label: 'SR.NO.' },
    { id: 'Name', label: 'Name' },
    { id: 'City', label: 'City' },
    { id: 'State', label: 'State' },
    { id: 'ContactNo', label: 'Contact No.' },
    // { id: 'User', label: 'User Name' },
    { id: 'TotalBilling', label: 'Total Billing' },
    { id: 'TotalLoanAmount', label: 'TotalLoan Amount' },
  ];

  const tablevel = () => {
    setActiveTab("level 2")
    setUserData([])
  }

  return (<div className="overflow-x-hidden">
    {/* <Sidebar />
    <Header /> */}

    {loader ?
      <>
        {/* START::OVERLAY */}
        <div className="fixed inset-0 w-full h-screen bg-white z-[9999] flex items-center justify-center">
          <img src="assets/logo.svg" />
        </div>
        {/* END::OVERLAY */}
      </>
      :
      <>

        {/* <main className="lg:pl-[280px] pt-[92px]"> */}
        <div className="p-4 sm:p-9 space-y-5">

          {/* START::DASHBOARD HEADER */}
          <div className="flex flex-wrap gap-5 items-center justify-between">
            <div className="md:flex w-full lg:w-[unset] items-center lg:justify-between md:space-x-9 space-y-5 md:space-y-0">
              <h1 className="text-[22px] text-light-gray font-semibold">Users Referred</h1>

              {/* 
                            if(tab active) 
                                Add class "bg-white rounded-[18px] text-primary"
                            else 
                                Remove class "bg-white rounded-[18px] text-primary"
                        */}
              <ul className="inline-flex items-center p-[2px] text-white bg-primary rounded-[18px] whitespace-nowrap overflow-x-auto">
                <li>
                  <button className={`min-w-[170px] py-2 px-7 text-xs font-medium ${activeTab === "level 1" && "bg-white rounded-[18px] text-primary"}`} onClick={() => setActiveTab("level 1")}>Level 01</button>
                </li>
                <li>
                  <button className={`min-w-[170px] py-2 px-7 text-xs font-medium ${activeTab === "level 2" && "bg-white rounded-[18px] text-primary"}`} onClick={() => tablevel()}>Level 02</button>
                </li>
              </ul>
            </div>

            {/* START::EXPORT */}
            <ul className="flex space-x-[18px]">
              <li>
                <button className="flex items-center space-x-[6px] py-2 px-6 rounded-[18px] border border-light-gray text-xs font-semibold">
                  <img src="assets/pdf.svg" alt="" />
                  <span>PDF</span>
                </button>
              </li>
              <li>
                <button className="flex items-center space-x-[6px] py-2 px-6 rounded-[18px] border border-light-gray text-xs font-semibold">
                  <img src="assets/excel.svg" alt="" />
                  <span>EXCEL</span>
                </button>
              </li>
            </ul>
            {/* END::EXPORT */}
          </div>
          {/* END::DASHBOARD HEADER */}
          <TableComponent
            rows={userData ?? []}
            handlePageData={handlePageData}
            handlePage={handlePage}
            state={state}
            columns={activeTab === "level 2" ? columns_level_2 : columns_level_1}
            renderColumn={(column: any) => {
              return (
                <TableCell align="center"
                  className="!bg-gray-200"
                  key={column.id}
                  style={{ background: "gray" }}
                >
                  {column.label}
                </TableCell>
              )
            }}
            renderRow={(row: any) => {

              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  <TableCell align="center" component="th" scope="row">
                    {row.SRNO}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {row.Name}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {row.City}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {row.State}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {row.ContactNo}
                  </TableCell>
                  {/* {activeTab === "level 2" &&
                    <TableCell align="center" component="th" scope="row">
                      {row.User}
                    </TableCell>
                  } */}
                  <TableCell align="center" component="th" scope="row">
                    {row.TotalBilling}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {numberFormat(row.TotalLoanAmount)}
                  </TableCell>
                </TableRow>
              )
            }}
          />
        </div>
      </>
    }
    {/* </main> */}
  </div>
  )
}

export default UserReffered
// const protocol = `${process.env.REACT_APP_PROTOCOL}`;
// const host = `${process.env.REACT_APP_HOST}`;
// const apiPort = `${process.env.REACT_APP_PORT}`;
// const trailUrl = `${process.env.REACT_APP_TRAIL_URL}`;

const protocol = `https`;

const host = `app-backend.orendafinserv.com`;
// const apiPort = `5009`;
const trailUrl = `api/v1`;



const hostUrl = `${protocol}://${host}/`;
const hostUrl2 = `${protocol}://${host}:449`;
const endpoint = `${protocol}://${host}/${trailUrl}`;

const API_LOCAL = {
    protocol: protocol,
    host: host,
    apiUrl: trailUrl,
    endpoint: endpoint,
    hostUrl: hostUrl,
    hostUrl2: hostUrl2
};

export const API = API_LOCAL

import { TableCell, TableRow, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import TableComponent from '../../components/Tables';
import { STATE } from '../../constants';
import { ApiGet, ApiPost, ApiPut } from '../../helper/API/ApiData';
import { PERMISSION_TYPE, userPermission } from '../../helper/permission';
import { RootState } from '../../redux/store';
// import Header from './components/header'
// import Sidebar from './components/sidebar'
function LoginCredentials() {

    const loginListData = {
        bank_name: "",
        product: "",
        location: "",
        app_type: "",
        link: "",
        agency_code: "",
        mobile: "",
        email: "",
        userId: "",
        password: "",
        bank_name_error: null,
        product_error: null,
        location_error: null,
        app_type_error: null,
        link_error: null,
        agency_code_error: null,
        mobile_error: null,
        email_error: null,
        userId_error: null,
        password_error: null,
    }

    const columns = [
        { id: 'SRNO', label: 'SR.NO.' },
        { id: 'bank_name', label: 'Bank Name' },
        { id: 'location', label: 'Location' },
        { id: 'product', label: 'Product' },
        { id: 'app_type', label: 'App Type' },
        { id: 'link', label: 'Link' },
        { id: 'agency_code', label: 'Agency Code' },
        { id: 'mobile', label: 'Mobile' },
        { id: 'email', label: 'Email' },
        { id: 'userId', label: 'UserId' },
        { id: 'password', label: 'Password' },
        { id: 'action', label: 'Action' },
    ];


    const [formData, setFormData] = useState<any>(loginListData);
    const [selectedId, setSelectedId] = useState<string>("");
    const [addOpen, setAddOpen] = useState(false);
    const [loginListDetail, setLoginListDetail] = useState([]);
    const [loader, setLoader] = useState(true);
    const [state, setState] = useState(STATE.DEFAULT_STATE);
    const [permission, setPermission] = useState<any>({});
    const user = useSelector((state: RootState) => state?.authUser?.userData?.user)

    const getLoginList = async () => {
        await ApiGet(`loginCredentials?page=${state.page}&limit=${state.limit}`)
            .then((res: any) => {
                let temp = res.data.result.docs.map((item: any, index: number) => {
                    return {
                        SRNO: (((state.page - 1) * state.limit) + index + 1),
                        bank_name: item.bank_name,
                        product: item.product,
                        location: item.location,
                        app_type: item.app_type,
                        link: item.link,
                        agency_code: item.agency_code,
                        mobile: item.mobile,
                        email: item.email,
                        userId: item.userId,
                        password: item.password,
                        id: item._id,
                    }
                })
                setLoginListDetail(temp)

                const tempPermission = {
                    [PERMISSION_TYPE.ADMIN]: userPermission(user, PERMISSION_TYPE.ADMIN)
                }
                setPermission(tempPermission)

                setState({
                    ...state,
                    totalItems: res.data.result.total
                })
            }).catch((error) => {
                const tempPermission = {
                    [PERMISSION_TYPE.ADMIN]: userPermission(user, PERMISSION_TYPE.ADMIN)
                }
                setPermission(tempPermission)
            });
    }

    useEffect(() => {
        Promise.all([
            getLoginList()
        ]).then(() => {
            setTimeout(() => { setLoader(false) }, 250)
        }).catch((error) => {
            setLoader(false)
        })
    }, [state.page, state.limit])


    const handlePageData = (limit: number, page: number = 1) => {
        setState({
            ...state,
            limit,
            page
        })
    }

    const handlePage = (page: number) => {
        setState({
            ...state,
            page
        })
    }

    const handleEditData = (row: any) => {
        const { SRNO, id, ...payload } = row
        setFormData({ ...loginListData, ...payload })
        setAddOpen(true)
    }

    const handleDeleteData = async (id: string) => {
        await ApiPut(`loginCredentials/delete/${id}`, {})
            .then((res: any) => {
                getLoginList()
            })
    }

    const addLoginList = () => {
        let error = { ...formData };
        let emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/;
        // if (formData.bank_name == "") {
        //     error = { ...error, bank_name_error: "Please enter a bank name" };
        // }
        // if (formData.product == "") {
        //     error = { ...error, product_error: "Please enter a dsa name" };
        // }
        // if (formData.location == "") {
        //     error = { ...error, location_error: "Please enter a location" };
        // }
        // if (formData.app_type == "") {
        //     error = { ...error, app_type_error: "Please enter a app type" };
        // }
        // if (formData.link == "") {
        //     error = { ...error, link_error: "Please enter a link" };
        // }
        // if (formData.agency_code == "") {
        //     error = { ...error, agency_code_error: "Please enter a agency code" };
        // }
        // if (formData.mobile == "") {
        //     error = { ...error, mobile_error: "Please enter a mobile" };
        // }
        if (formData.email != "" && !emailPattern.test(formData.email)) {
            error = { ...error, email_error: "Please enter a valid email" };
        }
        // if (formData.userId == "") {
        //     error = { ...error, userId_error: "Please enter a userId" };
        // }
        // if (formData.password == "") {
        //     error = { ...error, password_error: "Please enter a password" };
        // }
        setFormData(error)
        if (error.bank_name_error == null && error.product_error == null && error.location_error == null && error.app_type_error == null && error.link_error == null && error.agency_code_error == null && error.mobile_error == null && error.email_error == null && error.userId_error == null && error.password_error == null) {
            const { bank_name_error, product_error, location_error, app_type_error, link_error, agency_code_error, mobile_error, email_error, userId_error, password_error, ...payload } = formData
            if (selectedId == "") {
                ApiPost("loginCredentials/add-user", payload)
                    .then((res: any) => {
                        setFormData(loginListData);
                        getLoginList();
                        setAddOpen(false);
                    })
                    .catch((error) => {
                        console.log(error);
                    })
            } else {
                ApiPut(`loginCredentials/${selectedId}`, payload)
                    .then((res: any) => {
                        setFormData(loginListData);
                        getLoginList();
                        setAddOpen(false);
                    })
                    .catch((error) => {
                        console.log(error);
                    })
            }
        }
    }
    return (<div className="overflow-x-hidden">

        {/* <Sidebar />
    <Header /> */}
        {loader ?
            <>
                {/* START::OVERLAY */}
                <div className="fixed inset-0 w-full h-screen bg-white z-[9999] flex items-center justify-center">
                    <img src="assets/logo.svg" />
                </div>
                {/* END::OVERLAY */}
            </>
            :
            <>
                {/* START::MAIN CONTENT */}
                {/* <main className="lg:pl-[280px] pt-[92px]"> */}
                <div className="p-4 sm:p-9 space-y-5">

                    {/* START::DASHBOARD HEADER */}
                    <div className="flex flex-wrap gap-5 items-center justify-between">
                        <div className="md:flex w-full lg:w-[unset] items-center lg:justify-between md:space-x-9">
                            <h1 className="text-[22px] text-light-gray font-semibold">Login Credentials List</h1>
                            {permission[PERMISSION_TYPE.ADMIN] &&
                                <ul className="inline-flex items-center p-[2px] text-white bg-primary rounded-[18px] whitespace-nowrap overflow-x-auto">
                                    <li>
                                        <button className={`py-2 px-7 text-xs font-medium`} onClick={(e) => {
                                            setFormData(loginListData)
                                            setSelectedId("")
                                            setAddOpen(true)
                                        }}>Add Login Credential</button>
                                    </li>
                                </ul>
                            }
                        </div>

                        {/* START::EXPORT */}
                        <ul className="flex space-x-[18px]">
                            <li>
                                <button className="flex items-center space-x-[6px] py-2 px-6 rounded-[18px] border border-light-gray text-xs font-semibold">
                                    <img src="assets/pdf.svg" alt="" />
                                    <span>PDF</span>
                                </button>
                            </li>
                            <li>
                                <button className="flex items-center space-x-[6px] py-2 px-6 rounded-[18px] border border-light-gray text-xs font-semibold">
                                    <img src="assets/excel.svg" alt="" />
                                    <span>EXCEL</span>
                                </button>
                            </li>
                        </ul>
                        {/* END::EXPORT */}
                    </div>
                    {/* END::DASHBOARD HEADER */}
                    <TableComponent
                        rows={loginListDetail}
                        columns={columns}
                        handlePageData={handlePageData}
                        handlePage={handlePage}
                        state={state}
                        renderColumn={(column: any) => {
                            return (
                                <TableCell align="center"
                                    className="!bg-gray-200"
                                    key={column.id}
                                    style={{ background: "gray" }}
                                >
                                    {column.label}
                                </TableCell>
                            )
                        }}
                        renderRow={(row: any) => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                    <TableCell align="center" component="th" scope="row">
                                        {row.SRNO}
                                    </TableCell>
                                    <TableCell align="center" component="th" scope="row">
                                        {row.bank_name}
                                    </TableCell>
                                    <TableCell align="center" component="th" scope="row">
                                        {row.product}
                                    </TableCell>
                                    <TableCell align="center" component="th" scope="row">
                                        {row.location}
                                    </TableCell>
                                    <TableCell align="center" component="th" scope="row">
                                        {row.app_type}
                                    </TableCell>
                                    <TableCell align="center" component="th" scope="row">
                                        <a href={row.link} target="_blank">{row.link}</a>
                                    </TableCell>
                                    <TableCell align="center" component="th" scope="row">
                                        {row.agency_code}
                                    </TableCell>
                                    <TableCell align="center" component="th" scope="row">
                                        {row.mobile}
                                    </TableCell>
                                    <TableCell align="center" component="th" scope="row">
                                        {row.email}
                                    </TableCell>
                                    <TableCell align="center" component="th" scope="row">
                                        {row.userId}
                                    </TableCell>
                                    <TableCell align="center" component="th" scope="row">
                                        {row.password}
                                    </TableCell>
                                    <TableCell align="center" component="th" scope="row" className="cursor-pointer">
                                        <ul className="flex space-x-2 justify-center">
                                            <li>
                                                <Tooltip title="Edit Data">
                                                    <img className='mx-auto' onClick={() => {
                                                        setSelectedId(row.id)
                                                        handleEditData(row)
                                                    }} src="assets/edit-connector.svg" alt="" />
                                                </Tooltip>
                                            </li>
                                            <li>
                                                <Tooltip title="Delete Data">
                                                    <img className='mx-auto' onClick={() => {
                                                        handleDeleteData(row.id)
                                                    }} src="assets/trash-gray.svg" alt="" />
                                                </Tooltip>
                                            </li>
                                        </ul>
                                    </TableCell>
                                </TableRow>
                            )
                        }}
                    />
                </div>
                {/* </main> */}

                {/* Start::Add Disbursement Data Modal */}
                <div className={`tw-modal ${addOpen ? 'flex' : 'hidden'} !items-start`}>
                    <div className="tw-modal-content">
                        <div className="tw-modal-title">
                            <div>Add CodeList Data</div>
                            <button onClick={() => setAddOpen(false)}>
                                <img src="assets/close-outline.svg" alt="" />
                            </button>
                        </div>

                        <div className='tw-modal-body'>
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-[22px]">
                                <div className="space-y-1">
                                    <label htmlFor="bank_name" className={`input-label ${formData.bank_name_error ? "text-red-500" : ""}`}>Bank Name</label>
                                    <div id="bank_name" className={`input-wrap ${formData.bank_name_error ? "border-red-500" : ""}`}>
                                        <img src="assets/user.svg" alt="" />
                                        <input type="text" name="bank_name" value={formData.bank_name} onChange={(e: any) => {
                                            setFormData({ ...formData, bank_name: e.target.value, bank_name_error: null });
                                        }} className="w-full text-sm" placeholder="Enter Bank Name" />
                                    </div>
                                    <p className="text-red-500 text-sm">{formData.bank_name_error}</p>
                                </div>
                                <div className="space-y-1">
                                    <label htmlFor="product" className={`input-label ${formData.product_error ? "text-red-500" : ""}`}>Product</label>
                                    <div id="product" className={`input-wrap ${formData.product_error ? "border-red-500" : ""}`}>
                                        <img src="assets/firm.svg" alt="" />
                                        <input type="text" name="product" value={formData.product} onChange={(e: any) => {
                                            setFormData({ ...formData, product: e.target.value, product_error: null });
                                        }} className="w-full text-sm" placeholder="Enter Product" />
                                    </div>
                                    <p className="text-red-500 text-sm">{formData.product_error}</p>
                                </div>
                                <div className="space-y-1">
                                    <label htmlFor="location" className={`input-label ${formData.location_error ? "text-red-500" : ""}`}>Location</label>
                                    <div id="location" className={`input-wrap ${formData.location_error ? "border-red-500" : ""}`}>
                                        <img src="assets/email.svg" alt="" />
                                        <input type="text" name="location" value={formData.location} onChange={(e: any) => {
                                            setFormData({ ...formData, location: e.target.value, location_error: null });
                                        }} className="w-full text-sm" placeholder="Enter Location" />
                                    </div>
                                    <p className="text-red-500 text-sm">{formData.location_error}</p>
                                </div>
                                <div className="space-y-1">
                                    <label htmlFor="app_type" className={`input-label ${formData.app_type_error ? "text-red-500" : ""}`}>App Type</label>
                                    <div id="app_type" className={`input-wrap ${formData.app_type_error ? "border-red-500" : ""}`}>
                                        <img src="assets/product.svg" alt="" />
                                        <select name="app_type" value={formData.app_type} onChange={(e) => setFormData({ ...formData, app_type: e.target.value, app_type_error: null })} className="w-full text-sm text-[#808080]">
                                            <option value="" selected>Select App Type</option>
                                            <option value="both">Both</option>
                                            <option value="web">Web</option>
                                            <option value="App">App</option>
                                        </select>
                                    </div>
                                    <p className="text-red-500 text-sm">{formData.app_type_error}</p>
                                </div>
                                <div className="space-y-1">
                                    <label htmlFor="link" className={`input-label ${formData.link_error ? "text-red-500" : ""}`}>Link</label>
                                    <div id="link" className={`input-wrap ${formData.link_error ? "border-red-500" : ""}`}>
                                        <img src="assets/bank.svg" alt="" />
                                        <input type="text" name="link" value={formData.link} onChange={(e: any) => {
                                            setFormData({ ...formData, link: e.target.value, link_error: null });
                                        }} className="w-full text-sm" placeholder="Enter Link" />
                                    </div>
                                    <p className="text-red-500 text-sm">{formData.link_error}</p>
                                </div>
                                <div className="space-y-1">
                                    <label htmlFor="agency_code" className={`input-label ${formData.agency_code_error ? "text-red-500" : ""}`}>Aency Code</label>
                                    <div id="agency_code" className={`input-wrap ${formData.agency_code_error ? "border-red-500" : ""}`}>
                                        <img src="assets/user.svg" alt="" />
                                        <input type="text" name="agency_code" value={formData.agency_code} onChange={(e: any) => {
                                            setFormData({ ...formData, agency_code: e.target.value, agency_code_error: null });
                                        }} className="w-full text-sm" placeholder="Enter Aency Code" />
                                    </div>
                                    <p className="text-red-500 text-sm">{formData.agency_code_error}</p>
                                </div>
                                <div className="space-y-1">
                                    <label htmlFor="mobile" className={`input-label ${formData.mobile_error ? "text-red-500" : ""}`}>Mobile</label>
                                    <div id="mobile" className={`input-wrap ${formData.mobile_error ? "border-red-500" : ""}`}>
                                        <img src="assets/firm.svg" alt="" />
                                        <input type="text" name="mobile" maxLength={13} value={formData.mobile} onChange={(e: any) => {
                                            setFormData({ ...formData, mobile: e.target.value, mobile_error: null });
                                        }} className="w-full text-sm" placeholder="Enter Mobile" />
                                    </div>
                                    <p className="text-red-500 text-sm">{formData.mobile_error}</p>
                                </div>
                                <div className="space-y-1">
                                    <label htmlFor="email" className={`input-label ${formData.email_error ? "text-red-500" : ""}`}>Email</label>
                                    <div id="email" className={`input-wrap ${formData.email_error ? "border-red-500" : ""}`}>
                                        <img src="assets/email.svg" alt="" />
                                        <input type="text" name="email" value={formData.email} onChange={(e: any) => {
                                            setFormData({ ...formData, email: e.target.value, email_error: null });
                                        }} className="w-full text-sm" placeholder="Enter Email" />
                                    </div>
                                    <p className="text-red-500 text-sm">{formData.email_error}</p>
                                </div>
                                <div className="space-y-1">
                                    <label htmlFor="userId" className={`input-label ${formData.userId_error ? "text-red-500" : ""}`}>UserId</label>
                                    <div id="userId" className={`input-wrap ${formData.userId_error ? "border-red-500" : ""}`}>
                                        <img src="assets/product.svg" alt="" />
                                        <input type="text" name="userId" value={formData.userId} onChange={(e: any) => {
                                            setFormData({ ...formData, userId: e.target.value, userId_error: null });
                                        }} className="w-full text-sm" placeholder="Enter UserId" />
                                    </div>
                                    <p className="text-red-500 text-sm">{formData.userId_error}</p>
                                </div>
                                <div className="space-y-1">
                                    <label htmlFor="password" className={`input-label ${formData.password_error ? "text-red-500" : ""}`}>Password</label>
                                    <div id="password" className={`input-wrap ${formData.password_error ? "border-red-500" : ""}`}>
                                        <img src="assets/bank.svg" alt="" />
                                        <input type="text" name="password" value={formData.password} onChange={(e: any) => {
                                            setFormData({ ...formData, password: e.target.value, password_error: null });
                                        }} className="w-full text-sm" placeholder="Enter Password" />
                                    </div>
                                    <p className="text-red-500 text-sm">{formData.password_error}</p>
                                </div>
                            </div>
                        </div>

                        <div className='tw-modal-footer'>
                            <button className='tw-modal-footer-button bg-secondary text-white' onClick={addLoginList}>Save</button>
                            <button className='tw-modal-footer-button bg-secondary bg-opacity-10 text-secondary' onClick={() => setAddOpen(false)}>Cancel</button>
                        </div>
                    </div>
                </div>
                {/* End::Add Disbursement Data Modal */}
                {/* END::MAIN CONTENT */}
            </>
        }
    </div>)
}

export default LoginCredentials